import { Grid, InputLabel, MenuItem, Select, Typography, TextField, Stack, LinearProgress, CardContent, Card } from '@mui/material';
import React, { useEffect } from 'react';
import VolunteerHeader from './StudentHeader';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../../../common/ui/TableComponent';
import CustomButton from '../../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { 
  schoolListInprogress, 
  selectSchoolList, 
  selectStandardList, 
  selectstudentsList, 
  selectstudentsListLoading, 
  standardListInProgress, 
  studentsListInProgress 
} from '../../../../store/students/_students.slice';
import CircularProgress from '@mui/material/CircularProgress';
import { surveyDetailsInProgress } from '../../../../store/survey/_survey.slice';
import { Controller, useForm } from 'react-hook-form';
import { passFailStatus, surveyStatus } from '../../../../common/constants/FormItems';
import person from '../../../../common/icons/image-card-1.png';
import person2 from '../../../../common/icons/image-card-2.png';
import person3 from '../../../../common/icons/image-card-3.png';
import person4 from '../../../../common/icons/image-card-4.png';

function SchoolWiseStudentDetaails() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location =useLocation()


  const schoolID=location?.state?.data
const overAlldata=location?.state?.overAlldate
console.log("location",location)
  const schoolDetails: any = useSelector(selectSchoolList);
  const schoolList: any = schoolDetails?.data || [];
  const { control, watch, handleSubmit, setValue } = useForm({
    defaultValues: {
      standard_id: null,
      school_id: null,
      result: null,
      is_survey_completed: null,
     student_emis_no:null,
      search: ''
    }
  });
  const studentDetails = useSelector(selectstudentsList);
  const standardDetails: any = useSelector(selectStandardList);
  const studentListLoading = useSelector(selectstudentsListLoading);
  const studentList = studentDetails?.data || [];
  const studentTotalRecords = studentDetails?.totalRecords || 0;
  const standardList: any = standardDetails?.data || [];
console .log("studentList",studentList)
  const handlefilter = () => {
    const { standard_id, school_id, result, is_survey_completed, search } = watch();
    const payload: any = {
      standard_id: standard_id,
      school_id: schoolID,
      result: result,
      is_survey_completed: is_survey_completed,
      pageNo: 1,
      pagination: true,
      student_emis_no: search
    };
    dispatch(studentsListInProgress(payload));
  }

  const surveyCompletedClick = (studentId: any) => {
    const studentIds = studentId?.row?.id;
    navigate(APP_ROUTES.SURVEY_DETAILS, { state: studentIds });
    dispatch(surveyDetailsInProgress(studentIds));
  }



  useEffect(() => {
    dispatch(standardListInProgress());
   
  }, [dispatch]);

  const columns = [
    {
      field: 'id',
      headerName: `${t('Student_id')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'firstName',
      headerName: `${t('First_Name')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'email',
      headerName: `${t('EMAIL')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'contact',
      headerName: `${t('MOBILE_NUMBER')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'dob',
      headerName: `${t('DOB')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'Status',
      headerName: `${t('Status')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'Action',
      headerName: `${t('Action')}`,
      disableColumnMenu: true,
      width: 200,
      renderCell: (params: any) => (
        <>
          {params.row.surveyStatus === 1 ? (
            <CustomButton
              text={`Add Survey`}
              className='mx-5'
              color={'primary'}
              onClick={() => {
                navigate(APP_ROUTES.STUDENT_FORM, {
                  state: {
                    data: params.row,
                    type: 'addSurvey',
                    tab: 1,
                  },
                });
              }}
            />
          ) : (
            <CustomButton
              text={`Survey Completed`}
              className='mx-3 color-green'
              onClick={() => { surveyCompletedClick(params) }}
              color='success'
            />
          )}
        </>
      ),
    },
  ];

 

  const rows = studentList.map((student: any) => ({
    student_emis_no: student.student_emis_no,
    firstName: student.first_name,
    lastName: student.last_name,
    email: student.email,
    contact: student.phone,
    dob: student.DOB,
    Status: student.survey_status === 1 ? 'Active' : 'Inactive',
    id: student.id,
    surveyStatus: student?.survey_status,
    school_code: student.school_code
  }));

  const handlePagination = (data: any) => {
    const { standard_id, school_id, result, is_survey_completed, search } = watch();
    const payloads = {
      standard_id: standard_id,
      school_id: school_id,
      result: result,
      is_survey_completed: is_survey_completed,
      student_emis_no: search,
      pageNo: data?.page + 1,
      pagination: true,
    };
    dispatch(studentsListInProgress(payloads as any));
  };
const handleOnchange=(value:any)=>{

  const { standard_id, school_id, result, is_survey_completed, search } = watch();
  const payloads = {
    standard_id: standard_id,
    school_id: school_id,
    result: result,
    is_survey_completed: is_survey_completed,
    student_emis_no: value,
    pageNo: 1,
    pagination: true,
   

  };
  dispatch(studentsListInProgress(payloads as any));
}
  return (
    <Grid container>
      <Grid item xs={12}>
      <Grid
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className='donation-header'
    >
      <Typography fontSize={20} fontWeight={700}>
       {t("School_wise_details")}
      </Typography>
       

<>  <CustomButton
                                        type="button"
                                        text={t("GO_BACK")}
                                        startIcon={SubdirectoryArrowRightIcon}
                                        color={'primary'}
                                        className="p-8 secondary-button"
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    /></>
     
    </Grid>
      </Grid>
      {/* <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          
          <Grid item xs={12} sm={6} md={6} lg={3} xl={4} p={2}>
          <InputLabel>Search:</InputLabel>
            <TextField
              fullWidth
              placeholder="Search"
              onChange={(e:any) =>handleOnchange(e.target.value)}
            />
         
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2.5} xl={4} p={2}>
              <InputLabel>Standard:</InputLabel>
              <Controller
                control={control}
                name="standard_id"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    displayEmpty
                  >
                   
                    {standardList.map((standard: any) => (
                      <MenuItem key={standard.id} value={standard.id}>
                        {standard.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2.5} xl={4} p={2}>
            <InputLabel>Pass/Fail</InputLabel>
            <Controller
              control={control}
              name="result"
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  displayEmpty
                >
                 
                  {passFailStatus.map((status: any) => (
                    <MenuItem key={status.id} value={status.id}>
                      {status.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} xl={4} p={2} mt={2.5}>
            <CustomButton
              onClick={handlefilter}
              text={'Filter'}
              className="p-12 secondary-button"
            />
          </Grid>
        </Grid> */}
        <Typography className='school-wise-details-text'> {t("Accuracy_2023_2024")}</Typography>
        <Grid item xs={12} container display="flex" className="dashboard-grid">
				
						<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
						<div className="flip-card-inner">
    <div className="flip-card-front">
	<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",result:1,school_id:schoolID,year:2024}}>
							<Card className='card-grid'style={{ backgroundColor: '#FFFDE7' }}>
								<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("hsc_passed")} - {Math.round(overAlldata?.pass_count_12)}
														</Typography>
														<Typography variant="h6">{Math.round(overAlldata?.pass_percentage_12)}%</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
              </Link>
    </div>
    <div className="flip-card-back">
	<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",result:1,school_id:schoolID,year:2024}}>
							<Card className='card-grid'style={{ backgroundColor: '#FFFDE7' }}>
								<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("hsc_passed")} - {Math.round(overAlldata?.pass_count_12)}
														</Typography>
														<Typography variant="h6">{Math.round(overAlldata?.pass_percentage_12)}%</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
              </Link>
    </div>
  </div>
            
						</Grid>
						
						{/* Card for Survey Completed */}
		<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
		<div className="flip-card-inner">

		<div className="flip-card-front">
            <Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",result:2,school_id:schoolID,year:2024}}>
							<Card className='card-grid'style={{ backgroundColor: '#FCE4EC' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("hsc_failed")} - {overAlldata?.fail_count_12}
														</Typography>
														<Typography variant="h6">{Math.round(overAlldata?.fail_percentage_12)}%</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card></Link></div>
							<div className="flip-card-back">
							<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",result:2,school_id:schoolID,year:2024}}>
							<Card className='card-grid'style={{ backgroundColor: '#FCE4EC' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("hsc_failed")} - {overAlldata?.fail_count_12}
														</Typography>
														<Typography variant="h6">{Math.round(overAlldata?.fail_percentage_12)}%</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card></Link>
							</div>
							</div>


						</Grid>
						{/* Card for Survey Pending */}
						<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
						<div className="flip-card-inner">	
						<div className="flip-card-front">				
            <Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",result:1,school_id:schoolID,year:2024}}>              
							<Card className='card-grid' style={{ backgroundColor: '#ECEFF1' }}>
              <CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("sslc_passed")} - {overAlldata?.pass_count_10}
														</Typography>
														<Typography variant="h6">{Math.round(overAlldata?.pass_percentage_10)}%</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card></Link>
							</div>	
							<div className="flip-card-back">
							<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",result:1,school_id:schoolID,year:2024}}>              
							<Card className='card-grid' style={{ backgroundColor: '#ECEFF1' }}>
              <CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("sslc_passed")} - {overAlldata?.pass_count_10}
														</Typography>
														<Typography variant="h6">{Math.round(overAlldata?.pass_percentage_10)}%</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card></Link>
							</div>
							</div>
						</Grid>
						{/* Card for Completed Percentage */}
						<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
						<div className="flip-card-inner">	
						<div className="flip-card-front">	
            <Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",result:2,school_id:schoolID,year:2024}}>
							<Card className='card-grid' style={{ backgroundColor: '#E1BEE7'}}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("sslc_failed")} - {overAlldata?.fail_count_10}
														</Typography>
														<Typography variant="h6">{Math.round(overAlldata?.fail_percentage_10)}%</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
              </Link></div>
			  <div className="flip-card-back">
			  <Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",result:2,school_id:schoolID,year:2024}}>
							<Card className='card-grid' style={{ backgroundColor: '#E1BEE7'}}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("sslc_failed")} - {overAlldata?.fail_count_10}
														</Typography>
														<Typography variant="h6">{Math.round(overAlldata?.fail_percentage_10)}%</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
              </Link>
			  </div>
			  </div>
						</Grid>
					</Grid>
       
          {/* <Typography className='school-wise-details-text'>{t("SSLC_Students_Status_2023_2024")}</Typography> */}
        {/* <Grid item xs={12} container display="flex" className="dashboard-grid"> */}
		
	{/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
	<div className="flip-card-inner">
	<div className="flip-card-front">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",school_id:schoolID,year:2024,student_status:3}}>
							<Card className='card-grid' style={{ backgroundColor: '#FADBD8' }}>
								<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_applied")}
														</Typography>
														<Typography variant="h6">{overAlldata?.higher_studies_applied_10?overAlldata?.higher_studies_applied_10:0} </Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link>
							</div>
							<div className="flip-card-back">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",school_id:schoolID,year:2024,student_status:3}}>
							<Card className='card-grid' style={{ backgroundColor: '#FADBD8' }}>
								<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_applied")}
														</Typography>
														<Typography variant="h6">{overAlldata?.higher_studies_applied_10?overAlldata?.higher_studies_applied_10:0} </Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link>
							</div>
							</div>
						</Grid> */}
						{/* Card for Survey Completed */}
{/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
<div className="flip-card-inner">
<div className="flip-card-front">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",school_id:schoolID,year:2024,student_status:1}}>						
							<Card className='card-grid' style={{ backgroundColor: '#D1F2EB' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.higher_studies_join_10?overAlldata?.higher_studies_join_10:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
			</Card></Link></div>
			<div className="flip-card-back">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",school_id:schoolID,year:2024,student_status:1}}>						
							<Card className='card-grid' style={{ backgroundColor: '#D1F2EB' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.higher_studies_join_10?overAlldata?.higher_studies_join_10:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
			</Card></Link></div>
							</div>
						</Grid> */}
						{/* Card for Survey Pending */}
						{/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
						<div className="flip-card-inner">
						<div className="flip-card-front">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",school_id:schoolID,year:2024,student_status:2}}>
							<Card className='card-grid' style={{ backgroundColor: '#D1F2EB' }}>
              <CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_not_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.no_studies_10?overAlldata?.no_studies_10:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link>
						</div>
						<div className="flip-card-back">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",school_id:schoolID,year:2024,student_status:2}}>
							<Card className='card-grid' style={{ backgroundColor: '#D1F2EB' }}>
              <CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_not_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.no_studies_10?overAlldata?.no_studies_10:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link>
						</div>
						
							</div>
						</Grid> */}
						{/* Card for Completed Percentage */}
						{/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
						<div className="flip-card-inner">
						<div className="flip-card-front"><Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",school_id:schoolID,year:2024,student_status:4}}>
							<Card className='card-grid' style={{ backgroundColor: '#E1D5E7' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("skill_based_course_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.skill_based_studies_10?overAlldata?.skill_based_studies_10:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card></Link></div>
							<div className="flip-card-back"><Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",school_id:schoolID,year:2024,student_status:4}}>
							<Card className='card-grid' style={{ backgroundColor: '#E1D5E7' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("skill_based_course_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.skill_based_studies_10?overAlldata?.skill_based_studies_10:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card></Link></div>
						
							</div>
						</Grid> */}
					{/* </Grid> */}
          <Typography className='school-wise-details-text'>HSC Students Status-2023-2024</Typography>
        <Grid item xs={12} container display="flex" className="dashboard-grid">
				
						<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
						<div className="flip-card-inner">
						<div className="flip-card-front">
						<Link to={APP_ROUTES.HIGHER_STUDIES_APPLIED_SCREEN}state={{mapData:location,type:"schoolWiseDetails"}}>
							<Card className='card-grid' style={{ backgroundColor: '#FFF9C4' }}>
								<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_applied")}
														</Typography>
														<Typography variant="h6">{overAlldata?.higher_studies_applied_12?overAlldata?.higher_studies_applied_12:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link>
						</div>
						<div className="flip-card-back">
						<Link to={APP_ROUTES.HIGHER_STUDIES_APPLIED_SCREEN}state={{mapData:location,type:"schoolWiseDetails"}}>
							<Card className='card-grid' style={{ backgroundColor: '#FFF9C4' }}>
								<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_applied")}
														</Typography>
														<Typography variant="h6">{overAlldata?.higher_studies_applied_12?overAlldata?.higher_studies_applied_12:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link>
						</div>
						
							</div>
						</Grid>
						{/* Card for Survey Completed */}
						<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
						<div className="flip-card-inner">
						<div className="flip-card-front">
						<Link to={APP_ROUTES.STREAM_BASED_SCREEN}state={{mapData:location,type:"schoolWiseDetails"}}>
							<Card className='card-grid' style={{ backgroundColor: '#E8F5E9' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.higher_studies_join_12?overAlldata?.higher_studies_join_12:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link>
							</div>
							<div className="flip-card-back">
							<Link to={APP_ROUTES.STREAM_BASED_SCREEN}state={{mapData:location,type:"schoolWiseDetails"}}>
							<Card className='card-grid' style={{ backgroundColor: '#E8F5E9' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.higher_studies_join_12?overAlldata?.higher_studies_join_12:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link>
							</div></div>
						</Grid>
						{/* Card for Survey Pending */}
						<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
						<div className="flip-card-inner">
						<div className="flip-card-front">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",school_id:schoolID,year:2024,student_status:2}}>
							<Card className='card-grid' style={{ backgroundColor: '#E1F5FE' }}>
              <CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_not_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.no_studies_12?overAlldata?.no_studies_12:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link></div>
							<div className="flip-card-back">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",school_id:schoolID,year:2024,student_status:2}}>
							<Card className='card-grid' style={{ backgroundColor: '#E1F5FE' }}>
              <CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("higher_studies_not_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.no_studies_12?overAlldata?.no_studies_12:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link></div>
							</div>
						</Grid>
						{/* Card for Completed Percentage */}
						<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="flip-card">
						<div className="flip-card-inner">
						<div className="flip-card-front"><Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",school_id:schoolID,year:2024,student_status:4}}>
							<Card className='card-grid' style={{ backgroundColor: '#F0F0F0' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("skill_based_course_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.skill_based_studies_12?overAlldata?.skill_based_studies_12:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link></div>
							<div className="flip-card-back"><Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",school_id:schoolID,year:2024,student_status:4}}>
							<Card className='card-grid' style={{ backgroundColor: '#F0F0F0' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
														{t("skill_based_course_joined")}
														</Typography>
														<Typography variant="h6">{overAlldata?.skill_based_studies_12?overAlldata?.skill_based_studies_12:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link></div>
						
							</div>
						</Grid>
					</Grid>
      {/* {studentListLoading ?
        <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress />
        </Grid>
        :
        <>
         <Grid item xs={12} my={5}>
            <TableComponent
              totalRecords={studentTotalRecords}
              columns={columns}
              rows={rows}
              pagination={false}
              handlePagination={handlePagination}
              paginationModel={{
                page: Number(studentDetails?.currentPage) - 1 || 0,
                pageSize: Number(studentDetails?.pagesize) || 5,
              }}
            />
          </Grid>
        </>
      } */}
	   
	   {/* <Grid item xs={12} container display="flex" className="dashboard-grid"> */}
         
		 {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} > */}
		   {/* <Typography className='school-wise-details-text'>{t("Over_all_survey_count")}</Typography>  */}
		   {/* <Grid display={"flex"} > */}
			 {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2} className="flip-card" >
			 <div className="flip-card-inner">
			 <div className="flip-card-front">
			 <Link to={APP_ROUTES.STUDENT_LIST}state={{is_survey_completed:2,school_id:schoolID}}>
							 <Card className='card-grid' style={{ backgroundColor: '#EDE7F6' }}>
								 <CardContent>
									 <Stack spacing={2}>
										 <Grid
											 item
											 xs={12}
											 container
											 display="flex"
											 justifyContent="space-between"
											 alignItems={"center"}
										 >
											 <Grid>
												 <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													 <Stack spacing={1}>
														 <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-s'>
														 {t("survey_completed")}
														 </Typography>
														 <Typography variant="h6">{overAlldata?.suvey_completed_student_count}</Typography>
													 </Stack>
												 </Stack>
											 </Grid>
											 
										 </Grid>
										 
									 </Stack>
								 </CardContent>
							 </Card>
			   </Link>
			 </div>
			 <div className="flip-card-back">
			 <Link to={APP_ROUTES.STUDENT_LIST}state={{is_survey_completed:2,school_id:schoolID}}>
							 <Card className='card-grid' style={{ backgroundColor: '#EDE7F6' }}>
								 <CardContent>
									 <Stack spacing={2}>
										 <Grid
											 item
											 xs={12}
											 container
											 display="flex"
											 justifyContent="space-between"
											 alignItems={"center"}
										 >
											 <Grid>
												 <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													 <Stack spacing={1}>
														 <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-s'>
														 {t("survey_completed")}
														 </Typography>
														 <Typography variant="h6">{overAlldata?.suvey_completed_student_count}</Typography>
													 </Stack>
												 </Stack>
											 </Grid>
											 
										 </Grid>
										 
									 </Stack>
								 </CardContent>
							 </Card>
			   </Link>
			 </div>
		 
			 </div>
		   
						 </Grid> */}
						 {/* Card for Survey Completed */}
			 {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}  className="flip-card">
			 <div className="flip-card-inner">
			 <div className="flip-card-front">
			 <Link to={APP_ROUTES.STUDENT_LIST}state={{is_survey_completed:1,school_id:schoolID}}>
							 <Card className='card-grid' style={{ backgroundColor: '#F5F5F5' }}>
							 <CardContent>
									 <Stack spacing={2}>
										 <Grid
											 item
											 xs={12}
											 container
											 display="flex"
											 justifyContent="space-between"
											 alignItems={"center"}
										 >
											 <Grid>
												 <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													 <Stack spacing={1}>
														 <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-s'>
														 {t("survey_pending")}
														 </Typography>
														 <Typography variant="h6">{overAlldata?.suvey_not_completed_student_count}</Typography>
													 </Stack>
												 </Stack>
											 </Grid>
											 
										 </Grid>
										 
									 </Stack>
								 </CardContent>
							 </Card></Link></div>
							 <div className="flip-card-back">
			 <Link to={APP_ROUTES.STUDENT_LIST}state={{is_survey_completed:1,school_id:schoolID}}>
							 <Card className='card-grid' style={{ backgroundColor: '#F5F5F5' }}>
							 <CardContent>
									 <Stack spacing={2}>
										 <Grid
											 item
											 xs={12}
											 container
											 display="flex"
											 justifyContent="space-between"
											 alignItems={"center"}
										 >
											 <Grid>
												 <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													 <Stack spacing={1}>
														 <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-s'>
														 {t("survey_pending")}
														 </Typography>
														 <Typography variant="h6">{overAlldata?.suvey_not_completed_student_count}</Typography>
													 </Stack>
												 </Stack>
											 </Grid>
											 
										 </Grid>
										 
									 </Stack>
								 </CardContent>
							 </Card></Link></div>
			 </div>
			
						 </Grid> */}
			 {/* </Grid> */}
			 {/* </Grid> */}
						 {/* Card for Survey Pending */}
			 {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}> */}
			 
			 {/* <Typography className='school-wise-details-text'>{t("Survey_Counts_2023_2024")}</Typography>  */}
			 {/* <Grid  display={"flex"}> */}
		 
	 {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2} className="flip-card">
	 <div className="flip-card-inner">
	 <div className="flip-card-front">
						 <Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",school_id:schoolID,is_survey_completed:2,year:2024}}>
							 <Card className='card-grid' style={{ backgroundColor: '#FFE0B2' }}>
			   <CardContent>
									 <Stack spacing={2}>
										 <Grid
											 item
											 xs={12}
											 container
											 display="flex"
											 justifyContent="space-between"
											 alignItems={"center"}
										 >
											 <Grid>
												 <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													 <Stack spacing={1}>
														 <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-s'>
															 
															 {t("Survey_Counts_SSLC")}
														 </Typography>
														 <Typography variant="h6">{overAlldata?.survey_count_10?overAlldata?.survey_count_10:0}</Typography>
													 </Stack>
												 </Stack>
											 </Grid>
											 
										 </Grid>
										 
									 </Stack>
								 </CardContent>
							 </Card>
							 </Link></div>
							 <div className="flip-card-back">
						 <Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",school_id:schoolID,is_survey_completed:2,year:2024}}>
							 <Card className='card-grid' style={{ backgroundColor: '#FFE0B2' }}>
			   <CardContent>
									 <Stack spacing={2}>
										 <Grid
											 item
											 xs={12}
											 container
											 display="flex"
											 justifyContent="space-between"
											 alignItems={"center"}
										 >
											 <Grid>
												 <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													 <Stack spacing={1}>
														 <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-s'>
															 
															 {t("Survey_Counts_SSLC")}
														 </Typography>
														 <Typography variant="h6">{overAlldata?.survey_count_10?overAlldata?.survey_count_10:0}</Typography>
													 </Stack>
												 </Stack>
											 </Grid>
											 
										 </Grid>
										 
									 </Stack>
								 </CardContent>
							 </Card>
							 </Link></div>
							 </div>
						 </Grid> */}
						 
						 {/* Card for Completed Percentage */}
						 
						 {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}  className="flip-card">
						 <div className="flip-card-inner">
						 <div className="flip-card-front">
						 <Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",school_id:schoolID,is_survey_completed:2,year:2024}}>
							 <Card className='card-grid' style={{ backgroundColor: '#E0F7FA' }}>
							 <CardContent>
									 <Stack spacing={2}>
										 <Grid
											 item
											 xs={12}
											 container
											 display="flex"
											 justifyContent="space-between"
											 alignItems={"center"}
										 >
											 <Grid>
												 <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													 <Stack spacing={1}>
														 <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-s'>
														 {t("Survey_Counts_HSC")}
														 </Typography>
														 <Typography variant="h6">{overAlldata?.survey_count_12?overAlldata?.survey_count_12:0}</Typography>
													 </Stack>
												 </Stack>
											 </Grid>
											 
										 </Grid>
										 
									 </Stack>
								 </CardContent>
							 </Card>
							 </Link>
						 </div>
						 <div className="flip-card-back">
						 <Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",school_id:schoolID,is_survey_completed:2,year:2024}}>
							 <Card className='card-grid' style={{ backgroundColor: '#E0F7FA' }}>
							 <CardContent>
									 <Stack spacing={2}>
										 <Grid
											 item
											 xs={12}
											 container
											 display="flex"
											 justifyContent="space-between"
											 alignItems={"center"}
										 >
											 <Grid>
												 <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													 <Stack spacing={1}>
														 <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-s'>
														 {t("Survey_Counts_HSC")}
														 </Typography>
														 <Typography variant="h6">{overAlldata?.survey_count_12?overAlldata?.survey_count_12:0}</Typography>
													 </Stack>
												 </Stack>
											 </Grid>
											 
										 </Grid>
										 
									 </Stack>
								 </CardContent>
							 </Card>
							 </Link>
						 </div>
						 
							 </div>
						 </Grid> */}
						 {/* </Grid> */}
						 {/* </Grid> */}
					 {/* </Grid> */}
    </Grid>
  );
}

export default SchoolWiseStudentDetaails;
