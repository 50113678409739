

export const AUTH_ROUTES = {
	LOGIN: '/',
	SIGNUP: '/signup',
	FORGET_PASSWORD: '/forget-password',
	OTP_SCREEN: '/otp-screen',
};

export const APP_ROUTES = {
	DASHBOARD: '/',
	CANDIDATE: '/candidate',
	ADMINCANDIDATE: '/candidate',
	STUDENT_LIST: '/student-list',
	STUDENT_FORM: '/student-form',
	ADD_PUBLIC_LIST: '/public/add',
	EDIT_PUBLIC_LIST: '/public/edit',
	SCHOOL: '/school',
	SCHOOLFORM: '/school/addschool',
	QUESTIONS: '/questions',
	QUESTIONFORM: '/questions/addquestions',
	CANDIDATE_PROFILE: '/profile',
	CHANGE_PASSWORD: '/change-password',
	SURVEY_DETAILS: '/survey-details',
	SCHOOLWISE_DEATILS:'/school-wise-details',
	PROFILE_UPDATE:"/profile-update",
	STREAM_BASED_SCREEN:"/stream-based-list",
	EDIT_SURVEY_FORM:"/edit-survey-Form",
	HIGHER_STUDIES_APPLIED_SCREEN:"/higher-studies-applied-screen",
	STUDENT_ADD_SCREEN:"/student-add-screen",
	SCHOOL_LIST_SCREEN:"/school-list-screen",
	STUDENT_EDIT_FORM:"/student-edit-form",
	COLLEGE_LIST_SCREEN:"/college-list-screen",
	SUBJECT_WISE_LIST_SCREEN:"/subject-wise-list-screen"
};

// export const PUBLIC_MENU = [
// 	{ text: 'DASHBOARD', route: APP_ROUTES.DASHBOARD },
// 	{ text: 'PETITIONS', route: APP_ROUTES.PETITION },
// 	{ text: 'MY_PROFILE', route: APP_ROUTES.CANDIDATE_PROFILE },
// 	{ text: 'CHANGE_PASSWORD', route: APP_ROUTES.CHANGE_PASSWORD },
// 	{ text: 'LOG_OFF' },
// ];

// export const SUPER_ADMIN_ROUTES = {
// 	DASHBOARD: '/',
// 	CANDIDATES: '/candidates',
// 	CANDIDATES_ADD: '/candidates/add',
// 	CANDIDATES_EDIT: '/candidates/edit',
// 	PARTIES: '/parties',
// 	PARTIES_ADD: '/parties/add',
// 	PARTIES_EDIT: '/parties/edit',
// 	CONSTITUENCIES: '/constituencies',
// 	CONSTITUENCIES_ADD: '/constituencies/add',
// 	CONSTITUENCIES_EDIT: '/constituencies/edit',
// 	PRICING_PLANS: '/pricing_plans',
// 	PRICING_PLANS_ADD: '/pricing_plans/add',
// 	PRICING_PLANS_EDIT: '/pricing_plans/edit',
// };
// export const ADMIN_MENU = [
// 	{ text: 'DASHBOARD', route: SUPER_ADMIN_ROUTES.DASHBOARD },
// 	{ text: 'CANDIDATES', route: SUPER_ADMIN_ROUTES.CANDIDATES },
// 	{ text: 'PARTIES', route: SUPER_ADMIN_ROUTES.PARTIES },
// 	{ text: 'PETITIONS', route: APP_ROUTES.PETITION },
// 	// { text: 'CONSTITUENCIES', route: SUPER_ADMIN_ROUTES.CONSTITUENCIES },
// 	{ text: 'MY_PROFILE', route: APP_ROUTES.CANDIDATE_PROFILE },
// 	{ text: 'CHANGE_PASSWORD', route: APP_ROUTES.CHANGE_PASSWORD },
// 	{ text: 'LOG_OFF' },
// ];
export const CANDIDATE_MENU = [
	{ text: 'DASHBOARD', route: APP_ROUTES.DASHBOARD },
	{ text: 'Student_list', route: APP_ROUTES.STUDENT_LIST },
	{ text: 'School', route: APP_ROUTES.SCHOOL },
	{ text: 'Questions', route: APP_ROUTES.QUESTIONS },
	{ text: 'Add_Student', route: APP_ROUTES.SCHOOL_LIST_SCREEN },
	// { text: 'PETITIONS', route: APP_ROUTES.PETITION },
	// { text: 'MY_PROFILE', route: APP_ROUTES.CANDIDATE_PROFILE },
	// { text: 'CHANGE_PASSWORD', route: APP_ROUTES.CHANGE_PASSWORD },
	{ text: 'LOG_OFF' },
];
export const PUBLIC_ROUTES = {};
