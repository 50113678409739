import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Tabs, Tab } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// import EditStudentFormHSc from './StudentSurveyForm';
import theme from '../../../../common/theme';
import CustomButton from '../../../../common/ui/CustomButton';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import StudentAddForm from './StudentAddForm';
import StudentSurveyForm from './StudentSurveyForm';
import { standardListInProgress } from '../../../../store/students/_students.slice';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import EditSurveyForm from './EditSurveyForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const location =useLocation()
  console.log("location",location)

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EditStudenrt = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state);
  const userData = user?.getProfile?.data?.data || [];
  const [enableSurvey,setEnableSuryvey]=useState(true)
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue, // from useForm
    getValues,
    reset,
    trigger
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
    middle_name: "",
    student_emis_no: "",
    email: "",
    phone: "",
    address: "",
    address2:"",
    city: "Thirupur",
    state: "Tamilnadu",
    country: "India",
    DOB:null,
    community: "",
    religion: "",
    father_phone:"" ,
    mother_phone: "",
    school_code:"",
    guardian_name: " ",
    guardian_number: " ",
    },
  });

  const [tabValue, setTabValue] = useState(location?.state?.tab ?? 0);  // renamed to setTabValue
console.log("enableSurvey",location?.state?.type,enableSurvey)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue); // use setTabValue here
  };

  return (
    <Grid container>
      <Grid item xs={12} p={2}>
        <Grid container justifyContent="space-between">
          <Typography fontSize={20} fontWeight={600} color={theme.palette.common.black}>
            {t("Student_Survey")}
          </Typography>
          {/* <CustomButton
            type="button"
            text="Go Back"
            startIcon={<SubdirectoryArrowRightIcon />}
            color="primary"
            className="p-8 secondary-button"
            onClick={() => {
              // navigate(APP_ROUTES.MY_PROFILE);
            }}
          /> */}
        </Grid>
      </Grid>

      <Grid item xs={12} >
      <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              <Tab label={t("Student_Add_Form")} {...a11yProps(0)} disabled={location?.state?.type === "addSurvey"||location?.state?.type === "Edit"} />
              <Tab label={t("Student_Survey_Form")} {...a11yProps(1)} disabled={enableSurvey && location?.state?.type !== "addSurvey"} />
              <Tab label={t("Surver_Edit_Form")} {...a11yProps(1)} disabled={enableSurvey && location?.state?.type === "addSurvey"||location?.state?.type === "Add-student"} />
            </Tabs>
            
          </Box>
          
          <CustomTabPanel value={tabValue} index={0}>
		  <StudentAddForm
              handleSubmit={handleSubmit}
              text="Add Team Member"
              register={register}
              control={control}
              errors={errors}
              reset={reset}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
              setEnableSuryvey={setEnableSuryvey}
              trigger={trigger}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
          <StudentSurveyForm
              handleSubmit={handleSubmit}
              text="Add Team Member"
              register={register}
              control={control}
              errors={errors}
              reset={reset}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
              locations={location}
              trigger={trigger}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={2}>
          <EditSurveyForm
              handleSubmit={handleSubmit}
              text="Add Team Member"
              register={register}
              control={control}
              errors={errors}
              reset={reset}
              setValue={setValue}
              watch={watch}
              getValues={getValues}
              locations={location}
            />
          </CustomTabPanel>
         
        </Box>
      </Grid>
    </Grid>
  );
};

export default EditStudenrt;
