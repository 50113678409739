import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../common';
import { RootState, useAppSelector } from '../store';
import { AuthUser } from '../../common/utils';

interface AuthState {
	token?: string | null;
	user: AuthUser | null;
	authUserInfo: any;
	loginRequest: {
		status: LoadingState;
		requestInfo: any;
		error: any;
	};
	logOut: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	otpRequest: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};
	otpVerify: {
		status: LoadingState;
		responseInfo: any;
		error: any;
	};

	signUp: {
		status: LoadingState;
		responseInfo: any;
		error: any;
		payload: any;
	};
	forgetPassword: {
		status: LoadingState;
		responseInfo: any;
		error: any;
		payload: any;
	};
	changePassword: {
		status: LoadingState;
		responseInfo: any;
		error: any;
		payload: any;
	};
}

const initialState: AuthState = {
	token: null,
	user: null,
	authUserInfo: null,
	loginRequest: {
		status: 'idle',
		requestInfo: undefined,
		error: undefined,
	},
	logOut: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	otpRequest: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},
	otpVerify: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
	},

	signUp: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
		payload: undefined,
	},
	forgetPassword: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
		payload: undefined,
	},
	changePassword: {
		status: 'idle',
		responseInfo: undefined,
		error: undefined,
		payload: undefined,
	},
};
const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		fetchLoginRequest: (state) => {
			state.loginRequest.status = 'loading';
			state.loginRequest.requestInfo = undefined;
			state.loginRequest.error = undefined;
		},
		reportLoginRequestSuccess: (state, { payload }) => {
			state.loginRequest.status = 'done';
			state.loginRequest.requestInfo = payload;
			state.loginRequest.error = undefined;
		},
		reportLoginRequestError: (state, { payload }) => {
			state.loginRequest.status = 'error';
			state.loginRequest.requestInfo = undefined;
			state.loginRequest.error = payload;
		},

		logoutRequest: (state, { payload }) => {
			state.logOut.status = 'loading';
			state.logOut.responseInfo = undefined;
			state.logOut.error = undefined;
		},
		logoutSuccess: (state, { payload }) => {
			state.logOut.status = 'done';
			state.logOut.responseInfo = payload;
			state.logOut.error = undefined;
			state.user = null;
		},
		logoutError: (state, { payload }) => {
			state.logOut.status = 'error';
			state.logOut.responseInfo = undefined;
			state.logOut.error = payload;
		},
		fetchOtpRequest: (state) => {
			state.token = 'token';
			state.otpRequest.status = 'loading';
			state.otpRequest.responseInfo = undefined;
			state.otpRequest.error = undefined;
		},
		reportOtpLoginSuccess: (state, { payload }) => {
			state.otpRequest.status = 'done';
			state.otpRequest.responseInfo = payload;
			state.otpRequest.error = undefined;
		},
		reportOtpLoginError: (state, { payload }) => {
			state.otpRequest.status = 'error';
			state.otpRequest.responseInfo = undefined;
			state.otpRequest.error = payload;
		},

		fetchOtpVerify: (state) => {
			state.otpVerify.status = 'loading';
			state.otpVerify.responseInfo = undefined;
			state.otpVerify.error = undefined;
		},
		reportOtpVerifySuccess: (state, { payload }) => {
			state.otpVerify.status = 'done';
			state.otpVerify.responseInfo = payload;
			state.otpVerify.error = undefined;
		},
		reportOtpVerifyError: (state, { payload }) => {
			state.otpVerify.status = 'error';
			state.otpVerify.responseInfo = undefined;
			state.otpVerify.error = payload;
		},

		signUpRequest: (state, { payload }) => {
			state.signUp.status = 'loading';
			state.signUp.responseInfo = undefined;
			state.signUp.error = undefined;
			state.signUp.payload = payload;
		},
		signUpSuccess: (state, { payload }) => {
			state.signUp.status = 'done';
			state.signUp.responseInfo = payload;
			state.signUp.error = undefined;
		},
		signUpError: (state, { payload }) => {
			state.signUp.status = 'error';
			state.signUp.responseInfo = undefined;
			state.signUp.error = payload;
		},

		forgetPasswordRequest: (state, { payload }) => {
			state.forgetPassword.status = 'loading';
			state.forgetPassword.responseInfo = undefined;
			state.forgetPassword.error = undefined;
			state.forgetPassword.payload = payload;
		},
		forgetPasswordSuccess: (state, { payload }) => {
			state.forgetPassword.status = 'done';
			state.forgetPassword.responseInfo = payload;
			state.forgetPassword.error = undefined;
		},
		forgetPasswordError: (state, { payload }) => {
			state.forgetPassword.status = 'error';
			state.forgetPassword.responseInfo = undefined;
			state.forgetPassword.error = payload;
		},
		changePasswordRequest: (state, { payload }) => {
			state.changePassword.status = 'loading';
			state.changePassword.responseInfo = undefined;
			state.changePassword.error = undefined;
			state.changePassword.payload = payload;
		},
		changePasswordSuccess: (state, { payload }) => {
			state.changePassword.status = 'done';
			state.changePassword.responseInfo = payload;
			state.changePassword.error = undefined;
		},
		changePasswordError: (state, { payload }) => {
			state.changePassword.status = 'error';
			state.changePassword.responseInfo = undefined;
			state.changePassword.error = payload;
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
		userSignOut: (state) => {
			state.user = null;
			sessionStorage.clear();
		},

		// eslint-disable-next-line no-unused-vars
	},
});

export const {
	setUser,
	userSignOut,
	fetchLoginRequest,
	reportLoginRequestError,
	reportLoginRequestSuccess,
	reportOtpLoginSuccess,
	reportOtpLoginError,
	fetchOtpRequest,
	fetchOtpVerify,
	reportOtpVerifySuccess,
	reportOtpVerifyError,
	signUpError,
	signUpRequest,
	signUpSuccess,
	forgetPasswordRequest,
	forgetPasswordSuccess,
	forgetPasswordError,
	changePasswordRequest,
	changePasswordSuccess,
	changePasswordError,

	logoutError,
	logoutSuccess,
	logoutRequest,
} = authSlice.actions;
export const selectIsLoginRequestLoading = (state: RootState) =>
	state.auth.loginRequest.status === 'loading';

export const selectLoginRequestInfo = (state: RootState) =>
	state.auth.loginRequest.requestInfo;

export const selectLoginRequestInfoError = (state: RootState) =>
	state.auth.loginRequest.error;

export const selectIsOtpRequestLoading = (state: RootState) =>
	state.auth.otpRequest.status === 'loading';

export const selectOtpResponseInfo = (state: RootState) =>
	state.auth.otpRequest.responseInfo;

export const selectOtpLoginError = (state: RootState) =>
	state.auth.otpRequest.error;

export const selectIsUserLoggedIn = (state: RootState) => state.auth.user;

export const selectIsOtpValidationLoading = (state: RootState) =>
	state.auth.otpVerify.status === 'loading';

export const selectIsSignUpLoading = (state: RootState) =>
	state.auth.signUp.status === 'loading';
export const selectIsSignUpData = (state: RootState) =>
	state.auth.signUp.responseInfo;
export const selectSignUpPayload = (state: RootState) =>
	state.auth.signUp?.payload?.Customer;

export default authSlice;
