import { CircularProgress, Grid, InputLabel, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { APP_ROUTES } from '../../common/constants/Routes';
import theme from '../../common/theme';
import CustomButton from '../../common/ui/CustomButton';
import FormInput from '../../common/ui/Forms/FormInput/FormInput';
import { collegeListInprogress, courseListInprogress, districtListInprogress, groupListInprogress, savestudentandsurveyInProgress, schoolListInprogress, selectCollegeList, selectCollegeListLoading, selectCourseList, selectDistrictList, selectGroupList, selectSchoolList } from '../../store/students/_students.slice';
import { setAlertInfo } from '../../common/Common.slice';

function StudentAddScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const groupDetails: any = useSelector(selectGroupList);
  const collegeDetails:any=useSelector(selectCollegeList)
  const collegeLoading:any=useSelector(selectCollegeListLoading)
  const schoolDetails: any = useSelector(selectSchoolList)
  const courseDetails:any=useSelector(selectCourseList)
  const districtDetails:any=useSelector(selectDistrictList)
  const {t}=useTranslation()
console.log(districtDetails,"districtDetails")
  const schoolList: any = schoolDetails?.data || [];
  const groupList: any = groupDetails?.data || [];
  const courseList: any = courseDetails?.data || [];
  const collegeList:any=collegeDetails?.data||[];
  const districtList:any=districtDetails?.data||[]

console.log("collegeList",location?.state?.data?.id)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    control,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      first_name:"",
      student_emis_no:"",
      school_id:location?.state?.data?.id,
  
      course_name:"",
      course_id:"",
      school_code:"",
      college_id:"",
      college_name:"",
      district_id:""

    },
  });

const streamWatcher =watch('course_id')
const districtWatcher =watch('district_id')
console.log("streamWatcher",streamWatcher)

  const onSubmit = (data: any) => {
	console.log("data",data)
		const handleSuccess=()=>{
			navigate(APP_ROUTES.SCHOOL_LIST_SCREEN)
      const alertInfo: any = {
        open: true,
        type: 'SUCCESS',
        description: `Student Added Sucessfully`,
       
    };
    dispatch(setAlertInfo(alertInfo));
		}
	
		const payload:any={
			...data,
			handleSuccess
		}
	   dispatch(savestudentandsurveyInProgress(payload))

	
  };


  const handleCourseChange = (value: any,name:any) => {
    console.log("value",value,"name",name)
    setValue('course_id', value, {
        shouldValidate: true,
    });
    if( Number(value)!==8){
        setValue('course_name', name);
    }
    if( Number(value)===8){
      setValue('course_name', "");
  }
       
   




};
const handleDistrictChange=(value:any,name:any)=>{
  setValue('district_id', value, {
    shouldValidate: true,
});

   
}

const handleSchoolChange = (value: any,short_code:any) => {
    console.log("value",value)
    setValue('school_id', value, {
        shouldValidate: true,
    });
    setValue('school_code', short_code, {
        shouldValidate: true,
    });



};
const handleCollegeName= (value: any,short_code:any) => {
  console.log("value",value)
  setValue('college_id', value, {
      shouldValidate: true,
  });
  setValue('college_name', short_code, {
      shouldValidate: true,
  });



};

useEffect(() => {
        
    dispatch(groupListInprogress());
    dispatch(schoolListInprogress())
    dispatch(courseListInprogress())
    dispatch(collegeListInprogress())
    dispatch(districtListInprogress())

    

}, [dispatch]);
useEffect(() => {
  const payload :any={
    courses_id:Number(streamWatcher)===7?2:streamWatcher,
    district_id:districtWatcher
  }
 if(streamWatcher&&districtWatcher){
  dispatch(collegeListInprogress(payload))
 }
  


  

}, [streamWatcher,districtWatcher]);
const handleGroupChange2 = (value:any, name:any) => {
    console.log("handleGroupChange2", value, name);
    setValue('course_name', value, { shouldValidate: true });
};

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid container xs={12} p={2} item>
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent={'space-between'}
              container
            >
              <Grid item>
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  color={theme.palette.common.black}
                >
              {t("Add_Student")}
                </Typography>
              </Grid>
              <Grid item>
                <CustomButton
                  type="button"
                  text={t("GO_BACK")}
                //   startIcon={SubdirectoryArrowRightIcon}
                  color={'primary'}
                  className="p-8 secondary-button"
                  onClick={() => {
                    navigate(APP_ROUTES.SCHOOL);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              alignItems={'center'}
              justifyContent={'flex-end'}
              display={'flex'}
            ></Grid>
          </Grid>

          <Grid container xs={12}>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
			<InputLabel>{t("STUDENT_NAME")}:</InputLabel>
				<FormInput
					name="first_name"
					type="text"
					placeholder={`${t("STUDENT_NAME")}....`}
					control={control}
					className="input-from"
					rules={{
						required: {
							value: true,
							message: `${t("PLEASE_ENTER_THE_STUDENT_NAME")}`,
						},
					}}
					inputProps={{
						...register('first_name', {}),
					}}
					errors={errors}
				/>
			</Grid>{' '}
        	<Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
			<InputLabel>{t("EMIS_NO")}:</InputLabel>
				<FormInput
					name="student_emis_no"
					type="text"
					placeholder={`${t('EMIS_NO')}...`}
					control={control}
					className="input-from"
					rules={{
						required: {
							value: true,
							message: `${t("PLEASE_ENTER_LAST_EMI")}`,
							
						},
					}}
					inputProps={{
						...register('student_emis_no', {}),
					}}
					errors={errors}
				/>
			</Grid>

{/* <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
          <InputLabel>{t("SCHOOL")}:</InputLabel>
        <Controller
         control={control}
         name={'school_id'}
          render={({ field }) => (
            <Select
                options={schoolList} 
                isSearchable
                placeholder={`${t("SCHOOL")}...`}
                value={
                    schoolList.find(
                        (option:any) => option.id === field.value
                    ) || null
                }
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(selectedOption) => {
                    handleSchoolChange(selectedOption?.id || '',selectedOption.short_code);
                    field.onChange(selectedOption?.id || '');
                }}
                onBlur={() => field.onBlur()}
                isClearable
            />
        )}
        rules={{ required: `${t("PLEASE_SELECT_YOUR_SCHOOL_TYPE")}` }}
    />
    {errors?.school_id?.message ? (
        <Typography color={theme.palette.error.main} fontSize={12}>
            <>{errors?.school_id?.message}</>
        </Typography>
    ) : null}
</Grid> */}
<Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
<InputLabel>{t('Select_District')}:</InputLabel>
          <><Controller
control={control}
name={'district_id'}
 render={({ field }) => (
   <Select
       options={districtList}
       isSearchable
       placeholder={`${t('Select_District')}...`}
       value={
           districtList.find(
               (option:any) => option.id === field.value
           ) || null
       }
       getOptionLabel={(option) => option.name}
       getOptionValue={(option) => option.id}
       onChange={(selectedOption) => {
           handleDistrictChange(selectedOption?.id || '',selectedOption?.name);
           field.onChange(selectedOption?.id || '');
       }}
       onBlur={() => field.onBlur()}
       isClearable
   />
)}
rules={{ required: `Please Select Your District` }}
/>
{errors?.course_id?.message ? (
<Typography color={theme.palette.error.main} fontSize={12}>
   <>{errors?.course_id?.message}</>
</Typography>

) : null}

</>
    {errors?.school_id?.message ? (
        <Typography color={theme.palette.error.main} fontSize={12}>
            <>{errors?.school_id?.message}</>
        </Typography>
    ) : null}
</Grid>
<Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
<InputLabel>{t('Select_Stream')}:</InputLabel>
          <><Controller
control={control}
name={'course_id'}
 render={({ field }) => (
   <Select
       options={courseList}
       isSearchable
       placeholder={`${t('Select_Stream')}...`}
       value={
           courseList.find(
               (option:any) => option.id === field.value
           ) || null
       }
       getOptionLabel={(option) => option.name}
       getOptionValue={(option) => option.id}
       onChange={(selectedOption) => {
           handleCourseChange(selectedOption?.id || '',selectedOption?.name);
           field.onChange(selectedOption?.id || '');
       }}
       onBlur={() => field.onBlur()}
       isClearable
   />
)}
rules={{ required: `Please Select Your School Type` }}
/>
{errors?.course_id?.message ? (
<Typography color={theme.palette.error.main} fontSize={12}>
   <>{errors?.course_id?.message}</>
</Typography>

) : null}

</>
    {errors?.school_id?.message ? (
        <Typography color={theme.palette.error.main} fontSize={12}>
            <>{errors?.school_id?.message}</>
        </Typography>
    ) : null}
</Grid>
{streamWatcher && districtWatcher && (<>
  {Number(streamWatcher)===8?(<>


<Grid  item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t('Course_Name')}</InputLabel>
            <FormInput
                name="course_name"
                type="text"
                placeholder={`${t('Course_Name')}....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('course_name', {}),
                }}
                errors={errors}
            />
        </Grid>
        <Grid  item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t('college_name')}</InputLabel>
            <FormInput
                name="college_name"
                type="text"
                placeholder={`${t('college_name')}....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('college_name', {}),
                }}
                errors={errors}
            />
        </Grid></>):(<>
        {collegeLoading?<CircularProgress />:


        <>         

{(Number(districtWatcher) === 2 || Number(districtWatcher) === 20 || Number(districtWatcher) === 9)?(
  <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
    <InputLabel>{t('SELECT_COLLEGE')}:</InputLabel>
    <Controller
      control={control}
      name={'college_id'}
      render={({ field }) => (
        <Select
          options={collegeList}
          isSearchable
          placeholder={`${t('SELECT_COLLEGE')}...`}
          value={
            collegeList.find(
              (option:any) => option.id === field.value
            ) || null
          }
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          onChange={(selectedOption) => {
            handleCollegeName(selectedOption?.id || '', selectedOption?.name);
            field.onChange(selectedOption?.id || '');
          }}
          onBlur={() => field.onBlur()}
          isClearable
        />
      )}
      rules={{ required: `Please Select Your School Type` }}
    />
    {errors?.college_id?.message ? (
      <Typography color={theme.palette.error.main} fontSize={12}>
        <>{errors?.college_id?.message}</>
      </Typography>
    ) : null}
  </Grid>
):(<>
<Grid  item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t('college_name')}</InputLabel>
            <FormInput
                name="college_name"
                type="text"
                placeholder={`${t('college_name')}....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('college_name', {}),
                }}
                errors={errors}
            />
        </Grid></>)
}

        
       
        </>}
</>)}</>)}



          </Grid>

          <Grid
            item
            xs={12}
            justifyContent="flex-end"
            display={'flex'}
            my={5}
          >
            <CustomButton
              type="submit"
              text={t("SUBMIT")}
              className="p-12 secondary-button"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default StudentAddScreen;
