import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { APP_ROUTES, PUBLIC_ROUTES } from '../common/constants/Routes';
import DashboardScreen from '../features/app/DashboardScreen';
import AppLayout from './AppLayout';
import Student from '../features/app/Students/TeamMember/Student';
import EditStudenrt from '../features/app/Students/TeamMember/EditStudenrt';
import Schools from '../features/app/School/Schools';
import SchoolForm from '../features/app/School/SchoolForm';
import Questions from '../features/app/Questions/Questions';
import QuestionsForm from '../features/app/Questions/QuestionsForm';
import ChangePassword from '../features/app/ChangePassword';
import SurveyDetails from '../features/app/Students/SurveyModule/SurveyDetails';
import SchoolWiseStudentDetaails from '../features/app/Students/TeamMember/SchoolWiseStudentDetaails';
import ProfileScreen from '../features/app/ProfileScreen/ProfileScreen';
import StreamBasedScreen from '../features/app/StreamBasedScreen';
import EditSurveyForm from '../features/app/Students/TeamMember/EditSurveyForm';
import HigherStudiesAppliedScreen from '../features/app/HigherStudiesAppliedScreen';
import StudentAddScreen from '../features/app/StudentAddScreen';
import SchoolListScreen from '../features/app/School/SchoolListScreen';
import StudentEditForm from '../features/app/Students/TeamMember/StudentEditForm';
import CollegeListScreen from '../features/app/CollegeListScreen';
import SubjectListScreen from '../features/app/SubjectListScreen';



const AppRoutes = ({ isLogged }: any) => {
	console.log('isLogged', isLogged, localStorage.getItem('user'));
	const user = localStorage.getItem('user');
	console.log('user-AppRoutes', user);
	return (
		<Routes>
			{isLogged === true ? (
				<Route path="/" element={<AppLayout />}>
					<Route path={APP_ROUTES.DASHBOARD} element={<DashboardScreen />} />
					<Route path={APP_ROUTES.STUDENT_LIST} element={<Student />} />
					<Route path={APP_ROUTES.STUDENT_FORM} element={<EditStudenrt />} />
					<Route path={APP_ROUTES.SCHOOL} element={<Schools />} />
					<Route path={APP_ROUTES.SCHOOLFORM} element={<SchoolForm />} />
					<Route path={APP_ROUTES.QUESTIONS} element={<Questions />} />
					<Route path={APP_ROUTES.QUESTIONFORM} element={<QuestionsForm />} />
					<Route path={APP_ROUTES.SURVEY_DETAILS} element={<SurveyDetails/>} />
					<Route path={APP_ROUTES.CHANGE_PASSWORD} element={<ChangePassword/>} />
					<Route path={APP_ROUTES.SCHOOLWISE_DEATILS} element={<SchoolWiseStudentDetaails/>} />
					<Route path={APP_ROUTES.PROFILE_UPDATE} element={<ProfileScreen/>} />
					<Route path={APP_ROUTES.STREAM_BASED_SCREEN} element={<StreamBasedScreen/>} />
					<Route path={APP_ROUTES.HIGHER_STUDIES_APPLIED_SCREEN} element={<HigherStudiesAppliedScreen/>} />
					<Route path={APP_ROUTES.STUDENT_ADD_SCREEN} element={<StudentAddScreen/>} />
					<Route path={APP_ROUTES.SCHOOL_LIST_SCREEN} element={<SchoolListScreen/>} />
					<Route path={APP_ROUTES.STUDENT_EDIT_FORM} element={<StudentEditForm/>} />
					<Route path={APP_ROUTES.COLLEGE_LIST_SCREEN} element={<CollegeListScreen/>} />
					<Route path={APP_ROUTES.SUBJECT_WISE_LIST_SCREEN} element={<SubjectListScreen/>} />
					
					{/* ---------------Super Admin End--------------- */}
					{!Object.values(PUBLIC_ROUTES).includes("sds") ? (
						<Route path="*" element={<Navigate to={APP_ROUTES.DASHBOARD} />} />
					) : 
					(
						<></>
					)}
				</Route>
			) : (
				<></>
			)}
		</Routes>
	);
};

export default AppRoutes;
