
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_TYPE } from '../../models/CommonModels';
import { RootState, useAppSelector } from '../store';
import { questionsModel, SurveyModel } from '../../models/StudentModel';

interface questionsDetailsTypes extends INITIAL_STATE_TYPE {
	data: null | SurveyModel;
}

interface questionsListTypes extends INITIAL_STATE_TYPE {
	data: questionsModel[];
}

interface InitialStateTypes {
	savequestions: INITIAL_STATE_TYPE;
	questionsList: questionsListTypes;
	questionsDetails:any;
    updatequestions:any;
    deletequestions:any;
	questionsTypes:any;
	csvquestionsList:any;


	
	
}

const initialState: InitialStateTypes = {


	questionsList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},

	savequestions: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	questionsDetails: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: null,
	},
    updatequestions: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	deletequestions: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	questionsTypes: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	csvquestionsList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},

};

const questionsSlice = createSlice({
	name: 'questions',
	initialState,
	reducers: {
	
		
		questionsListInProgress: (state) => {
			state.questionsList.data = [];
			state.questionsList.loading = true;
			state.questionsList.success = false;
			state.questionsList.error = false;
			state.questionsList.message = null;
		},
		questionsListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: questionsModel[]; message: string }>
		) => {
			state.questionsList.data = payload.data;
			state.questionsList.loading = false;
			state.questionsList.success = true;
			state.questionsList.message = payload.message;
		},
		questionsListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.questionsList.data = [];
			state.questionsList.loading = false;
			state.questionsList.error = true;
			state.questionsList.message = payload.message;
		},

	

		savequestionsInProgress: (state, {}: PayloadAction<questionsModel>) => {
			state.savequestions.loading = true;
			state.savequestions.success = false;
			state.savequestions.error = false;
			state.savequestions.message = null;
		},
		savequestionsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.savequestions.loading = false;
			state.savequestions.success = true;
			state.savequestions.message = payload.message;
		},
		savequestionsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.savequestions.loading = false;
			state.savequestions.error = true;
			state.savequestions.message = payload.message;
		},
		
		questionsDetailsInProgress: (state, {}: PayloadAction<string>) => {
			state.questionsDetails.loading = true;
			state.questionsDetails.success = false;
			state.questionsDetails.error = false;
			state.questionsDetails.message = null;
		},
		questionsDetailsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data:any; message: string }>
		) => {
			state.questionsDetails.data = payload.data;
			state.questionsDetails.loading = false;
			state.questionsDetails.success = true;
			state.questionsDetails.message = payload.message;
		},
		questionsDetailsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.questionsDetails.data = null;
			state.questionsDetails.loading = false;
			state.questionsDetails.error = true;
			state.questionsDetails.message = payload.message;
		},
        updatequestionsInProgress: (state, {}: PayloadAction<questionsModel>) => {
			state.updatequestions.loading = true;
			state.updatequestions.success = false;
			state.updatequestions.error = false;
			state.updatequestions.message = null;
		},
		updatequestionsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.updatequestions.loading = false;
			state.updatequestions.success = true;
			state.updatequestions.message = payload.message;
		},
		updatequestionsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.updatequestions.loading = false;
			state.updatequestions.error = true;
			state.updatequestions.message = payload.message;
		},
		deletequestionsInProgress: (state, {}: PayloadAction<questionsModel>) => {
			state.deletequestions.loading = true;
			state.deletequestions.success = false;
			state.deletequestions.error = false;
			state.deletequestions.message = null;
		},
		deletequestionsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.deletequestions.loading = false;
			state.deletequestions.success = true;
			state.deletequestions.message = payload.message;
		},
		deletequestionsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.deletequestions.loading = false;
			state.deletequestions.error = true;
			state.deletequestions.message = payload.message;
		},
		questionsTypeInProgress: (state) => {
			state.questionsTypes.data = [];
			state.questionsTypes.loading = true;
			state.questionsTypes.success = false;
			state.questionsTypes.error = false;
			state.questionsTypes.message = null;
		},
		questionsTypeInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: questionsModel[]; message: string }>
		) => {
			state.questionsTypes.data = payload.data;
			state.questionsTypes.loading = false;
			state.questionsTypes.success = true;
			state.questionsTypes.message = payload.message;
		},
		questionsTypeError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.questionsTypes.data = [];
			state.questionsTypes.loading = false;
			state.questionsTypes.error = true;
			state.questionsTypes.message = payload.message;
		},
		
		csvquestionsListInProgress: (state) => {
			state.csvquestionsList.data = [];
			state.csvquestionsList.loading = true;
			state.csvquestionsList.success = false;
			state.csvquestionsList.error = false;
			state.csvquestionsList.message = null;
		},
		csvquestionsListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: questionsModel[]; message: string }>
		) => {
			state.csvquestionsList.data = payload.data;
			state.csvquestionsList.loading = false;
			state.csvquestionsList.success = true;
			state.csvquestionsList.message = payload.message;
		},
		csvquestionsListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.csvquestionsList.data = [];
			state.csvquestionsList.loading = false;
			state.csvquestionsList.error = true;
			state.csvquestionsList.message = payload.message;
		},

	
	
	},
});

export const {
	questionsListInProgress,
    questionsListInSuccess,
    questionsListInError,
    savequestionsInProgress,
    savequestionsInSuccess,
    savequestionsInError,
	questionsDetailsInProgress,
	questionsDetailsInSuccess,
	questionsDetailsInError,
    updatequestionsInProgress,
    updatequestionsInError,
    updatequestionsInSuccess,
    deletequestionsInProgress,
    deletequestionsInError,
    deletequestionsInSuccess,
	questionsTypeInProgress,
	questionsTypeInSuccess,
	questionsTypeError,
	csvquestionsListInProgress,
	csvquestionsListInSuccess,
	csvquestionsListInError
	

} = questionsSlice.actions;



export const selectSavestudentLoading = (state: RootState) =>
	state.questions.savequestions.loading;
export const selectquestionsDetailsListLoading = (state: RootState) =>
	state.questions.questionsDetails.loading;
export const selectquestionsDetailsList = (state: RootState) =>
	state.questions.questionsDetails.data;
export const questionsDetailsListSuccess = (state: RootState) =>
	state.questions.questionsDetails.success;
export const selectUpdatequestionsLoading = (state: RootState) =>
	state.questions.updatequestions.loading;
	export const selectDeletequestionsLoading = (state: RootState) =>
	state.questions.deletequestions.loading;
	export const selectquestionsTypeListLoading = (state: RootState) =>
		state.questions.questionsTypes.loading;
	export const selectquestionsTypeList = (state: RootState) =>
		state.questions.questionsTypes.data;
	export const questionsTypeListSuccess = (state: RootState) =>
		state.questions.questionsTypes.success;
	export const selectCsvquestionsDetailsListLoading = (state: RootState) =>
		state.questions.csvquestionsList.loading;
	export const selectCsvquestionsDetailsList = (state: RootState) =>
		state.questions.csvquestionsList.data;
	export const questionsCsvDetailsListSuccess = (state: RootState) =>
		state.questions.csvquestionsList.success;

export default questionsSlice;
