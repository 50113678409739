const ROUTES = {
	loginRequest: {
		url: '/auth/logIn',
	},
	signUp: {
		url: '/auth/userSignup',
	},

	otp: {
		url: 'auth/otpMail',
	},
	otpVerify: {
		url: '/auth/otpVerification',
	},
	forgetPassword: {
		url: 'auth/updatePassword',
	},
	logOut: {
		url: 'auth/logOut',
	},
	changePassword:{
		url:'/auth/changePassword'
	}
};
const canditateRoutes = {
	candidateList: {
		url: '/auth/logIn',
	},
	candidateAdd: {
		url: '/candidate/add',
	},
};

const constituencyRoutes = {
	constituentList: { url: '/constituent/list' },
	parlimentConstituentList: { url: '/constituent/parlimentconstituentlist' },
};
const publicRoutes = {
	publicList: {
		url: '/people/list',
	},
	savePublic: {
		url: '/people/add',
	},
	updatePublic: {
		url: '/people/profileUpdate',
	},
};
const profileRoutes = {
	getProfile: {
		url: '/people/getProfile',
	},

	updateProfile: {
		url: '/people/profileUpdate',
	},
	updatePassword: {
		url: '/auth/updatePassword',
	},
};
const PetetionRoutes = {
	addPetetion: { url: '/petetion/add' },
	getPetetion: { url: '/petetion/details' },
	updatePetetion: { url: '/petetion/update' },
	editPetetion: { url: '/petetion/edit' },
	getPetetionList: { url: '/petetion/list' },
	deletePetetion: { url: '/petetion/delete' },
	subconstituent: { url: 'constituent/subconstituentdetails' },
	petetionLogs: { url: '/petetionlog/list' },
};
export {
	PetetionRoutes,
	canditateRoutes,
	publicRoutes,
	profileRoutes,
	constituencyRoutes,
};

export default ROUTES;
