
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_TYPE } from '../../models/CommonModels';
import { RootState, useAppSelector } from '../store';
import { SurveyModel } from '../../models/StudentModel';

interface SurveyDetailsTypes extends INITIAL_STATE_TYPE {
	data: null | SurveyModel;
}

interface SurveyListTypes extends INITIAL_STATE_TYPE {
	data: SurveyModel[];
}

interface InitialStateTypes {
	saveSurvey: INITIAL_STATE_TYPE;
	surveyList: SurveyListTypes;
	surveyDetails:any;
	dashboardList:any;
	updateSurvey:INITIAL_STATE_TYPE;

	
	
}

const initialState: InitialStateTypes = {
	dashboardList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},

	surveyList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},

	saveSurvey: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	updateSurvey: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	surveyDetails: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: null,
	},

};

const surveySlice = createSlice({
	name: 'surveys',
	initialState,
	reducers: {
	
		
		surveyListInProgress: (state) => {
			state.surveyList.data = [];
			state.surveyList.loading = true;
			state.surveyList.success = false;
			state.surveyList.error = false;
			state.surveyList.message = null;
		},
		surveyListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: SurveyModel[]; message: string }>
		) => {
			state.surveyList.data = payload.data;
			state.surveyList.loading = false;
			state.surveyList.success = true;
			state.surveyList.message = payload.message;
		},
		surveyListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.surveyList.data = [];
			state.surveyList.loading = false;
			state.surveyList.error = true;
			state.surveyList.message = payload.message;
		},
			
		dashboardListInProgress: (state) => {
			state.dashboardList.data = [];
			state.dashboardList.loading = true;
			state.dashboardList.success = false;
			state.dashboardList.error = false;
			state.dashboardList.message = null;
		},
		dashboardListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: SurveyModel[]; message: string }>
		) => {
			state.dashboardList.data = payload.data;
			state.dashboardList.loading = false;
			state.dashboardList.success = true;
			state.dashboardList.message = payload.message;
		},
		dashboardListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.dashboardList.data = [];
			state.dashboardList.loading = false;
			state.dashboardList.error = true;
			state.dashboardList.message = payload.message;
		},

	

		saveSurveyInProgress: (state, {}: PayloadAction<SurveyModel>) => {
			state.saveSurvey.loading = true;
			state.saveSurvey.success = false;
			state.saveSurvey.error = false;
			state.saveSurvey.message = null;
		},
		saveSurveyInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.saveSurvey.loading = false;
			state.saveSurvey.success = true;
			state.saveSurvey.message = payload.message;
		},
		saveSurveyInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.saveSurvey.loading = false;
			state.saveSurvey.error = true;
			state.saveSurvey.message = payload.message;
		},
		updateSurveyInProgress: (state, {}: PayloadAction<SurveyModel>) => {
			state.updateSurvey.loading = true;
			state.updateSurvey.success = false;
			state.updateSurvey.error = false;
			state.updateSurvey.message = null;
		},
		updateSurveyInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.updateSurvey.loading = false;
			state.updateSurvey.success = true;
			state.updateSurvey.message = payload.message;
		},
		updateSurveyInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.updateSurvey.loading = false;
			state.updateSurvey.error = true;
			state.updateSurvey.message = payload.message;
		},
		
		surveyDetailsInProgress: (state, {}: PayloadAction<string>) => {
			state.surveyDetails.loading = true;
			state.surveyDetails.success = false;
			state.surveyDetails.error = false;
			state.surveyDetails.message = null;
		},
		surveyDetailsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data:any; message: string }>
		) => {
			state.surveyDetails.data = payload.data;
			state.surveyDetails.loading = false;
			state.surveyDetails.success = true;
			state.surveyDetails.message = payload.message;
		},
		surveyDetailsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.surveyDetails.data = null;
			state.surveyDetails.loading = false;
			state.surveyDetails.error = true;
			state.surveyDetails.message = payload.message;
		},
	
	
	},
});

export const {
	surveyListInProgress,
    surveyListInSuccess,
    surveyListInError,
    saveSurveyInProgress,
    saveSurveyInSuccess,
    saveSurveyInError,
	surveyDetailsInProgress,
	surveyDetailsInSuccess,
	surveyDetailsInError,
	dashboardListInProgress,
	dashboardListInSuccess,
	dashboardListInError,
	updateSurveyInProgress,
	updateSurveyInSuccess,
	updateSurveyInError
	

} = surveySlice.actions;



export const selectSavestudentLoading = (state: RootState) =>
	state.surveys.saveSurvey.loading;

export const selectUpdateSurveyLoading = (state: RootState) =>
	state.surveys.updateSurvey.loading;
export const selectSurveyDetailsListLoading = (state: RootState) =>
	state.surveys.surveyDetails.loading;
export const selectSurveyDetailsList = (state: RootState) =>
	state.surveys.surveyDetails.data;
export const surveyDetailsListSuccess = (state: RootState) =>
	state.surveys.surveyDetails.success;
export const selectDashboardDetailsListLoading = (state: RootState) =>
	state.surveys.dashboardList.loading;
export const selectDashboardDetailsList = (state: RootState) =>
	state.surveys.dashboardList.data;
export const surveyDashboardDetailsSuccess = (state: RootState) =>
	state.surveys.dashboardList.success;
	

export default surveySlice;
