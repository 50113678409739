import {
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
    Checkbox,
    MenuItem,
    Select as MuiSelect,
    TextField,
    FormLabel
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CustomButton from '../../../../common/ui/CustomButton';
import { collegeListInprogress, courseListInprogress, districtListInprogress, groupListInprogress, questionListBystandardInprogress, schoolListInprogress, selectCollegeList, selectCollegeListLoading, selectCourseList, selectDistrictList, selectDistrictListLoading, selectGroupList, selectQuestionListByStandard, selectQuestionListByStandardLoading, selectSchoolList, selectSchoolListLoading, selectSkillBasedCourse, selectStandardList, selectsubjectListById, selectsubjectListByIdLoading, skillBasedCourseListInprogress, standardListInProgress, subjetcListByGroupIdInprogress } from '../../../../store/students/_students.slice';
import dayjs from 'dayjs';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import { healthStatusOption, higherStudyOption, mediumType, passFailStatus, yearsList } from '../../../../common/constants/FormItems';
import { saveSurveyInProgress, updateSurveyInProgress } from '../../../../store/survey/_survey.slice';
import { SurveyModel } from '../../../../models/StudentModel';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { setAlertInfo } from '../../../../common/Common.slice';

const EditSurveyForm = ({
    text,
    handleSubmit,
    register,
    reset,
    errors,
    control,
    setValue,
    watch,
    getValues,
    locations
}: {
    text: any;
    handleSubmit: any;
    register: any;
    reset: any;
    errors: any;
    control: any;
    setValue: any;
    watch: any;
    getValues: any;
    locations:any;
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const type=locations?.state?.type
    const dispatch = useDispatch();
    console.log("type",locations?.state?.type)
    const districtDetailsLoading:any=useSelector(selectDistrictListLoading)

    const editDatas=locations.state?.data
    const group_id = editDatas?.answers?.studentMarks?.[0]?.group_id
    console.log("editDatas",editDatas,group_id)
    const courses_id=editDatas?.answers?.course_id
    const district_id=editDatas?.answers?.district_id
    console.log("courses_id",district_id,courses_id)
    const streamWatcher =watch('course_id')
const districtWatcher =watch('district_id')
    
    const [fisrtSubmit,setFirstSubmit]=useState(false)
    const schoolDetails: any = useSelector(selectSchoolList);
    const schoolListLoading:any=useSelector(selectSchoolListLoading)
    const courseDetails:any=useSelector(selectCourseList)
    const skillBasedCourseDetails:any=useSelector(selectSkillBasedCourse)
    const standardDetails: any = useSelector(selectStandardList);
    const questionList2: any = useSelector(selectQuestionListByStandard);
	const groupDetails: any = useSelector(selectGroupList);
	const subjectDetails =useSelector(selectsubjectListById);
	const subjectLoading =useSelector(selectsubjectListByIdLoading);
	const questionListLoading =useSelector(selectQuestionListByStandardLoading);
    const collegeLoading:any=useSelector(selectCollegeListLoading)
    const highetStudiesStatus=watch('higher_studies')
    const skillBasedCoursesStatus=watch('skill_based')
    const qusetionTypeWatcher=watch('question_type')
    const yearWatcher =watch('year')
    const coursesSelected=watch('course_id')
    const supplimentryStatus=watch('supplimentry')
    const standardWatcher=watch('standard_id')
    const groupWatcher=watch('group')
    const mediumWatcher=watch('medium')
    const appliedCollegeWatcher=watch('applied_college')
    const strugglingSubjectWatcher=watch('struggling_subject')
    const collegeDetails:any=useSelector(selectCollegeList)
    const collegeList:any=collegeDetails?.data||[]
  
    const districtDetails:any=useSelector(selectDistrictList)

    const districtList:any=districtDetails?.data||[]

	let counter =qusetionTypeWatcher===1?4:5;
	
	const subjectList:any=subjectDetails?.data || [];
	

    const questionList: any = questionList2?.data || [];
    const standardList: any = standardDetails?.data || [];
	const groupList: any = groupDetails?.data || [];
    const schoolList: any = schoolDetails?.data || [];
    const courseList: any = courseDetails?.data || [];
    const skillBaseCourseList: any = skillBasedCourseDetails?.data || [];

    const handleCollegeName= (value: any,short_code:any) => {
        console.log("value",value)
        setValue('college_id', value, {
            shouldValidate: true,
        });
        setValue('college_name', short_code, {
            shouldValidate: true,
        });
      
      
      
      };
    const onSubmit = (data:any) => {   
        const { standard_id, year, question_type, total_mark, percentage, ...answers } = data;
    
        const AnswerQuestions = questionList.map((question:any) => {
            const answer = answers[`question_${question.id}`];
            const optionKey = `option${question.options.findIndex((opt:any) => opt.option === answer) + 1}`;
            return {
                questions_id: question.id,
                question: question.questions,
                options: question.options,
                [optionKey]: answer,
            };
        });

    
        const mark = subjectList.map((subject:any) => {
            const internalMark = standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20" ? 0 : parseFloat(data[`subject_${subject.id}_internal_mark`]) || 0;
            const theoryMark = parseFloat(data[`subject_${subject.id}_theory_passmark`]) || 0;
            const practicalMark = parseFloat(data[`subject_${subject.id}_practical_passmark`]) || 0;
            const theoryPassMark = parseInt(subject.theory_passmark);
            const practicalPassMark = subject.practical_passmark ? parseInt(subject.practical_passmark) : undefined;
    
            const result = isSubjectFailed(internalMark, theoryMark, theoryPassMark, practicalMark, practicalPassMark) ? 2 : 1;
    
            return {
                group_id: standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20" ? 136 : data.group,
                subject_id: subject.id,
                subject_code: subject.subject_code,
                practical_marks: practicalMark,
                theory_marks: theoryMark,
                internal_marks: internalMark,
                result,
            };
        });
    
        const marks = Number(qusetionTypeWatcher) === 1 ? [] : mark;
        const handleSuccess = () => {
            navigate(APP_ROUTES.STUDENT_LIST);
            window.location.reload();
            const alertInfo: any = {
                open: true,
                type: 'SUCCESS',
                description: `Survey Updated Sucessfully`,
               
            };
            dispatch(setAlertInfo(alertInfo));
        };
        const higherStudies = Number(highetStudiesStatus) === 1 ? 1 : Number(appliedCollegeWatcher) === 2 ? 2 : 3;
    
        const payload :any= {
            id:editDatas?.answers?.id,
            student_id: locations?.state?.data?.id || locations?.state?.data?.StudentInfo?.id,
            standard_id,
            student_emis_no: locations?.state?.data?.student_emis_no || locations?.state?.data?.StudentInfo?.student_emis_no,
            school_id: data?.school_id,
            medium: data?.medium,
            year,
            total_mark: Number(qusetionTypeWatcher) === 1 ? "" : Math.round(overallTotal) || "",
            percentage: Number(qusetionTypeWatcher) === 1 ? "" : Math.round(overallPercentage) || "",   
            result: qusetionTypeWatcher,
            AnswerQuestions,
            studentMarks: marks,
            exams_attened:1,
            higher_studies: standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20" ? data.higher_studies : higherStudies,
            career_guidance: data.career_guidance,
            course_id: data?.course_id,
            skill_based: data?.skill_based?data?.skill_based:"2",
            skill_based_course_id: Number(skillBasedCoursesStatus) === 1 ? data.skill_based_course_id : null,
            course_name: data.course_name,
            college_name: data.college_name,
            college_id: data.college_id,
            supplimentry: Number(qusetionTypeWatcher) === 1 ? null : data.supplimentry,
            current_work: data.current_work,
            supplimentry_reason: Number(qusetionTypeWatcher) === 2 && Number(supplimentryStatus) === 2 ? data.supplimentry_reason : null,
            parent_status: data.parent_status,
            struggling_subject: Number(qusetionTypeWatcher) === 2 ? data.struggling_subject : null,
            struggling_subject_reson: Number(strugglingSubjectWatcher) === 1 ? data.struggling_subject_reson : null,
            applied_college: data?.applied_college,
            district_id:data?.district_id,
            handleSuccess,
        };
    
        if (!fisrtSubmit) {
            console.log("console3",fisrtSubmit)
            if (standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20") {
                console.log("console1",fisrtSubmit)
                const handleSuccess = () => {
                    setFirstSubmit(true);
                };
                const payload2 :any= { standard_id, year, question_type, handleSuccess };
                dispatch(questionListBystandardInprogress(payload2));
    
                const payload = {
                    group_id: 136,
                };
                dispatch(subjetcListByGroupIdInprogress( { payload } as any));
            } else {
                console.log("console2",fisrtSubmit)
                const handleSuccess = () => {
                    setFirstSubmit(true);
                };
                const payload2 :any= { standard_id, year, question_type, handleSuccess };
                dispatch(questionListBystandardInprogress(payload2));
            }
        }
    console.log("payload",payload)
        if (fisrtSubmit) {
            dispatch(updateSurveyInProgress(payload));
        }
    };
    
    

    useEffect(() => {
        
        dispatch(standardListInProgress());
		dispatch(groupListInprogress());
        dispatch(schoolListInprogress())
        dispatch(courseListInprogress())
        dispatch(skillBasedCourseListInprogress())
        dispatch(districtListInprogress())
        const payload :any={
            courses_id:courses_id,
            district_id:district_id
          }
         if(courses_id&&district_id){
          dispatch(collegeListInprogress(payload))
         }
         setValue('higher_studies', type==="Edit"?editDatas?.answers?.higher_studies===3?2:editDatas?.answers?.higher_studies:null, {
            shouldValidate: false,
        });
       if( group_id){
        const payload={
            group_id:group_id
        }
        dispatch(subjetcListByGroupIdInprogress( { payload } as any));
       }
        
    }, [dispatch]);

    const handleDistrictChange=(value:any,name:any)=>{
        setValue('district_id', value, {
          shouldValidate: true,
      });
      
         
      }

    const handleReportingManager = (value: any) => {
        setValue('standard_id', value, {
            shouldValidate: true,
        });
        setValue('applied_college', null, {
            shouldValidate: false,
        });
        setValue('career_guidance', null, {
            shouldValidate: false,
        });
        setValue('college_name', null, {
            shouldValidate: false,
        });
        setValue('college_id', null, {
            shouldValidate: false,
        });
        setValue('course_id', null, {
            shouldValidate: false,
        });
        
        setValue('course_name', null, {
            shouldValidate: false,
        });
        setValue('current_work', null, {
            shouldValidate: false,
        });
        setValue('higher_studies', null, {
            shouldValidate: false,
        });
        setValue('skill_based', null, {
            shouldValidate: false,
        });
        setValue('skill_based_course_id', null, {
            shouldValidate: false,
        });
        setValue('struggling_subject', null, {
            shouldValidate: false,
        });
        setValue('struggling_subject_reson', null, {
            shouldValidate: false,
        });
        setValue('supplimentry', null, {
            shouldValidate: false,
        });
        setValue('supplimentry_reason', null, {
            shouldValidate: false,
        });


if(yearWatcher&&qusetionTypeWatcher&&mediumWatcher&&fisrtSubmit){
    const payload2: any = { standard_id:value, year:yearWatcher ,question_type:qusetionTypeWatcher};
    dispatch(questionListBystandardInprogress(payload2));
}
if (value ==="ab2cc5ed-52d9-45a1-bb38-5d830354fd20"&&yearWatcher&&qusetionTypeWatcher&&mediumWatcher&&fisrtSubmit){
    const payload={
        group_id:136
    }
            dispatch(subjetcListByGroupIdInprogress({payload}as any));
}

    
    };
    
   
    const handlePassFailChange = (value: any) => {
        setValue('question_type', value, {
            shouldValidate: true,
        });
        console.log("standardWatcher",yearWatcher&&qusetionTypeWatcher&&mediumWatcher&&fisrtSubmit)
        if(yearWatcher&&qusetionTypeWatcher&&mediumWatcher&&fisrtSubmit){
            const payload2: any = { standard_id:standardWatcher, year:yearWatcher ,question_type:qusetionTypeWatcher};
            dispatch(questionListBystandardInprogress(payload2));
        }
    };
    const handleYearChange = (value: any) => {
        console.log("value",value)
        setValue('year', value, {
            shouldValidate: true,
        });
        if(Number(value)===2023){
            setValue('question_type',1,{
                shouldValidate: true,
            });
        }
    };
    
    const handleCourseChange2= (value: any) => {
       
        setValue('course_name', value, {
            shouldValidate: true,
        });
       
    };
    const handleGroupChange2 = (value:any, name:any) => {
        console.log("handleGroupChange2", value, name);
        setValue('course_name', value, { shouldValidate: true });
    };
    const handleSupplimentaryChange=(value:any)=>{
        setValue('supplimentry', value, {
            shouldValidate: true,
        });
        if(Number(value===1)){
            setValue('supplimentry_reason', null, {
                shouldValidate: false,
            });
        }
        
    }
	const handleGroupChange = (value: any) => {
		console.log("value",value)
        setValue('group', value, {
            shouldValidate: true,
        });

const payload={
	group_id:value
}
		dispatch(subjetcListByGroupIdInprogress({payload}as any));
    };
	const handleMediumChange = (value: any) => {
		console.log("value",value)
        setValue('medium', value, {
            shouldValidate: true,
        });}
        const handleSchoolChange = (value: any) => {
            console.log("value",value)
            setValue('school_id', value, {
                shouldValidate: true,
            });
        
        

    };
    const handleCourseChange = (value: any,name:any) => {
        console.log("value",value,"name",name)
        setValue('course_id', value, {
            shouldValidate: true,
        });
        if( Number(value)!==8){
            setValue('course_name', name);
        }
        const payload :any={
            courses_id:value,
            district_id:district_id
          }
         if(value&&district_id){
          dispatch(collegeListInprogress(payload))
         }
           
       

    
    

};
useEffect(() => {
    const payload :any={
      courses_id:Number(streamWatcher)===7?2:streamWatcher,
      district_id:districtWatcher
    }
   if(streamWatcher&&districtWatcher){
    dispatch(collegeListInprogress(payload))
   }
    
  
  
    
  
  }, [streamWatcher,districtWatcher]);
const handleSkillBasedCourseChange = (value: any) => {
    console.log("value",value)
    setValue('skill_based_course_id', value, {
        shouldValidate: true,
    });



};

console.log(highetStudiesStatus,"highetStudiesStatus")
const calculateOverallPassmark = (internal:any, theory:any, practical:any) => {
    let total = theory;
    if (standardWatcher !== "ab2cc5ed-52d9-45a1-bb38-5d830354fd20") {
        total += internal;
    }
    if (practical) {
        total += practical;
    }
    return total;
};
const overallTotal = subjectList.reduce((acc:any, subject:any) => {
    const internalMark = standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20" ? 0 : parseFloat(watch(`subject_${subject.id}_internal_mark`)) || 0;
    const theoryPassmark = parseFloat(watch(`subject_${subject.id}_theory_passmark`)) || 0;
    const practicalPassmark = parseFloat(watch(`subject_${subject.id}_practical_passmark`)) || 0;
    return acc + calculateOverallPassmark(internalMark, theoryPassmark, practicalPassmark);
}, 0);

const overallPercentage = subjectList.length > 0 ? (overallTotal / (subjectList.length * 100)) * 100 : 0;
    
    
const isSubjectFailed = (internalMark:any, theoryMark:any, theoryPass:any, practicalMark:any, practicalPass:any) => {
    if (theoryMark < theoryPass || (practicalPass && practicalMark < practicalPass)) return true;
    const totalMark = standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20" ? (theoryMark + (practicalMark || 0)) : (internalMark + theoryMark + (practicalMark || 0));
    return totalMark < 35;
};
useEffect(() => {
    if (editDatas) {
  
        const handleSuccess = () => {
            setFirstSubmit(true);
        };
        if(editDatas){
            const payload2: any = { standard_id:editDatas?.answers?.Standard?.id, year:editDatas?.answers?.year ,question_type:editDatas?.answers?.result,handleSuccess};
            dispatch(questionListBystandardInprogress(payload2));
        }
      editDatas?.answers?.studentMarks.forEach((mark: any) => {
        setValue(`subject_${mark.subject_id}_theory_passmark`, mark.theory_marks);
        setValue(`subject_${mark.subject_id}_practical_passmark`, mark.practical_marks);
        setValue(`subject_${mark.subject_id}_internal_mark`, mark.internal_marks);
      });
    }
  }, [editDatas, setValue]);
  useEffect(() => {
    if (editDatas && editDatas.answers && editDatas.answers.AnswerQuestions) {
      editDatas.answers.AnswerQuestions.forEach((question: any) => {
        const fieldName = `question_${question.questions_id}`;
  
      
        if (question.option1) {
          setValue(fieldName, question.option1);
        } else if (question.option2) {
          setValue(fieldName, question.option2);
        } else if (question.option3) {
          setValue(fieldName, question.option3);
        } else if (question.option4) {
          setValue(fieldName, question.option4);
        } else if (question.option5) {
          setValue(fieldName, question.option5);
        } else if (question.option6) {
          setValue(fieldName, question.option6);
        }
      });
    }
  }, [editDatas, setValue]);
  


    
    return (
        <Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                {schoolListLoading?
                <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
     <CircularProgress/>
    </Grid>:
    <>
    {console.log("locations",editDatas)}
          <InputLabel>{t("school_type")}:</InputLabel>
        <Controller
         control={control}
         name={'school_id'}
          render={({ field }) => (
            <Select
                options={schoolList} 
                isSearchable
                isDisabled={true}
                placeholder={`${t("school_type")}`}
                value={
                    schoolList.find(
                        (option:any) => option.id === field.value
                    ) || null
                }
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(selectedOption) => {
                    handleSchoolChange(selectedOption?.id || '');
                    field.onChange(selectedOption?.id || '');
                }}
                onBlur={() => field.onBlur()}
                defaultValue={type==="Edit"?editDatas?.answers?.School?.id:null}
                isClearable
                
            />
        )}
      defaultValue={type==="Edit"?editDatas?.answers?.School?.id:null}
        rules={{ required: `${t("school_type_required")}` }}
    />
    {errors?.school_id?.message ? (
        <Typography color={theme.palette.error.main} fontSize={12}>
            <>{errors?.school_id?.message}</>
        </Typography>
    ) : null}
    </>
}
</Grid>
<Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                    <InputLabel>{t("Year")}:</InputLabel>
                        <Controller
                            control={control}
                            name={'year'}
                            render={({ field }) => (
                                <Select
                                    options={yearsList}
                                    isSearchable
                                    isDisabled={true}
                                    placeholder={`${t("Year")}...`}
                                    value={
                                        yearsList.find(
                                            (option: any) => option.id === watch('year')
                                        ) || null
                                    }
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={(selectedOption) => {
                                        handleYearChange(selectedOption?.id || '');
                                        field.onChange(selectedOption?.id || '');
                                    }}
                                    onBlur={() => field.onBlur()}
                                    isClearable
                                    defaultValue={editDatas?.answers?.year||null}
                                />
                            )}
                            defaultValue={type==="Edit"?Number(editDatas?.answers?.year):null}
                            
                            rules={{ required: `${t("This_Field_Is_Required")}` }}
                        />
                        {errors?.year?.message ? (
                            <Typography color={theme.palette.error.main} fontSize={12}>
                                {errors?.year?.message}
                            </Typography>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                        <InputLabel>{t("Standard")}:</InputLabel>
                        <Controller
                            control={control}
                            name={'standard_id'}

                            render={({ field }) => (
                                <Select
                                    options={standardList}
                                    isSearchable
                                    isDisabled={true}
                                    placeholder={`${t('STANDARD')}`}
                                    value={
                                        standardList.find(
                                            (option: any) => option.id === watch('standard_id')
                                        ) || null
                                    }
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    onChange={(selectedOption) => {
                                        handleReportingManager(selectedOption?.id || '');
                                        field.onChange(selectedOption?.id || '');
                                    }}
                                    onBlur={() => field.onBlur()}
                                    isClearable
                                    defaultValue={type==="Edit"?editDatas?.answers?.Standard?.id:null}
                                    
                                />
                            )}
                            rules={{ required: `${t('STANDARD_IS_REQUIRED')}` }}
                            defaultValue={type==="Edit"?editDatas?.answers?.Standard?.id:null}
                        />
                        {errors?.standard_id?.message ? (
                            <Typography color={theme.palette.error.main} fontSize={12}>
                                {errors?.standard_id?.message}
                            </Typography>
                        ) : null}
                    </Grid>

                


            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
			    <InputLabel>{t('Medium')}:</InputLabel>
				<Controller
					control={control}
					name={'medium'}
            
					render={({ field }) => (
						<Select
							options={mediumType}
							isSearchable
                            isDisabled={true}
							placeholder={`${t("Medium")}...`}
							value={
								mediumType.find(
									(option:any) => option.id === parseInt(watch('medium'))
								) || null
							}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => option.id}
							onChange={(selectedOption) => {
								handleMediumChange(selectedOption?.id || '');
								field.onChange(selectedOption?.id || '');
							}}
							onBlur={() => field.onBlur()}
							isClearable
                            defaultValue={type==="Edit"?editDatas?.answers?.medium:null}
						/>
					)}
                    defaultValue={type==="Edit"?editDatas?.answers?.medium:null}
					rules={{ required: `${t('This_Field_Is_Required')}`}}
				/>

				{errors?.medium?.message ? (
					<Typography color={theme.palette.error.main} fontSize={12}>
						<>{errors?.medium?.message}</>
					</Typography>
				) : null}
			</Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
			    <InputLabel>{t('Pass')}/{t('Fail')}:</InputLabel>
				<Controller
					control={control}
					name={'question_type'}
					render={({ field }) => (
						<Select
							options={passFailStatus}
							isSearchable
                            isDisabled={true}
							placeholder={`${t('Pass')}/${t('Fail')}`}
							value={
								passFailStatus.find(
									(option:any) => option.id === parseInt(watch('question_type'))
								) || null
							}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => option.id}
							onChange={(selectedOption) => {
								handlePassFailChange(selectedOption?.id || '');
								field.onChange(selectedOption?.id || '');
							}}
							onBlur={() => field.onBlur()}
							isClearable
							defaultValue={type==="Edit"?editDatas?.answers?.result:null}
						/>
					)}
                    defaultValue={type==="Edit"?editDatas?.answers?.result:null}
					rules={{ required: `${t('This_Field_Is_Required')}`}}
				/>

				{errors?.question_type?.message ? (
					<Typography color={theme.palette.error.main} fontSize={12}>
						<>{errors?.question_type?.message}</>
					</Typography>
				) : null}
			</Grid>
				{questionList.length>0&&(<>
                {standardWatcher !=="ab2cc5ed-52d9-45a1-bb38-5d830354fd20"&&(<>
                   {Number(qusetionTypeWatcher)===2  &&(<>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
			    <InputLabel>{t('Select_Stream')}:</InputLabel>
				<Controller
					control={control}
					name={'group'}
					render={({ field }) => (
						<Select
							options={groupList}
							isSearchable
                            isDisabled={true}
							placeholder={`${t('Select_Stream')}`}
							value={
								groupList.find(
									(option:any) => option.id === parseInt(watch('group'))
								) || null
							}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => option.id}
							onChange={(selectedOption) => {
								handleGroupChange(selectedOption?.id || '');
								field.onChange(selectedOption?.id || '');
							}}
							onBlur={() => field.onBlur()}
							isClearable
						    defaultValue={editDatas?.answers?.studentMarks?.[0]?.group_id||null}
						/>
					)}
                    defaultValue={editDatas?.answers?.studentMarks?.[0]?.group_id||null}
					rules={{ required: `${t('This_Field_Is_Required')}`}}
				/>

				{errors?.group?.message ? (
					<Typography color={theme.palette.error.main} fontSize={12}>
						<>{errors?.group?.message}</>
					</Typography>
				) : null}
			</Grid></>)}
              </>)}
                </>
               
        )}	
       
       {qusetionTypeWatcher === 2 && (
                <>
                    {subjectLoading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            {subjectList.length > 0 && subjectList.map((subject:any) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    key={subject.id}
                                    p={2}
                                    display={"flex"}
                                    flexDirection={"column"}
                                >
                                    <Grid xs={12}>
                                        <Typography variant="h6">{subject.subject}</Typography>
                                    </Grid>
                                    <Grid display={"flex"}>
                                    {standardWatcher !=="ab2cc5ed-52d9-45a1-bb38-5d830354fd20"&&(
                                        <>
                                         <FormInput
                                            name={`subject_${subject.id}_internal_mark`}
                                            type="number"
                                            placeholder="Internal Mark"
                                            control={control}
                                            className="input-from"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Internal Mark is required",
                                                },
                                                max: {
                                                    value: parseInt(subject.internal_mark),
                                                    message: `Internal Mark cannot exceed ${subject.internal_mark}`,
                                                },
                                            }}
                                            inputProps={{
                                                ...register(`subject_${subject.id}_internal_mark`),
                                                readOnly: true,
                                            }}
                                            errors={errors}
                                        />
                                        </>
                                    )}
                                       
                                       <FormInput
            name={`subject_${subject.id}_theory_passmark`}
            type="number"
            placeholder="Theory Passmark"
            control={control}
            className="input-from"
            rules={{
                required: {
                    value: true,
                    message: "Theory Passmark is required",
                },
                max: {
                    value: parseInt(subject.maximum_theorymark),
                    message: `Theory mark cannot exceed ${subject.maximum_theorymark}`,
                },
            }}
            inputProps={{
                ...register(`subject_${subject.id}_theory_passmark`),
                readOnly: true,
            }}
            errors={errors}
        />
                                        {subject.practical_passmark && (
                                     <FormInput
                                     name={`subject_${subject.id}_practical_passmark`}
                                     type="number"
                                     placeholder="Practical Passmark"
                                     control={control}
                                     className="input-from"
                                     rules={{
                                         required: {
                                             value: true,
                                             message: "Practical Passmark is required",
                                         },
                                         max: {
                                             value: standardWatcher === "a3c15d59-31d5-49fe-a002-f741e23ff80e" ? 20 : 25,
                                             message: `Practical mark cannot exceed ${standardWatcher === "a3c15d59-31d5-49fe-a002-f741e23ff80e" ? 20 : 25}`,
                                         },
                                     }}
                                     inputProps={{
                                         ...register(`subject_${subject.id}_practical_passmark`),
                                         readOnly: true,
                                     }}
                                     errors={errors}
                                 />
                                        )}
                                         <FormInput
            name={`subject_${subject.id}_overall_passmark`}
            type="number"
            placeholder="Overall Passmark"
            control={control}
            className="input-from"
            inputProps={{
                ...register(`subject_${subject.id}_overall_passmark`),
                readOnly: true, // Making the field read-only
                value: calculateOverallPassmark(
                    parseFloat(watch(`subject_${subject.id}_internal_mark`)),
                    parseFloat(watch(`subject_${subject.id}_theory_passmark`)),
                    parseFloat(watch(`subject_${subject.id}_practical_passmark`))
                ),
            }}
            errors={errors}
        />
                                     <TextField
            label={`Pass/Fail`}
            variant="outlined"
            size="small"
            className="input-from"
            fullWidth
            value={
                isSubjectFailed(
                    parseFloat(watch(`subject_${subject.id}_internal_mark`)) || 0,
                    parseFloat(watch(`subject_${subject.id}_theory_passmark`)) || 0,
                    parseInt(subject.theory_passmark),
                    parseFloat(watch(`subject_${subject.id}_practical_passmark`)) || 0,
                    subject.practical_passmark ? parseInt(subject.practical_passmark) : undefined
                ) ? 'Fail' : 'Pass'
            }
            InputProps={{
                readOnly: true,
            }}
        />
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    )}
                    {subjectList.length > 0 && (
                        <>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
    <Typography variant="h6">Overall Total Marks</Typography>
    <Controller
        name="total_mark"
        control={control}
        defaultValue={overallTotal.toFixed(2)}
        render={({ field }) => (
            <TextField
                {...field}
                value={overallTotal.toFixed(2)} // Ensure it's a fixed 2 decimal places string
                InputProps={{ readOnly: true }}
                fullWidth
            />
        )}
    />
</Grid>
<Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
    <Typography variant="h6">Overall Percentage</Typography>
    <Controller
        name="percentage"
        control={control}
        defaultValue={overallPercentage.toFixed(2)}
        render={({ field }) => (
            <TextField
                {...field}
                value={overallPercentage.toFixed(2)} // Ensure it's a fixed 2 decimal places string
                InputProps={{ readOnly: true }}
                fullWidth
            />
        )}
    />
</Grid>


                            <Grid item xs={12} p={2}>
                                <InputLabel>நீங்கள் மறுதேர்வுக்கு விண்ணப்பித்துள்ளீர்களா?</InputLabel>
                                <Controller
                                    control={control}
                                    name={'supplimentry'}
                                    render={({ field }) => (
                                        <Select
                                            options={higherStudyOption}
                                            isSearchable
                                            isDisabled={true}
                                            placeholder={"மறுதேர்வுக்கு விண்ணப்பித்துள்ளீர்களா.."}
                                            value={
                                                higherStudyOption.find(
                                                    (option) => option.id === watch('supplimentry')
                                                ) || null
                                            }
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            onChange={(selectedOption) => {
                                                handleSupplimentaryChange(selectedOption?.id || '');
                                                field.onChange(selectedOption?.id || '');
                                            }}
                                            onBlur={() => field.onBlur()}
                                            isClearable
                                            defaultValue={editDatas?.answers?.supplimentry}
                                        />
                                    )}
                                    defaultValue={editDatas?.answers?.supplimentry}
                                    rules={{ required: "This Field  is required" }}
                                />
                                {errors?.supplimentry?.message ? (
                                    <Typography color={theme.palette.error.main} fontSize={12}>
                                        {errors?.supplimentry?.message}
                                    </Typography>
                                ) : null}
                            </Grid>
                            {supplimentryStatus && (
                                <>
                                    {Number(supplimentryStatus) === 2 && (
                                        <Grid item xs={12} sm={8} md={8} lg={6} xl={6} p={2}>
                                            <InputLabel>மறுதேர்வுக்கு விண்ணப்பிக்காத காரணம்:</InputLabel>
                                            <FormInput
                                                name="supplimentry_reason"
                                                type="text"
                                                placeholder={`காரணம்....`}
                                                control={control}
                                                className="input-from"
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: `This field is required`,
                                                    },
                                                }}
                                                inputProps={{
                                                    ...register('supplimentry_reason', {}),
                                                    readOnly:true
                                                }}
                                                defaultValue={editDatas?.answers?.supplimentry_reason}
                                                errors={errors}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
		
                 


            <>
            {questionList.length > 0?(<>          <Grid item xs={12} className='survey-questions-grid'>
                <Typography variant='h5'>
                  Survey Questions
                </Typography>
        <>
          
        <>
             
        <Grid item xs={12} p={2}>
    
        <Controller
            control={control}
            name="higher_studies"
            defaultValue={type==="Edit"?editDatas?.answers?.higher_studies===3?2:editDatas?.answers?.higher_studies:null}
            render={({ field }) => (
                <FormControl component="fieldset">
                    <FormLabel component="legend">
                    {standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20"?(<>
                    1.நீங்கள் உயர்கல்வியில் பயில விருப்பம் உள்ளவரா?</>):
                   <>1.நீங்கள் எந்த கல்லூரியிலாவது சேர்ந்துள்ளீர்களா?</>}
                        </FormLabel>
                    <RadioGroup
                        aria-labelledby="higher-studies-radio-group-label"
                        {...field}
                        name="higher_studies"
                        onChange={(e) => {
                            field.onChange(e); // Update the field value
                            console.log(e.target.value); 
                            console.log("e",e.target.value)
                            if(Number(e.target.value)===2){
                                   setValue('course_name', null, {
                                    shouldValidate: false,
                                });
                                setValue('district_id', null, {
                                    shouldValidate: false,
                                });
                                setValue('course_id', null, {
                                    shouldValidate: false,
                                });
                                 setValue('college_name', null, {
                                    shouldValidate: false,
                                }); 
                                setValue('college_id', null, {
                                    shouldValidate: false,
                                }); 
                                
                             
                            }
                            if(Number(e.target.value)===1){
                                setValue('skill_based', null, {
                                 shouldValidate: false,
                             });
                             setValue('applied_college', null, {
                                shouldValidate: false,
                            });
                             setValue('current_work', null, {
                                shouldValidate: false,
                            });
                            
                          
                         }
                        }}
                    >
                        {higherStudyOption.map(option => (
                            <FormControlLabel 
                                key={option.id} 
                                value={option.id} 
                                control={<Radio />} 
                                label={option.name} 
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            )}
            rules={{ required: "This question is required" }}
        />
    </Grid>
    {Number(highetStudiesStatus)===1 &&(<>
    {districtDetailsLoading? <Grid item xs={12} sm={8} md={8} lg={8} xl={8} p={2}></Grid>:
      <Grid item xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
      <InputLabel>எந்த மாவட்டம்?:</InputLabel>
                <><Controller
      control={control}
      name={'district_id'}
       render={({ field }) => (
         <Select
             options={districtList}
             isSearchable
             placeholder={`${t('Select_District')}...`}
             value={
                 districtList.find(
                     (option:any) => option.id === field.value
                 ) || null
             }
             getOptionLabel={(option) => option.name}
             getOptionValue={(option) => option.id}
             onChange={(selectedOption) => {
                 handleDistrictChange(selectedOption?.id || '',selectedOption?.name);
                 field.onChange(selectedOption?.id || '');
             }}
             defaultValue={type==="Edit"?editDatas?.answers?.district_id:null}
             onBlur={() => field.onBlur()}
             isClearable
         />
      )}
      rules={{ required: `Please Select Your District` }}
      defaultValue={type==="Edit"?editDatas?.answers?.district_id:null}
      />
      {errors?.course_id?.message ? (
      <Typography color={theme.palette.error.main} fontSize={12}>
         <>{errors?.course_id?.message}</>
      </Typography>
      
      ) : null}
      
      </>
          {errors?.school_id?.message ? (
              <Typography color={theme.palette.error.main} fontSize={12}>
                  <>{errors?.school_id?.message}</>
              </Typography>
          ) : null}
      </Grid>}
      
    </>)}

    {highetStudiesStatus&&(<>
        {Number(highetStudiesStatus)===1?(<>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
     <InputLabel>1.2எந்த வகையான உயர்கல்வியில் சேர்ந்துள்ளீர்கள்?</InputLabel>
     {standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20"?<>
     <Controller
            control={control}
          
            name={'course_name'}
            render={({ field }) => (
                <Select
                    options={groupList}
                    isSearchable
                    placeholder={`Group...`}
                    value={
                        groupList.find(
                            (option:any) => option.name === field.value
                        ) || null
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    onChange={(selectedOption) => {
                        handleGroupChange2(selectedOption?.name || '', selectedOption?.name);
                        field.onChange(selectedOption?.name || '');
                    }}
                    onBlur={field.onBlur}
                    isClearable
                   
                />
            )}
             rules={{ required: 'Please Select Your Course' }}
        />

 {errors?.group?.message ? (
     <Typography color={theme.palette.error.main} fontSize={12}>
         <>{errors?.group?.message}</>
     </Typography>
 ) : null}</>
:
<><Controller
control={control}
name={'course_id'}
 render={({ field }) => (
   <Select
       options={courseList}
       isSearchable
       placeholder={`உங்கள் படிப்பைத் தேர்ந்தெடுக்கவும்...`}
       value={
           courseList.find(
               (option:any) => option.id === field.value
           ) || null
       }
       getOptionLabel={(option) => option.name}
       getOptionValue={(option) => option.id}
       onChange={(selectedOption) => {
           handleCourseChange(selectedOption?.id || '',selectedOption?.name);
           field.onChange(selectedOption?.id || '');
       }}
       onBlur={() => field.onBlur()}
       defaultValue={type==="Edit"?editDatas?.answers?.course_id:null}
       isClearable
   />
)}
rules={{ required: `Please Select Your School Type` }}
defaultValue={type==="Edit"?editDatas?.answers?.course_id:null}
        
/>
{errors?.course_id?.message ? (
<Typography color={theme.palette.error.main} fontSize={12}>
   <>{errors?.course_id?.message}</>
</Typography>
) : null}
</>}
    
	
</Grid>
{coursesSelected&&(<>
    {Number(coursesSelected)===8&&(<>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
        <InputLabel>படிப்பின் பெயர்:</InputLabel>
            <FormInput
                name="course_name"
                type="text"
                placeholder={`படிப்பின் பெயர்....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('course_name', {}),
                }}
                errors={errors}
                defaultValue={type==="Edit"?editDatas?.answers?.course_name:null}
            />
        </Grid></>)}
       
       
        {Number(highetStudiesStatus)===1?(<>
            {(Number(districtWatcher) === 2 || Number(districtWatcher) === 20 || Number(districtWatcher) === 9)?(<>
                {Number(coursesSelected) === 8 ? (<>
                    <Grid  item xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
            <InputLabel>1.3 கல்லூரியின் பெயர்?</InputLabel>
            <FormInput
                name="college_name"
                type="text"
                placeholder={`${t('college_name')}....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('college_name', {}),
                }}
                errors={errors}
                defaultValue={type==="Edit"?editDatas?.answers?.college_name:null}
            />
        </Grid></>):(<>
            {collegeLoading?
             <Grid item xs={12} sm={8} md={8} lg={6} xl={6} p={2} mt={1}>
                <CircularProgress />
             </Grid>
            :
             <Grid item xs={12}  sm={8} md={8} lg={8} xl={8} p={2} mt={1}>
             <InputLabel>1.3 கல்லூரியின் பெயர்?</InputLabel>
             <><Controller
     control={control}
     name={'college_id'}
      render={({ field }) => (
        <Select
            options={collegeList}
            isSearchable
            placeholder={`${t('SELECT_COLLEGE')}...`}
            value={
                collegeList.find(
                    (option:any) => option.id === field.value
                ) || null
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(selectedOption) => {
                handleCollegeName(selectedOption?.id || '',selectedOption?.name);
                field.onChange(selectedOption?.id || '');
            }}
            onBlur={() => field.onBlur()}
            isClearable
            defaultValue={type==="Edit"?editDatas?.answers?.college_id:null}
        />
     )}
     rules={{ required: `Please Select Your College` }}
     defaultValue={type==="Edit"?editDatas?.answers?.college_id:null}
     />
     {errors?.college_id?.message ? (
     <Typography color={theme.palette.error.main} fontSize={12}>
        <>{errors?.college_id?.message}</>
     </Typography>
     
     ) : null}
     
     </>
             </Grid>}
           </>)}</>):(<>
        {collegeLoading?<CircularProgress />:
          <Grid  item xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
          <InputLabel>1.3 கல்லூரியின் பெயர்?</InputLabel>
          <FormInput
              name="college_name"
              type="text"
              placeholder={`${t('college_name')}....`}
              control={control}
              className="input-from"
              rules={{
                  required: {
                      value: true,
                      message: `This field is required`,
                  },
              }}
              inputProps={{
                  ...register('college_name', {}),
              }}
              errors={errors}
              defaultValue={type==="Edit"?editDatas?.answers?.college_name:null}
          />
      </Grid>}
          
        </>)}


        </>):
        (<>
       
        </>)}
       
</>)}


</>):(<>
{standardWatcher !=="ab2cc5ed-52d9-45a1-bb38-5d830354fd20"&&(<>
    <Grid item xs={12} p={2}>
    <Controller
            control={control}
            name="applied_college"
            defaultValue={Number(editDatas?.answers?.higher_studies)===1?null:editDatas?.answers?.applied_college}
            render={({ field }) => (
                <FormControl component="fieldset">
                    <FormLabel component="legend">1.2 நீங்கள் எந்த கல்லூரிக்காவது விண்ணபித்துள்ளீர்களா?</FormLabel>
                    <RadioGroup
                        aria-labelledby="applied_college-radio-group-label"
                        {...field}
                        name="applied_college"
                        onChange={(e) => {
                            field.onChange(e); 
                            console.log(e.target.value); 
                            if(Number(e.target.value)===2){
                                setValue('course_name', null, {
                                    shouldValidate: false,
                                });
                                setValue('course_id', null, {
                                    shouldValidate: false,
                                });
                                 setValue('college_name', null, {
                                    shouldValidate: false,
                                });
                                setValue('district_id', null, {
                                    shouldValidate: false,
                                });
                                setValue('college_id', null, {
                                    shouldValidate: false,
                                });
                                setValue('skill_based', null, {
                                    shouldValidate: false,
                                });
                            }// Do something with the value, like logging
                        }}
                    >
                        {higherStudyOption.map(option => (
                            <FormControlLabel 
                                key={option.id} 
                                value={option.id} 
                                control={<Radio />} 
                                label={option.name} 
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            )}
            rules={{ required: "This question is required" }}
        />
    
    
</Grid></>)}
{appliedCollegeWatcher&& Number(appliedCollegeWatcher)===1 &&(<>
{districtDetailsLoading?<Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}><CircularProgress/></Grid>:
  <Grid item xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
  <InputLabel>எந்த மாவட்டம்?:</InputLabel>
            <><Controller
  control={control}
  name={'district_id'}
   render={({ field }) => (
     <Select
         options={districtList}
         isSearchable
         placeholder={`${t('Select_District')}...`}
         value={
             districtList.find(
                 (option:any) => option.id === field.value
             ) || null
         }
         getOptionLabel={(option) => option.name}
         getOptionValue={(option) => option.id}
         onChange={(selectedOption) => {
             handleDistrictChange(selectedOption?.id || '',selectedOption?.name);
             field.onChange(selectedOption?.id || '');
         }}
         defaultValue={type==="Edit"?editDatas?.answers?.district_id:null}
         onBlur={() => field.onBlur()}
         isClearable
         
     />
  )}
  defaultValue={type==="Edit"?editDatas?.answers?.district_id:null}
  rules={{ required: `Please Select Your District` }}
  />
  {errors?.course_id?.message ? (
  <Typography color={theme.palette.error.main} fontSize={12}>
     <>{errors?.course_id?.message}</>
  </Typography>
  
  ) : null}
  
  </>
      {errors?.school_id?.message ? (
          <Typography color={theme.palette.error.main} fontSize={12}>
              <>{errors?.school_id?.message}</>
          </Typography>
      ) : null}
  </Grid>}
      
    </>)}
{appliedCollegeWatcher&& Number(appliedCollegeWatcher)===1?
(<>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
     <InputLabel>1.3எந்த வகையான உயர்கல்வியில் சேர விரும்புகிறீர்கள்?</InputLabel>
     {standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20"?<>
     <Controller
     control={control}
     name={'course_name'}
     render={({ field }) => (
         <Select
             options={groupList}
             isSearchable
             placeholder={`Course`}
             value={
                 groupList.find(
                     (option:any) => option.name === parseInt(watch('course_name'))
                 ) || null
             }
             getOptionLabel={(option) => `${option.name}`}
             getOptionValue={(option) => option.name}
             onChange={(selectedOption) => {
                 handleCourseChange2(selectedOption?.name || '');
                 field.onChange(selectedOption?.name || '');
             }}
             onBlur={() => field.onBlur()}
             isClearable
             defaultValue={type==="Edit"?editDatas?.answers?.course_name:null}
         />
     )}
     rules={{ required: `Please Select Your Course`}}
     defaultValue={type==="Edit"?editDatas?.answers?.course_name:null}
 />

 {errors?.group?.message ? (
     <Typography color={theme.palette.error.main} fontSize={12}>
         <>{errors?.group?.message}</>
     </Typography>
 ) : null}
 </>
:
<><Controller
control={control}
name={'course_id'}
 render={({ field }) => (
   <Select
       options={courseList}
       isSearchable
       placeholder={`உங்கள் படிப்பைத் தேர்ந்தெடுக்கவும்...`}
       value={
           courseList.find(
               (option:any) => option.id === field.value
           ) || null
       }
       getOptionLabel={(option) => option.name}
       getOptionValue={(option) => option.id}
       onChange={(selectedOption) => {
           handleCourseChange(selectedOption?.id || '',selectedOption?.name);
           field.onChange(selectedOption?.id || '');
       }}
       onBlur={() => field.onBlur()}
       isClearable
       defaultValue={type==="Edit"?editDatas?.answers?.course_id:null}
   />
)}
rules={{ required: `Please Select Your School Type` }}
defaultValue={type==="Edit"?editDatas?.answers?.course_id:null}
/>
{errors?.course_id?.message ? (
<Typography color={theme.palette.error.main} fontSize={12}>
   <>{errors?.course_id?.message}</>
</Typography>

) : null}
{coursesSelected&&(<>
    {/* {Number(coursesSelected)===8&&(<>
       
        <InputLabel>படிப்பின் பெயர்:</InputLabel>
            <FormInput
                name="course_name"
                type="text"
                placeholder={`படிப்பின் பெயர்....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('course_name', {}),
                }}
                errors={errors}
            />
        </>)} */}
       
       
        {coursesSelected?(<>
            {(Number(districtWatcher) === 2 || Number(districtWatcher) === 20 || Number(districtWatcher) === 9)?(<>
                {Number(coursesSelected)===8?(<>
    <Grid  item xs={12} sm={8} md={8} lg={8} xl={8} mt={3}>
        <InputLabel>1.4நீங்கள் சேர விரும்பும் கல்லூரி?</InputLabel>
            <FormInput
                name="college_name"
                type="text"
                placeholder={`${t('college_name')}....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('college_name', {}),
                }}
                errors={errors}
                defaultValue={type==="Edit"?editDatas?.answers?.college_name:null}
            />
        </Grid></>):(<>
        {collegeLoading? < Grid item xs={12} sm={12} md={12} lg={12} xl={12}  mt={3}>
        <CircularProgress/>
        </Grid>:
         < Grid item xs={12} sm={12} md={12} lg={12} xl={12}  mt={3}>
         <InputLabel>1.4நீங்கள் சேர விரும்பும் கல்லூரி?</InputLabel>
  <><Controller
 control={control}
 name={'college_id'}
 
  render={({ field }) => (
    <Select
        options={collegeList}
        isSearchable
        placeholder={`${t('SELECT_COLLEGE')}...`}
        value={
            collegeList.find(
                (option:any) => option.id === field.value
            ) || null
        }
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onChange={(selectedOption) => {
            handleCollegeName(selectedOption?.id || '',selectedOption?.name);
            field.onChange(selectedOption?.id || '');
        }}
        onBlur={() => field.onBlur()}
        isClearable
        defaultValue={type==="Edit"?editDatas?.answers?.college_id:null}
    />
 )}
 rules={{ required: `Please Select Your School Type` }}
 defaultValue={type==="Edit"?editDatas?.answers?.college_id:null}
 />
 {errors?.college_id?.message ? (
 <Typography color={theme.palette.error.main} fontSize={12}>
    <>{errors?.college_id?.message}</>
 </Typography>
 
 ) : null}
 
 </>
    
 
        </Grid>}
   </>)}</>):(<>
        <Grid  item xs={12} sm={8} md={8} lg={8} xl={8} mt={3}>
        <InputLabel>1.4நீங்கள் சேர விரும்பும் கல்லூரி?</InputLabel>
            <FormInput
                name="college_name"
                type="text"
                placeholder={`${t('college_name')}....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('college_name', {}),
                }}
                errors={errors}
                defaultValue={type==="Edit"?editDatas?.answers?.college_name:null}
            />
        </Grid></>)}
          
        
        </>
        ):
        (<>
        {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>1.3தற்போது என்ன செய்துகொண்டுள்ளீர்கள்?</InputLabel>
            <FormInput
                name="current_work"
                type="text"
                placeholder={`தற்போதையா வேலை....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('current_work', {}),
                }}
                errors={errors}
            />
        </Grid> */}
        </>)}
       
</>)}
</>}
    
	
</Grid>
{coursesSelected&&(<>
    {Number(coursesSelected)===8&&(<>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
        <InputLabel>படிப்பின் பெயர்:</InputLabel>
            <FormInput
                name="course_name"
                type="text"
                placeholder={`படிப்பின் பெயர்....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('course_name', {}),
                }}
                errors={errors}
                defaultValue={type==="Edit"?editDatas?.answers?.course_name:null}
            />
        </Grid></>)}
       
       
        {Number(highetStudiesStatus)===1?(<>
            <Grid xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
        <InputLabel>1.3நீங்கள் சேர விரும்பும் கல்லூரி?</InputLabel>
        
            <><Controller
control={control}
name={'college_id'}
 render={({ field }) => (
   <Select
       options={collegeList}
       isSearchable
       placeholder={`${t('SELECT_COLLEGE')}...`}
       value={
           collegeList.find(
               (option:any) => option.id === field.value
           ) || null
       }
       getOptionLabel={(option) => option.name}
       getOptionValue={(option) => option.id}
       onChange={(selectedOption) => {
           handleCollegeName(selectedOption?.id || '',selectedOption?.name);
           field.onChange(selectedOption?.id || '');
       }}
       onBlur={() => field.onBlur()}
       defaultValue={type==="Edit"?editDatas?.answers?.college_id:null}
       isClearable
   />
)}
rules={{ required: `Please Select Your College` }}
defaultValue={type==="Edit"?editDatas?.answers?.college_id:null}
/>
{errors?.college_id?.message ? (
<Typography color={theme.palette.error.main} fontSize={12}>
   <>{errors?.college_id?.message}</>
</Typography>

) : null}

</>
        </Grid>
        </>):
        (<>
       
        </>)}
       
</>)}
</>):(null)}
<Grid item xs={12} p={2}>



{standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20"&&Number(highetStudiesStatus)===2?
<>
    <Controller
        control={control}
        name="skill_based"
        defaultValue={type==="Edit"?editDatas?.answers?.skill_based:null}
        render={({ field }) => (
            <FormControl component="fieldset">
                <FormLabel component="legend">
                    {standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20"?
                    <>  1.2திறன் சார்ந்த படிப்பில் ஆர்வமாக உள்ளீர்களா?</>:
                    <>   1.3திறன் சார்ந்த படிப்பில் ஆர்வமாக உள்ளீர்களா</>}
                  </FormLabel>
                <RadioGroup
                    aria-labelledby="higher-studies-radio-group-label"
                    {...field}
                    name="skill_based"
                    onChange={(e) => {
                        field.onChange(e); // Update the field value
                        console.log(e.target.value); 
                        if(Number(e.target.value)!==2){
                           setValue('current_work', null, {
                                    shouldValidate: false,
                                });

                        }
                        
                        if(Number(e.target.value)===2){
                            setValue('skill_based_course_id', null, {
                                     shouldValidate: false,
                                 });
 
                         }
                    }}
                >
                    {higherStudyOption.map(option => (
                        <FormControlLabel 
                            key={option.id} 
                            value={option.id} 
                            control={<Radio />} 
                            label={option.name} 
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        )}
        rules={{ required: "This question is required" }}
    />
    {errors?.skill_based?.message ? (
    <Typography color={theme.palette.error.main} fontSize={12}>
        <>{errors?.skill_based?.message}</>
    </Typography>
) : null}</>:<>
{Number(appliedCollegeWatcher)===2&&
    <Controller
        control={control}
        name="skill_based"
        defaultValue={type==="Edit"?editDatas?.answers?.skill_based:null}
        render={({ field }) => (
            <FormControl component="fieldset">
                <FormLabel component="legend">
                    {standardWatcher === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20"?
                    <>  1.2திறன் சார்ந்த படிப்பில் ஆர்வமாக உள்ளீர்களா?</>:
                    <>   1.3திறன் சார்ந்த படிப்பில் ஆர்வமாக உள்ளீர்களா?</>}
                  </FormLabel>
                <RadioGroup
                    aria-labelledby="higher-studies-radio-group-label"
                    {...field}
                    name="skill_based"
                    onChange={(e) => {
                        field.onChange(e); // Update the field value
                        console.log(e.target.value); 
                        if(Number(e.target.value)!==2){
                           setValue('current_work', null, {
                                    shouldValidate: false,
                                });

                        }
                        
                        if(Number(e.target.value)===2){
                            setValue('skill_based_course_id', null, {
                                     shouldValidate: false,
                                 });
 
                         }
                    }}
                >
                    {higherStudyOption.map(option => (
                        <FormControlLabel 
                            key={option.id} 
                            value={option.id} 
                            control={<Radio />} 
                            label={option.name} 
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        )}
        rules={{ required: "This question is required" }}
    />}
    {errors?.skill_based?.message ? (
    <Typography color={theme.palette.error.main} fontSize={12}>
        <>{errors?.skill_based?.message}</>
    </Typography>
) : null}</>}


</Grid>
{skillBasedCoursesStatus&&(<>
    {Number(skillBasedCoursesStatus)===1?(<>
        <Grid item xs={12} p={2}>

        <InputLabel>திறன் அடிப்படையிலான படிப்புகள்:</InputLabel>
        <Controller
     control={control}
     name={'skill_based_course_id'}
      render={({ field }) => (
        <Select
            options={skillBaseCourseList}
            isSearchable
            placeholder={`திறன் சார்ந்த படிப்பு...`}
            value={
                skillBaseCourseList.find(
                    (option:any) => option.id === field.value
                ) || null
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(selectedOption) => {
                handleSkillBasedCourseChange(selectedOption?.id || '');
                field.onChange(selectedOption?.id || '');
            }}
            onBlur={() => field.onBlur()}
            isClearable
        />
    )}
    rules={{ required: `This Field is Required` }}
    defaultValue={type==="Edit"?editDatas?.answers?.skill_based_course_id:null}
/>
{errors?.skill_based_course_id?.message ? (
    <Typography color={theme.palette.error.main} fontSize={12}>
        <>{errors?.skill_based_course_id?.message}</>
    </Typography>
) : null}
</Grid></>):
(<Grid item xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
    <InputLabel>1.4தற்போது என்ன செய்துகொண்டுள்ளீர்கள்?</InputLabel>
        <FormInput
            name="current_work"
            type="text"
            placeholder={`தற்போதையா வேலை....`}
            control={control}
            className="input-from"
            rules={{
                required: {
                    value: true,
                    message: `This field is required`,
                },
            }}
            inputProps={{
                ...register('current_work', {}),
            }}
            defaultValue={type==="Edit"?editDatas?.answers?.current_work:null}
            errors={errors}
        />
    </Grid>)
}
</>)}



</>)}
</>)
}
<Grid item xs={12} p={2}>
    
    <Controller
        control={control}
        name="career_guidance"
        defaultValue={type==="Edit"?editDatas?.answers?.career_guidance:null}
        render={({ field }) => (
            <FormControl component="fieldset">
                <FormLabel component="legend">2.உயர்கல்விக்கான வழிகாட்டி வகுப்புகள் தேவை படுகிறதா?</FormLabel>
                <RadioGroup
                    aria-labelledby="career_guidance-radio-group-label"
                    {...field}
                    name="career_guidance"
                    onChange={(e) => {
                        field.onChange(e); // Update the field value
                        console.log(e.target.value); // Do something with the value, like logging
                    }}
                >
                    {higherStudyOption.map(option => (
                        <FormControlLabel 
                            key={option.id} 
                            value={option.id} 
                            control={<Radio />} 
                            label={option.name} 
                            disabled={true}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        )}
        rules={{ required: "This question is required" }}
    />
</Grid>



<Grid item xs={12} p={2}>

<InputLabel>3.பெற்றோர் உடல் நலம் குன்றியிருப்பின்:</InputLabel>
<Controller
control={control}
name={'parent_status'}
render={({ field }) => (
<Select
options={healthStatusOption}
isSearchable
isDisabled={true}
placeholder={`பெற்றோர் உடல் நலம்...`}
value={
    healthStatusOption.find(
        (option:any) => option.id === field.value
    ) || null
}
getOptionLabel={(option) => option.name}
getOptionValue={option => String(option.id)}
onChange={(selectedOption) => {
    handleSkillBasedCourseChange(selectedOption?.id || '');
    field.onChange(selectedOption?.id || '');
}}
onBlur={() => field.onBlur()}
isClearable
defaultValue={type==="Edit"?editDatas?.answers?.parent_status:null}
/>
)}
rules={{ required: `This Field is Required` }}
defaultValue={type==="Edit"?editDatas?.answers?.parent_status:null}
/>
{errors?.parent_status?.message ? (
<Typography color={theme.palette.error.main} fontSize={12}>
<>{errors?.parent_status?.message}</>
</Typography>
) : null}
</Grid>
{Number(qusetionTypeWatcher)===2&&(<>
    <Grid item xs={12} p={2}>
    
    <Controller
        control={control}
        name="struggling_subject"
        defaultValue={type==="Edit"?editDatas?.answers?.struggling_subject:null}
        render={({ field }) => (
            <FormControl component="fieldset">
                <FormLabel component="legend">4.குறிப்பிட்ட பாடங்களில் சிரமம் இருக்கிறதா?</FormLabel>
                <RadioGroup
                    aria-labelledby="struggling_subject-radio-group-label"
                    {...field}
                    name="struggling_subject"
                    onChange={(e) => {
                        field.onChange(e); // Update the field value
                        console.log(e.target.value); // Do something with the value, like logging
                    }}
                >
                    {higherStudyOption.map(option => (
                        <FormControlLabel 
                            key={option.id} 
                            value={option.id} 
                            control={<Radio />} 
                            label={option.name} 
                            disabled={true}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        )}
        rules={{ required: "This question is required" }}
    />
</Grid>
{Number(strugglingSubjectWatcher)===1&&(<>
    <Grid item xs={12} sm={8} md={8} lg={8} xl={8} p={2}>
        <InputLabel>எந்த பாடம்?</InputLabel>
            <FormInput
                name="struggling_subject_reson"
                type="text"
                placeholder={`எந்த பாடம்....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `This field is required`,
                    },
                }}
                inputProps={{
                    ...register('struggling_subject_reson', {}),
                }}
                errors={errors}
                defaultValue={type==="Edit"?editDatas?.answers?.struggling_subject_reson:null}
            />
        </Grid>
</>)}</>)}


        {questionList?.map((question: any) => (
                    <Grid item xs={12} key={question?.id} p={2}>
                        <InputLabel>{counter++}.{question?.questions}</InputLabel>
                        {question?.answer_type === 1 && (
                            <Controller
                                control={control}
                                name={`question_${question.id}`}
                            
                                render={({ field }) => (
                                    <FormControl component="fieldset">
                                        <RadioGroup {...field}>
                                            {question.options.map((option: any, index: number) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={option.option}
                                                    control={<Radio />}
                                                    label={option.option}
                                                    disabled={true}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                )}
                                rules={{ required: "This question is required" }}
                            />
                        )}
                        {question?.answer_type === 2 && (
                            <FormInput
                                name={`question_${question.id}`}
                                type="text"
                                placeholder={`question_${question.id}....`}
                                control={control}
                                className="input-from"
                                rules={{
                                    required: {
                                        value: true,
                                        message: "This question is required",
                                    },
                                }}
                                inputProps={{
                                    ...register('address', {}),
                                    
                                }}
                                errors={errors}
                            />
                        )}
                        {question?.answer_type === 3 && (
                            <Controller
                                control={control}
                                name={`question_${question.id}`}
                                
                                render={({ field }) => (
                                    <FormControl fullWidth>
                                        <MuiSelect
                                            {...field}
                                            displayEmpty
                                            disabled={true}
                                            defaultValue=""
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="" disabled>Select an option</MenuItem>
                                            {question.options.map((option: any, index: number) => (
                                                <MenuItem key={index} value={option.option}>
                                                    {option.option}
                                                </MenuItem>
                                            ))}
                                        </MuiSelect>
                                    </FormControl>
                                )}
                                rules={{ required: "This question is required" }}
                            />
                        )}
                        {question?.answer_type === 4 && (
                            <Controller
                                control={control}
                                name={`question_${question.id}`}
                                disabled={true}
                                render={({ field }) => (
                                    <FormControl component="fieldset">
                                        {question.options.map((option: any, index: number) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={field.value?.includes(option.option)}
                                                        onChange={() => {
                                                            const newValue = field.value || [];
                                                            if (newValue.includes(option.option)) {
                                                                field.onChange(newValue.filter((v: any) => v !== option.option));
                                                            } else {
                                                                field.onChange([...newValue, option.option]);
                                                            }
                                                        }}
                                                    />
                                                }
                                                label={option.option}
                                            />
                                        ))}
                                    </FormControl>
                                )}
                                rules={{ required: "This question is required" }}
                            />
                        )}
                        {errors[`question_${question?.id}`] && (
                            <Typography color={theme.palette.error.main} fontSize={12}>
                                {errors[`question_${question.id}`]?.message}
                            </Typography>
                        )}
                    </Grid>
                ))}
</>
   
    </></Grid></>):(<><CircularProgress/></>)}
   

    </>
 
                   
                    <Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
                        <CustomButton
                            type="submit"
                            text={`Save`}
                            className="p-12 secondary-button"
                        />
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};

export default EditSurveyForm;



