import { combineSlices } from '@reduxjs/toolkit';
import authSlice from './auth/_auth.slice';
import studentSlice from './students/_students.slice';
import commonSlice from '../common/Common.slice';
import surveySlice from './survey/_survey.slice';
import schoolSlice from './school/_school.slice';
import questionsSlice from './questions/_questions.slice';

const rootReducer = combineSlices(
	authSlice,
	commonSlice,
	studentSlice,
	surveySlice,
	schoolSlice,
	questionsSlice,
	commonSlice
);

export default rootReducer;
