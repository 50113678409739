import { useRef, useState } from 'react';
import jsPDF from 'jspdf';
import ReactDOM from 'react-dom';
import PrintTable from './PrintTable';
import { DataGrid, GridClasses, GridColDef } from '@mui/x-data-grid';
import CustomButton from './CustomButton';
import { exportToCsv } from '../utils';
import { Box, Grid } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useDemoData } from '@mui/x-data-grid-generator';
import { makeStyles } from '@mui/styles';
import ExcelJS from 'exceljs'; 
const TableComponent = ({
	rows,
	columns,
	type,
	view,
	add,
	loading = false,
	hideFooterPagination,
	className,
	totalRecords,
	handlePagination,
	paginationModel,
	csvData,
	handleCsvClick,
}: {
	rows: any[];
	columns: GridColDef[];
	hideFooterPagination?: any;
	className?: any;
	pagination?: any;
	type?: any;
	view?: any;
	add?: any;
	loading?: boolean;
	totalRecords?: any;
	handlePagination?: any;
	paginationModel?: any;
	csvData?:any;
	handleCsvClick?:any
}) => {
	const useStyles = makeStyles({
		header: {
		  backgroundColor: '#f0f0f0', // Change header color
		},
		cell: {
		  border: '1px solid #e0e0e0', // Change cell border
		},
	  });
	  const classes = useStyles();
	const [uploadOpen, setUploadOpen] = useState(false);
	const [completed, setComplted] = useState(false);
	const orgName = '';
	const generateCsvContent = (data: any, columns: any) => {
		// Extracting CSV headers
		const csvHeaders = columns.map((column: any) => column.title).join(',');

		// Extracting CSV data
		const csvData = data
			.map((row: any) =>
				columns.map((column: any) => row[column.dataIndex]).join(',')
			)
			.join('\n');

		// Creating CSV content
		return `${csvHeaders}\n${csvData}`;
	};
 console.log("csvData",csvData)

	const downloadExcel = () => {
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet('Sheet1');
	

	// while (!completed) {
		console.log("whileloop")
		if(csvData?.length > 0){
			if(type==="student_list"){
				const columnsToIncludeStudents = [
				
					{ header: 'Student EMIS No', key: 'student_emis_no' },
					{ header: 'First Name', key: 'firstName' },
					{ header: 'Last Name', key: 'lastName' },
					{ header: 'Middle Name', key: 'middle_name' },
					{ header: 'Email', key: 'email' },
					{ header: 'Contact', key: 'contact' },
					{ header: 'Date of Birth', key: 'dob' },
					{ header: 'Survey Status', key: 'surveyStatus' },
					{ header: 'Community', key: 'community' },
					{ header: 'Religion', key: 'religion' },
					{ header: 'Father Name', key: 'father_name' },
					{ header: 'Mother Name', key: 'mother_name' },
					{ header: 'Father Phone', key: 'father_phone' },
					{ header: 'Mother Phone', key: 'mother_phone' },
					{ header: 'Guardian Name', key: 'guardian_name' },
					{ header: 'Guardian Number', key: 'guardian_number' },
					{ header: 'Address', key: 'address' },
					{ header: 'Address 2', key: 'address2' }
				  ];
					  // Adding column headers
				worksheet.columns = columnsToIncludeStudents.map(col => ({
					header: col.header,
					key: col.key,
					width: 15
				  }));
				
				  // Adding data rows
				  csvData.forEach((row:any, rowIndex:any) => {
					const rowData:any = {};
					columnsToIncludeStudents.forEach(col => {
					  rowData[col.key] = row[col.key];
					});
					worksheet.addRow(rowData);
				  });
				
				  worksheet.getRow(1).font = { bold: true };
				  worksheet.eachRow({ includeEmpty: false }, row => {
					row.border = {
					  top: { style: 'thin' },
					  left: { style: 'thin' },
					  bottom: { style: 'thin' },
					  right: { style: 'thin' }
					};
				  });
				
				  workbook.xlsx.writeBuffer().then(buffer => {
					const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = 'students.xlsx';
					a.click();
					window.URL.revokeObjectURL(url);
				  });
				  setComplted(true)
			  }
			  if(type==="school_List"){
				const columnsToIncludeStudents = [
					{ header: 'ID', key: 'id' },
					{ header: 'Name', key: 'name' },
					{ header: 'Street', key: 'street' },
					{ header: 'Address', key: 'address' },
					{ header: 'Pass Percentage 10th', key: 'pass_percentage_10' },
					{ header: 'Pass Percentage 12th', key: 'pass_percentage_12' },
					{ header: 'Fail Percentage 10th', key: 'fail_percentage_10' },
					{ header: 'Fail Percentage 12th', key: 'fail_percentage_12' },
					{ header: 'Survey Completed Student Count', key: 'suvey_completed_student_count' },
					{ header: 'Survey Not Completed Student Count', key: 'suvey_not_completed_student_count' },
					{ header: 'Head Master Name', key: 'head_master_name' },
					{ header: 'Head Master Number', key: 'hm_number' },
				
					{ header: 'Student Count', key: 'student_count' },
					{ header: 'Survey Count 10th', key: 'survey_count_10' },
					{ header: 'Survey Count 12th', key: 'survey_count_12' },
					{ header: 'Fail Count', key: 'fail_count' },
					{ header: 'Pass Count 10th', key: 'pass_count_10' },
					{ header: 'Pass Count 12th', key: 'pass_count_12' },
					{ header: 'Fail Count 10th', key: 'fail_count_10' },
					{ header: 'Fail Count 12th', key: 'fail_count_12' },
					{ header: 'Higher Studies Join 12th', key: 'higher_studies_join_12' },
					{ header: 'No Studies 12th', key: 'no_studies_12' },
					{ header: 'Higher Studies Applied 12th', key: 'higher_studies_applied_12' },
					{ header: 'Skill Based Studies 12th', key: 'skill_based_studies_12' },
					{ header: 'Higher Studies Join 10th', key: 'higher_studies_join_10' },
					{ header: 'No Studies 10th', key: 'no_studies_10' },
					{ header: 'Higher Studies Applied 10th', key: 'higher_studies_applied_10' },
					{ header: 'Skill Based Studies 10th', key: 'skill_based_studies_10' },
				  ];
				  
					  // Adding column headers
				worksheet.columns = columnsToIncludeStudents.map(col => ({
					header: col.header,
					key: col.key,
					width: 15
				  }));
				
				  // Adding data rows
				  csvData.forEach((row:any, rowIndex:any) => {
					const rowData:any = {};
					columnsToIncludeStudents.forEach(col => {
					  rowData[col.key] = row[col.key];
					});
					worksheet.addRow(rowData);
				  });
				
				  worksheet.getRow(1).font = { bold: true };
				  worksheet.eachRow({ includeEmpty: false }, row => {
					row.border = {
					  top: { style: 'thin' },
					  left: { style: 'thin' },
					  bottom: { style: 'thin' },
					  right: { style: 'thin' }
					};
				  });
				
				  workbook.xlsx.writeBuffer().then(buffer => {
					const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = 'students.xlsx';
					a.click();
					window.URL.revokeObjectURL(url);
				  });
			  }
			  if(type==="question"){
				const columnsToIncludeStudents = [
					{ header: 'ID', key: 'id' },
					{ header: 'Qusetions', key: 'question' },
					{ header: 'Question Type', key: 'answer_type' },
					
					
				  ];
				  
					  // Adding column headers
				worksheet.columns = columnsToIncludeStudents.map(col => ({
					header: col.header,
					key: col.key,
					width: 15
				  }));
				
				  // Adding data rows
				  csvData.forEach((row:any, rowIndex:any) => {
					const rowData:any = {};
					columnsToIncludeStudents.forEach(col => {
					  rowData[col.key] = row[col.key];
					});
					worksheet.addRow(rowData);
				  });
				
				  worksheet.getRow(1).font = { bold: true };
				  worksheet.eachRow({ includeEmpty: false }, row => {
					row.border = {
					  top: { style: 'thin' },
					  left: { style: 'thin' },
					  bottom: { style: 'thin' },
					  right: { style: 'thin' }
					};
				  });
				
				  workbook.xlsx.writeBuffer().then(buffer => {
					const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					const url = window.URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = 'students.xlsx';
					a.click();
					window.URL.revokeObjectURL(url);
				  });
		
			  }
		} 
		
	//   }
	  
	
	  };
	const handlePrint = () => {
		const selectedColumn = columns
			.filter(
				(column: any) =>
					column.headerName !== 'Options' && column.headerName !== 'Action'
			)
			.map((column: any) => ({
				title: column.headerName,
				dataIndex: column.field,
				key: column.key,
			}));
		console.log('selectedColumn', selectedColumn, columns);
		const newWindow = window.open('', '_blank');
		if (newWindow) {
			newWindow.document.write('<html><head><title>Print</title>');
			newWindow.document.write(
				"<link rel='stylesheet'  href='../../styles/modules/doctor.less'>"
			);
			newWindow.document.write('</head><body>');
			newWindow.document.write("<div id='print-content'>");
			ReactDOM.render(
				<PrintTable
					dataSource={rows}
					columns={selectedColumn}
					orgName={orgName}
				/>,
				newWindow.document.getElementById('print-content')
			);

			newWindow.document.write('</div>');
			newWindow.document.write('</body></html>');
			newWindow.document.close();
			newWindow.onload = () => {
				newWindow.print();
			};
		} else {
			console.error('Failed to open new window for printing.');
		}
	};
	const handlePdf = () => {
		const unit = 'pt';
		const size = 'A4';
		const orientation = 'landscape';
		const marginLeft = 40;
		const doc: any = new jsPDF(orientation, unit, size);
		doc.setFontSize(15);
		const title = 'Details';
		const defaultColumnWidth = 60;
		let columnsToRemove = [
			'createdAt',
			'updatedAt',
			'familyMember',
			'donationTime',
			'permissions',
			'Doner_Id',
			'donatedBy_id',
			'additionalInstructions',
			'additionalInstructionsMaterial',
			'additionalInstructionsGeneral',
			'recurringDonation',
			'donerBank',
			'Doner_serial_no',
			'Doner_address',
			'paymentNow',
			'user_id',
			'role_id',
			'password',
			'Action',
		];
		const cleanedDataSource = rows.map((obj: any) => {
			Object.values(obj).forEach((key) => {
				//  return key;
			});
			Object.keys(obj).forEach((key) => {
				if (
					columnsToRemove.includes(key) ||
					key.toLowerCase().startsWith('id')
				) {
					delete obj[key];
				}
			});
			return obj;
		});
		const dataSourceKeys = Object.keys(cleanedDataSource[0]);
		const headers = [dataSourceKeys.map((key) => key)];
		const dataValues = cleanedDataSource.map((row: any) => {
			const rowData: any = [];
			headers[0].forEach((header) => {
				rowData.push(row[header]);
			});
			return rowData;
		});
		const totalColumns = headers?.length;
		const columnStyles: any = {};
		for (let i = 0; i < totalColumns; i++) {
			columnStyles[i] = { columnWidth: defaultColumnWidth };
		}
		const content: any = {
			startY: 50,
			head: headers,
			body: dataValues,
			columnStyles: columnStyles,
		};
		doc.text(title, marginLeft, 40);
		doc.autoTable(content);
		doc.save('sivaPakkiyam.pdf');
	};
	const handleUpload = () => {
		setUploadOpen(true);
	};
	const customClasses: Partial<GridClasses> = {
		root: 'custom-root-class',
		columnHeader: 'custom-column-header-class',
	};
	const { data } = useDemoData({
		dataSet: 'Commodity',
		rowLength: 500,
		maxColumns: 6,
	});
	const columnsWithStyles = columns.map((col:any) => ({
		...col,
		headerClassName: classes.header,
		cellClassName: classes.cell,
	  }));
	return (
		<>
			<Grid container>
				<Grid item xs={12} alignItems={'center'}>
					{type !== 'dashboard' && (
						<>
						<Grid
							xs={12}
							display={'flex'}
							justifyContent={'space-between'}
							alignItems={'center'}
							pb={5}
						>
					{csvData?.length > 0 &&(	<><Grid xs={12} justifyContent={'center'} display={'flex'}>
								<CustomButton
									text={'PRINT'}
									onClick={() => {
										handlePrint();
									}}
									// disabled={!view}
									className="px-5 py-3 mx-3 color-gray"
								/>
								{/* <CustomButton
									text={'PDF'}
									className="px-5 py-3 mx-3"
									startIcon={PictureAsPdfIcon}
									color={'warning'}
									onClick={() => {
										handlePdf();
									}}
									// disabled={!view}
								/> */}
								<CustomButton
									text={'CSV'}
								
									onClick={() => {
										downloadExcel();
									}}
									// disabled={!view}
									className="px-5 py-3 mx-3"
									color={'warning'}
								/>
								{type === 'donor' && (
									<CustomButton
										text={'UPLOAD'}
										className="px-5 py-3 mx-3"
										disabled={!add}
										color={'warning'}
										onClick={handleUpload}
									/>
								)}
							</Grid></>)}
							
						
						</Grid>
						</>
					)}
				</Grid>
			</Grid>
			<Box
				sx={{
					width: '100%',
					overflowX: 'auto',
				}}
			>
				<DataGrid
					rows={rows}
					columns={columnsWithStyles}
					rowCount={totalRecords}
					paginationMode="server"
					onPaginationModelChange={(e) => {
						handlePagination(e);
					}}
					hideFooter={hideFooterPagination}
					paginationModel={paginationModel}
					className={`${className} table-class`}
					classes={customClasses}
					sx={{
						'& .MuiDataGrid-cell': {
						  border: '1px solid #e0e0e0', // Add border to cells
						},
					  }}
				
				/>
			</Box>
		</>
	);
};

export default TableComponent;
