import { CircularProgress, Grid, InputLabel, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import theme from '../../../common/theme';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { answerType } from '../../../common/utils';
import CloseIcon from '@mui/icons-material/Close';
import { passFailStatus, yearsList } from '../../../common/constants/FormItems';
import { selectStandardList, selectStandardListLoading, standardListInProgress } from '../../../store/students/_students.slice';
import { useDispatch, useSelector } from 'react-redux';
import { savequestionsInProgress, updatequestionsInProgress } from '../../../store/questions/_questions.slice';
import { useTranslation } from 'react-i18next';

function QuestionsForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const {t}=useTranslation()
    const dispatch = useDispatch();
    const standardDetails: any = useSelector(selectStandardList);
    const locationData = location?.state?.data?.full_details;
	const standardLoading: any = useSelector(selectStandardListLoading);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue,
    } = useForm({
        defaultValues: {
            standard_id: locationData?.standard_id || '',
            year: locationData?.year||"",
            question: locationData?.questions || '',
            answer_type: locationData?.answer_type?.toString() || '',
            question_type:locationData?.questions_type?.toString()||"",
            options: locationData?.options.map((values: any) => ({
                option: values.option || '',
            })),
        },
    });

  console.log("locationData?.questions_type",locationData)

    const standardList: any = standardDetails?.data || [];
	useEffect(()=>{
		dispatch(standardListInProgress());
	},[])

    const handleStandardManager = (value: any) => {
        setValue('standard_id', value, {
            shouldValidate: true,
        });
    };

    const handleYearChange = (value: any) => {
        setValue('year', value, {
            shouldValidate: true,
        });
    };

    const handlePassFailChange = (value: any) => {
        setValue('question_type', value, {
            shouldValidate: true,
        });
    };

    const handleReportingManager = (value: any) => {
        setValue('answer_type', value, {
            shouldValidate: true,
        });
    };

    const onSubmit = (data: any) => {
        if(location?.state?.type === 'edit'){
			const handleSuccess=()=>{
				navigate(APP_ROUTES.QUESTIONS)
			}
			const payload:any={
				data:data,
				id:locationData?.id,
				handleSuccess
			}

			dispatch(updatequestionsInProgress(payload))
		}
		else{
			const handleSuccess=()=>{
				navigate(APP_ROUTES.QUESTIONS)
			}
			const payload:any={
				...data,
				
				handleSuccess
			}

			dispatch(savequestionsInProgress(payload))
		}
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'options',
    });

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <>
                    <Grid container>
                        <Grid container xs={12} p={2} item>
                            <Grid
                                item
                                xs={12}
                                display={'flex'}
                                justifyContent={'space-between'}
                                container
                            >
                                <Grid item>
                                    <Typography
                                        fontSize={20}
                                        fontWeight={600}
                                        color={theme.palette.common.black}
                                    >
                                        {location?.state?.type === 'edit' ? `${t('Edit_Questions')}` :  `${t('Add_Questions')}`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <CustomButton
                                        type="button"
                                        text={t('GO_BACK')}
                                        startIcon={SubdirectoryArrowRightIcon}
                                        color={'primary'}
                                        className="p-8 secondary-button"
                                        onClick={() => {
                                            navigate(APP_ROUTES.QUESTIONS);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
							{standardLoading?<CircularProgress/>: 
							<> <InputLabel>{t('Standard')}</InputLabel>
							<Controller
								control={control}
								name={'standard_id'}
								render={({ field }) => (
									<Select
										options={standardList}
										isSearchable
										placeholder={`${t('STANDARD')}...`}
										value={
											standardList.find(
												(option: any) => option.id === watch('standard_id')
											) || null
										}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										onChange={(selectedOption) => {
											handleStandardManager(selectedOption?.id || '');
											field.onChange(selectedOption?.id || '');
										}}
										onBlur={() => field.onBlur()}
										isClearable
										defaultValue={location.state?.data?.role_id}
									/>
								)}
								rules={{ required: `${t('STANDARD_IS_REQUIRED')}` }}
							/>
							{errors?.standard_id?.message ? (
								<Typography color={theme.palette.error.main} fontSize={12}>
									{(errors?.standard_id as any)?.message}
								</Typography>
							) : null}</>}
                               
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
							{standardLoading?<CircularProgress/>: 
							<>
							<InputLabel>{t('Year')}:</InputLabel>
                                <Controller
                                    control={control}
                                    name={'year'}
                                    render={({ field }) => (
                                        <Select
                                            options={yearsList}
                                            isSearchable
                                            placeholder={`${t('Year')}...`}
                                            value={
                                                yearsList.find(
                                                    (option: any) => option.id === parseInt(watch('year'))
                                                ) || null
                                            }
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            onChange={(selectedOption) => {
                                                handleYearChange(selectedOption?.id || '');
                                                field.onChange(selectedOption?.id || '');
                                            }}
                                            onBlur={() => field.onBlur()}
                                            isClearable
                                            defaultValue={locationData?.year||""}
                                        />
                                    )}
                                    rules={{ required: `${t('Year_is_Required')}` }}
                                />
                                {errors?.year?.message ? (
                                    <Typography color={theme.palette.error.main} fontSize={12}>
                                        {(errors?.year as any)?.message}
                                    </Typography>
                                ) : null}
								</>
								}
                               
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                                <InputLabel>{t('Questions')}:</InputLabel>
                                <FormInput
                                    name="question"
                                    type="text"
                                    placeholder={`${t("Questions")}...`}
                                    control={control}
                                    className="input-from"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: `${t("Year_is_Required")}`,
                                        },
                                    }}
                                    inputProps={{
                                        ...register('question', {}),
                                    }}
                                    errors={errors}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                                <InputLabel>{t("Pass")}/{t("Fail")}:</InputLabel>
                                <Controller
                                    control={control}
                                    name={'question_type'}
                                    render={({ field }) => (
                                        <Select
                                            options={passFailStatus}
                                            isSearchable
                                            placeholder={`${t("Pass")}/${t("Fail")}...`}
                                            value={
                                                passFailStatus.find(
                                                    (option: any) => option.id === parseInt(watch('question_type'))
                                                ) || null
                                            }
                                            getOptionLabel={(option) => `${option.name}`}
                                            getOptionValue={(option) => option.id}
                                            onChange={(selectedOption) => {
                                                handlePassFailChange(selectedOption?.id || '');
                                                field.onChange(selectedOption?.id || '');
                                            }}
                                            onBlur={() => field.onBlur()}
                                            isClearable
                                            defaultValue={location.state?.data?.role_id}
                                        />
                                    )}
                                    rules={{ required: `${t('This_Field_Is_Required')}` }}
                                    defaultValue={locationData?.questions_type}
                                />
                                {errors?.question_type?.message ? (
                                    <Typography color={theme.palette.error.main} fontSize={12}>
                                        {(errors?.question_type as any)?.message}
                                    </Typography>
                                ) : null}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
                                <InputLabel>{t('Answer_Type')}:</InputLabel>
                                <Controller
                                    control={control}
                                    name={'answer_type'}
                                    render={({ field }) => (
                                        <Select
                                            options={answerType}
                                            isSearchable
                                            placeholder={`${t('Answer_Type')}`}
                                            value={
                                                answerType?.find(
                                                    (option: any) => option?.value === parseInt(watch('answer_type'))
                                                ) || null
                                            }
                                            getOptionLabel={(option: any) => `${option.label}`}
                                            getOptionValue={(option: any) => option.value}
                                            onChange={(selectedOption: any) => {
                                                handleReportingManager(selectedOption?.value || '');
                                                field.onChange(selectedOption?.value || '');
                                            }}
                                            onBlur={() => field.onBlur()}
                                            isClearable
                                        />
                                    )}
                                    rules={{ required: `${t('Answer_Type_Is_Required')}` }}
                                />
                                {errors?.answer_type?.message ? (
                                    <Typography color={theme.palette.error.main} fontSize={12}>
                                        {(errors?.answer_type as any)?.message}
                                    </Typography>
                                ) : null}
                            </Grid>

                            {fields.map((field, index) => {
                                return (
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2} key={field.id}>
                                        <div>
                                            <InputLabel>{t("Option")} {index + 1}:</InputLabel>
                                            <FormInput
                                                name={`options.${index}.option`}
                                                type="text"
                                                placeholder={`${t("Option")}...`}
                                                control={control}
                                                className="input-from"
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: `${t('This_Field_Is_Required')}`,
                                                    },
                                                }}
                                                inputProps={{
                                                    ...register(`options.${index}.option`, {}),
                                                }}
                                                errors={errors}
                                            />
                                            <CustomButton
                                                variant="text"
                                                color="warning"
                                                type="button"
                                                startIcon={CloseIcon}
                                                onClick={() => remove(index)}
                                                text={t('Remove')}
                                            />
                                        </div>
                                    </Grid>
                                );
                            })}

                            <Grid item xs={12} p={2}>
                                <CustomButton
                                    className="p-12 secondary-button"
                                    type="button"
                                    onClick={() => append({ option: '' })}
                                    text={t('Add_Options')}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
                            <CustomButton
                                type="submit"
                                text={t('SUBMIT')}
                                className="p-12 secondary-button"
                            />
                        </Grid>
                    </Grid>
                </>
            </form>
        </div>
    );
}

export default QuestionsForm;
