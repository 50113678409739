import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import logo from '../../../src/common/icons/municipality-logo.png';
import Person2Icon from '../../common/icons/profile-png-5.png';
import Separator from '../../common/ui/Separator';
import { APP_ROUTES } from '../../common/constants/Routes';

interface TopHeaderProps {
  setDrawerOpen: (open: boolean) => void;
  setNotificationDrawer?: any;
  contentHeight?: number;
}

const TopHeader: React.FC<TopHeaderProps> = ({
  setDrawerOpen,
  setNotificationDrawer = () => {},
  contentHeight = 0,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  console.log("screenWidth",screenWidth)
  const theme = useTheme();
  const role_id = localStorage.getItem('role_id');
  const name = localStorage.getItem('name');
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogOff = () => {
    localStorage.removeItem('user');
    navigate(APP_ROUTES.DASHBOARD);
    window.location.reload();
    localStorage.removeItem('accessToken')
		localStorage.removeItem('name')
		localStorage.removeItem('email')
  };

  return (
    <Grid container className="dashBoard-header-menu py-3">
      {screenWidth < 900 && (
        <Grid
          item
          className="mr-10 ml-15 menu-bar"
          display={'flex'}
          alignItems={'center'}
        >
          <IconButton
            aria-label="open drawer"
            onClick={() => setDrawerOpen(true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item flex={1} className="mr-20">
        <Grid
          item
          justifyContent={'space-between'}
          className="header-profile"
          alignItems={'center'}
        >
          <Grid className="logo-icon mt-5 my-4">
            <img src={logo} alt="logo" className="img-logo" />
          </Grid>
          <Separator
            color={theme.palette.primary.main}
            className="profile-res1"
          />
          <Grid
            item
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid className="profile-img mt-5 mr-20">
              <img src={Person2Icon} alt="logo" className="img-logo" />
            </Grid>
            <Grid my={1}>
              <Typography
                color={theme.palette.common.black}
                display={'flex'}
                fontSize={14}
                whiteSpace={'nowrap'}
              >
                {name}
                <Typography
                  color={theme.palette.common.black}
                  fontStyle={'italic'}
                  fontSize={14}
                >
                  (
                  {role_id === '2'
                    ? 'Candidate'
                    : role_id === '3'
                    ? 'people'
                    : 'Admin'}
                  )
                </Typography>
              </Typography>
              <Link to={'#'} onClick={handleLogOff}>
                <Typography
                  display={'flex'}
                  alignItems={'center'}
                  color={theme.palette.grey[600]}
                  fontSize={14}
                >
                  log out &nbsp; <PowerSettingsNewIcon className="f-15 mt-2" />
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Separator color={theme.palette.primary.main} />
      </Grid>
    </Grid>
  );
};

export default TopHeader;
