import { Grid, Typography, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import LoginForm from './LoginForm';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Login.scss';
import corousel1 from '../../common/icons/image_773-removebg-corousel2.png';
import corousel2 from '../../common/icons/image corousel1.png';
import person1 from '../../common/icons/giphy (1).gif';
import { useDispatch, useSelector } from 'react-redux';

const Login = () => {
	const dispatch = useDispatch();

	const settings = {
		dots: false,
		infinite: true,
		speed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true, // Enable autoplay
		autoplaySpeed: 200, // Set autoplay speed in milliseconds
		nextArrow: <></>,
	};
	const theme = useTheme();
	console.log('DSF');
	return (
		<Grid container justifyContent="center" className="login">
			<Grid item xs={12} sm={12} md={12} lg={8} xl={8} className="login-image">
				<Slider {...settings}>
					
						<div className="person-image-head">
							<img src={corousel1} className="person-image" />
						</div>
						<div className="person-image-head">
							<img src={corousel2} className="person-image" />
						</div>
					
				</Slider>
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={4}
				xl={4}
				justifyContent="center"
				display={'flex'}
				alignItems={'center'}
				className="login-form-main"
			>
				<LoginForm logo={person1} />
			</Grid>
		</Grid>
	);
};
export default Login;
