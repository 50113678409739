import { delay, put, call, takeLatest } from 'redux-saga/effects';



import { AxiosResponse } from 'axios';
import { csvquestionsListInError, csvquestionsListInProgress, csvquestionsListInSuccess, deletequestionsInError, deletequestionsInProgress, deletequestionsInSuccess, questionsDetailsInError, questionsDetailsInProgress, questionsDetailsInSuccess, savequestionsInError, savequestionsInProgress, savequestionsInSuccess, updatequestionsInError, updatequestionsInProgress, updatequestionsInSuccess } from './_questions.slice';
import { addQuestionsEffect, deleteQuestionsEffect, getCsvQuestionsEffect, getQuestionsEffect, updateQuestionsEffect } from './_questions.service';



function* saveQuestionsWatcher({
    payload,
}: ReturnType<typeof savequestionsInProgress>) {
    const { navigate, ...requestPayload }: any = payload;
    try {
        console.log("sagaPaylod",payload)
        const { data }: AxiosResponse<any> = yield call(addQuestionsEffect, payload);
        yield put(savequestionsInSuccess(data));
        if (payload.handleSuccess) {
            payload.handleSuccess();	
        }
    } catch (e: any) {
        yield put(savequestionsInError(e.message));
    }
}
function* getQuestionWatcher({
	payload
}: any) {
	try {
		console.log("QusetionsagaCalled",payload)
		yield delay(3000);
		const { data } = yield call(getQuestionsEffect, payload);
		console.log('data', data);
		yield put(
			questionsDetailsInSuccess({
				data: data,
				message:"Survey Details Fetched Successfully"
			})
		);
	} catch (error) {
		yield put(
			questionsDetailsInError({
				message: 'error',
			})
		);
	}
}

function* updateQuestionsWatcher({
    payload,
}: ReturnType<typeof updatequestionsInProgress>) {
    const { navigate, ...requestPayload }: any = payload;
    try {
        console.log("sagaPaylod",payload)
        const { data }: AxiosResponse<any> = yield call(updateQuestionsEffect, payload);
        yield put(updatequestionsInSuccess(data));
        if (payload.handleSuccess) {
            payload.handleSuccess();	
        }
    } catch (e: any) {
        yield put(updatequestionsInError(e.message));
    }
}
function* deleteQuestionWatcher({
    payload,
}: ReturnType<typeof deletequestionsInProgress>) {
    const { navigate, ...requestPayload }: any = payload;
    try {
        console.log("sagaPaylod",payload)
        const { data }: AxiosResponse<any> = yield call(deleteQuestionsEffect, payload);
        yield put(deletequestionsInSuccess(data));
        if (payload.handleSuccess) {
            payload.handleSuccess();	
        }
    } catch (e: any) {
        yield put(deletequestionsInError(e.message));
    }
}
function* getCsvQuestionWatcher({
	payload
}: any) {
	try {
		console.log("QusetionsagaCalled",payload)
		yield delay(3000);
		const { data } = yield call(getCsvQuestionsEffect, payload);
		console.log('data', data);
		yield put(
			csvquestionsListInSuccess({
				data: data,
				message:"Survey Details Fetched Successfully"
			})
		);
	} catch (error) {
		yield put(
			csvquestionsListInError({
				message: 'error',
			})
		);
	}
}
export default function* questionsSaga() {
	yield takeLatest(savequestionsInProgress.type, saveQuestionsWatcher);
    yield takeLatest(questionsDetailsInProgress.type, getQuestionWatcher);
    yield takeLatest(csvquestionsListInProgress.type, getCsvQuestionWatcher);

	yield takeLatest(updatequestionsInProgress.type, updateQuestionsWatcher);
	yield takeLatest(deletequestionsInProgress.type, deleteQuestionWatcher);
	

}
