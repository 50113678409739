/* eslint-disable no-plusplus */
/* eslint-disable no-useless-escape */
/* eslint-disable default-param-last */
/* eslint-disable global-require */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

export const convertToBase64 = (file: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});
export const exportToCsv = (csvData: string, fileName: string) => {
	const blob = new Blob([csvData], { type: 'text/csv' });
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = fileName;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	URL.revokeObjectURL(url);
};
export interface AuthUser {
	mobile: string;
	email: string;
}

export const stripBase64Prefix = (base64: string) => base64.split(',')[1];
export const appendBase64PrefixForImage = (base64: string) =>
	`data:image/png/jpg/jpeg;base64,${base64}`;

export const candidateId = '8e56b028-bf1d-4b91-8688-ed20c9ac815d'; // KRN
// export const candidateId = '0856687a-79de-4fe9-80c6-85fc5aa2dd0b'; //DURAI

export const answerType: any = [
	{ label: 'Radio Button', value: 1 },
	{ label: 'Text Box', value: 2 },
	{ label: 'Select Box', value: 3 },
	{ label: 'Check Box', value: 4 },
];
export const generateYears = (startYear: number, endYear: number): number[] => {
	const years = [];
	for (let year = startYear; year <= endYear; year++) {
		years.push(year);
	}
	return years;
};
