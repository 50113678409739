
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_TYPE } from '../../models/CommonModels';
import { RootState, useAppSelector } from '../store';
import { SchoolModel, SurveyModel } from '../../models/StudentModel';

interface schoolDetailsTypes extends INITIAL_STATE_TYPE {
	data: null | SurveyModel;
}

interface schoolListTypes extends INITIAL_STATE_TYPE {
	data: SchoolModel[];
}

interface InitialStateTypes {
	saveSchool: INITIAL_STATE_TYPE;
	schoolList: schoolListTypes;
	schoolDetails:any;
    updateSchool:any;
    deleteSchool:any;
	schoolTypes:any;
	csvSchoolList:any


	
	
}

const initialState: InitialStateTypes = {


	schoolList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},

	saveSchool: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	schoolDetails: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: null,
	},
    updateSchool: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	deleteSchool: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	schoolTypes: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	csvSchoolList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: null,
	},

};

const schoolSlice = createSlice({
	name: 'schools',
	initialState,
	reducers: {
	
		
		schoolListInProgress: (state) => {
			state.schoolList.data = [];
			state.schoolList.loading = true;
			state.schoolList.success = false;
			state.schoolList.error = false;
			state.schoolList.message = null;
		},
		schoolListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: SchoolModel[]; message: string }>
		) => {
			state.schoolList.data = payload.data;
			state.schoolList.loading = false;
			state.schoolList.success = true;
			state.schoolList.message = payload.message;
		},
		schoolListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.schoolList.data = [];
			state.schoolList.loading = false;
			state.schoolList.error = true;
			state.schoolList.message = payload.message;
		},
			
		csvSchoolListInProgress: (state) => {
			state.csvSchoolList.data = [];
			state.csvSchoolList.loading = true;
			state.csvSchoolList.success = false;
			state.csvSchoolList.error = false;
			state.csvSchoolList.message = null;
		},
		csvSchoolListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: SchoolModel[]; message: string }>
		) => {
			state.csvSchoolList.data = payload.data;
			state.csvSchoolList.loading = false;
			state.csvSchoolList.success = true;
			state.csvSchoolList.message = payload.message;
		},
		csvSchoolListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.csvSchoolList.data = [];
			state.csvSchoolList.loading = false;
			state.csvSchoolList.error = true;
			state.csvSchoolList.message = payload.message;
		},

	

		saveSchoolInProgress: (state, {}: PayloadAction<SchoolModel>) => {
			state.saveSchool.loading = true;
			state.saveSchool.success = false;
			state.saveSchool.error = false;
			state.saveSchool.message = null;
		},
		saveSchoolInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.saveSchool.loading = false;
			state.saveSchool.success = true;
			state.saveSchool.message = payload.message;
		},
		saveSchoolInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.saveSchool.loading = false;
			state.saveSchool.error = true;
			state.saveSchool.message = payload.message;
		},
		
		schoolDetailsInProgress: (state, {}: PayloadAction<string>) => {
			state.schoolDetails.loading = true;
			state.schoolDetails.success = false;
			state.schoolDetails.error = false;
			state.schoolDetails.message = null;
		},
		schoolDetailsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data:any; message: string }>
		) => {
			state.schoolDetails.data = payload.data;
			state.schoolDetails.loading = false;
			state.schoolDetails.success = true;
			state.schoolDetails.message = payload.message;
		},
		schoolDetailsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.schoolDetails.data = null;
			state.schoolDetails.loading = false;
			state.schoolDetails.error = true;
			state.schoolDetails.message = payload.message;
		},
        updateSchoolInProgress: (state, {}: PayloadAction<SchoolModel>) => {
			state.updateSchool.loading = true;
			state.updateSchool.success = false;
			state.updateSchool.error = false;
			state.updateSchool.message = null;
		},
		updateSchoolInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.updateSchool.loading = false;
			state.updateSchool.success = true;
			state.updateSchool.message = payload.message;
		},
		updateSchoolInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.updateSchool.loading = false;
			state.updateSchool.error = true;
			state.updateSchool.message = payload.message;
		},
		deleteSchoolInProgress: (state, {}: PayloadAction<SchoolModel>) => {
			state.deleteSchool.loading = true;
			state.deleteSchool.success = false;
			state.deleteSchool.error = false;
			state.deleteSchool.message = null;
		},
		deleteSchoolInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.deleteSchool.loading = false;
			state.deleteSchool.success = true;
			state.deleteSchool.message = payload.message;
		},
		deleteSchoolInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.deleteSchool.loading = false;
			state.deleteSchool.error = true;
			state.deleteSchool.message = payload.message;
		},
		schoolTypeInProgress: (state) => {
			state.schoolTypes.data = [];
			state.schoolTypes.loading = true;
			state.schoolTypes.success = false;
			state.schoolTypes.error = false;
			state.schoolTypes.message = null;
		},
		schoolTypeInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: SchoolModel[]; message: string }>
		) => {
			state.schoolTypes.data = payload.data;
			state.schoolTypes.loading = false;
			state.schoolTypes.success = true;
			state.schoolTypes.message = payload.message;
		},
		schoolTypeError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.schoolTypes.data = [];
			state.schoolTypes.loading = false;
			state.schoolTypes.error = true;
			state.schoolTypes.message = payload.message;
		},

	
	
	},
});

export const {
	schoolListInProgress,
    schoolListInSuccess,
    schoolListInError,
    saveSchoolInProgress,
    saveSchoolInSuccess,
    saveSchoolInError,
	schoolDetailsInProgress,
	schoolDetailsInSuccess,
	schoolDetailsInError,
    updateSchoolInProgress,
    updateSchoolInError,
    updateSchoolInSuccess,
    deleteSchoolInProgress,
    deleteSchoolInError,
    deleteSchoolInSuccess,
	schoolTypeInProgress,
	schoolTypeInSuccess,
	schoolTypeError,
	csvSchoolListInProgress,
	csvSchoolListInSuccess,
	csvSchoolListInError

} = schoolSlice.actions;



export const selectSavestudentLoading = (state: RootState) =>
	state.schools.saveSchool.loading;
export const selectschoolDetailsListLoading = (state: RootState) =>
	state.schools.schoolDetails.loading;
export const selectschoolDetailsList = (state: RootState) =>
	state.schools.schoolDetails.data;
export const schoolDetailsListSuccess = (state: RootState) =>
	state.schools.schoolDetails.success;
export const selectUpdateSchoolLoading = (state: RootState) =>
	state.schools.updateSchool.loading;
	export const selectDeleteSchoolLoading = (state: RootState) =>
	state.schools.deleteSchool.loading;
	export const selectschoolTypeListLoading = (state: RootState) =>
		state.schools.schoolTypes.loading;
	export const selectschoolTypeList = (state: RootState) =>
		state.schools.schoolTypes.data;
	export const schoolTypeListSuccess = (state: RootState) =>
		state.schools.schoolTypes.success;
	export const selectCsvSchoolDetailsListLoading = (state: RootState) =>
		state.schools.csvSchoolList.loading;
	export const selectCsvSchoolDetailsList = (state: RootState) =>
		state.schools.csvSchoolList.data;
	export const schoolCsvSchoolDetailsListSuccess = (state: RootState) =>
		state.schools.csvSchoolList.success;

export default schoolSlice;
