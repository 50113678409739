import { Grid, InputLabel, MenuItem, Select, Typography, TextField, Stack, LinearProgress, CardContent, Card, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { dashboardListInProgress, selectDashboardDetailsList, selectDashboardDetailsListLoading } from '../../store/survey/_survey.slice';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ROUTES } from '../../common/constants/Routes';
import { useTranslation } from 'react-i18next';
import corousel1 from '../../../src/common/icons/noDataFoundTwo.png';

function StreamBasedScreen() {
    const { t } = useTranslation();
    const location=useLocation()
    const year=location?.state?.year
    console.log("location",location)
	const dispatch = useDispatch();
    const cardData=location?.state?.location?.courseJoinCount
    console.log("location",cardData)
    

    const dashboardLoading = useSelector(selectDashboardDetailsListLoading);
	const dashboardDetails = useSelector(selectDashboardDetailsList);
	useEffect(() => {
		const year=location?.state?.year
        if(year){
            dispatch(dashboardListInProgress({ year }as any));
        }
		
	}, [dispatch]);

    const courses :any= [
        { id: "1", name: "Bachelor of Engineering" },
        { id: "2", name: "Arts & Science" },
        { id: "3", name: "Law" },
        { id: "4", name: "Agriculture" },
        { id: "5", name: "Medical" },
        { id: "6", name: "Nursing" },
        { id: "7", name: "Journalism" },
        { id: "8", name: "Others" }
    ];

    const courseColors :any= {
        "Bachelor of Engineering": '#FFECB3',
        "Arts & Science": '#E8EAF6',
        "Law": '#FFEBEE',
        "Agriculture": '#C8E6C9',
        "Medical": '#FFE0B2',
        "Nursing": '#ADD8E6',
        "Journalism": '#F8BBD0',
        "Others": '#E0F7FA'
    };


console.log("dashboardDetails",dashboardDetails)



  return (
    <>
    {dashboardLoading ?(
        <Grid container display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <CircularProgress />
    </Grid>
    )
       
        :(<>
   <Grid container>
            <Typography className='school-wise-details-text' mb={2}>
                {t("hsc_students_stream_based_counts")}
            </Typography>
            {year?(<>
            {console.log("console1")}
                <Grid item xs={12} container display="flex" className="dashboard-grid">
                    {dashboardDetails.courseJoinCount.length > 0 ?(<>
                        {dashboardDetails.courseJoinCount.map((course:any, index:any) => {
                    const courseName = courses.find((c: any) => c.id === course.course_id)?.name || "Others";
                    return (
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2} className="btn" key={index}>
                            <Link to={APP_ROUTES.STUDENT_LIST} state={{ standard_id: course.standard_id, course_id: course.course_id, year: year, student_status: 1 ,type:"streamBased"}}>
                                <Card className='card-grid' style={{ backgroundColor: courseColors[courseName] }}>
                                    <CardContent>
                                        <Stack spacing={2}>
                                            <Grid item xs={12} container display="flex" justifyContent="space-between" alignItems="center">
                                                <Grid>
                                                    <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
                                                         <Stack spacing={1}>
                                                            <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
                                                                {t(courseName.replace(/\s+/g, '_').toLowerCase())}
                                                            </Typography>
                                                            <Typography variant="h6">{course.count}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    );
                })}</>):(<>  <Grid item xs={6} my={5}>
              <div className="no-data-image-div">
                <img src={corousel1} className="no-data-image" />
              </div>
            </Grid></>)}
             
            </Grid></>):(<>
                {console.log("console2")}
                <Grid item xs={12} container display="flex" className="dashboard-grid">
                    {cardData?.length >= 0 ?(<>
                        {cardData?.map((course:any, index:any) => {
                    const courseName = courses.find((c: any) => c.id === course.course_id)?.name || "Others";
                    return (
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2} className="btn" key={index}>
       
                            <Link to={APP_ROUTES.COLLEGE_LIST_SCREEN} state={{ standard_id: course.standard_id, course_id: course.course_id, year: 2024, student_status: 1,school_id:course?.school_id,type:"streamBased"}}>
                                <Card className='card-grid' style={{ backgroundColor: courseColors[courseName] }}>
                                    <CardContent>
                                        <Stack spacing={2}>
                                            <Grid item xs={12} container display="flex" justifyContent="space-between" alignItems="center">
                                                <Grid>
                                                    <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
                                                              <Stack spacing={1}>
                                                            <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
                                                                {t(courseName.replace(/\s+/g, '_').toLowerCase())}
                                                            </Typography>
                                                            <Typography variant="h6">{course.count}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    );
                })}</>):(<>
                <Grid item xs={6} my={5}>
              <div className="no-data-image-div">
                <img src={corousel1} className="no-data-image" />
              </div>
            </Grid></>)}
               
            </Grid></>)}
           
        </Grid>
</>)}
</>
  );
}

export default StreamBasedScreen;
