
import { useTheme } from '@emotion/react';
import React, { useEffect } from 'react';
import { useForm, Controller, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { savestudentInProgress, schoolListInprogress, selectSchoolList, selectStandardList, standardListInProgress, updateStudentInProgress } from '../../../../store/students/_students.slice';
import { FormControl, Grid, InputLabel, Typography } from '@mui/material';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import { StudentModel } from '../../../../models/StudentModel';
import CustomButton from '../../../../common/ui/CustomButton';
import theme from '../../../../common/theme';
import Select from 'react-select';
import { communityList, financialType, genderType, locality, parentalEducation, parentalOccupation, parentalStatus, reportingManagerList, yearsList } from '../../../../common/constants/FormItems';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { APP_ROUTES } from '../../../../common/constants/Routes';
interface FormValues {
    first_name: any | null;
    last_name: any | null;
    middle_name: any | null;
    student_emis_no: any | null;
    school_id: any | null;
    year: number | null;  // Assuming year is a number
    standard_id: String | null;
    gender: String | null;
    email: any | null;
    DOB: Date | null;
    address: any | null;
    religion: any | null;
    community: any | null;
    phone: any | null;
    father_name: any | null;
    mother_name: any | null;
    father_phone: any | null;
    mother_phone: any | null;
    country: any;
    state: any;
    city: any;
    financial_type: number | null;
    parenting_status: number | null;
    parents_occupation: number | null;
    Parents_education: number | null;
    locality_type: number | null;
    school_code: any | null;
    group: any | null;
     address2:String,
     guardian_name:String,
            guardian_number:String,
}

const StudentEditForm = () => {
    const location = useLocation();
    const {register,getValues, watch, trigger, control, handleSubmit, setValue, formState: { errors } } = useForm<FormValues>({
        defaultValues: {
            first_name: location?.state?.data?.firstName || null,
            last_name: location?.state?.data?.lastName || null,
            middle_name: location?.state?.data?.middle_name || null,
            student_emis_no: location?.state?.data?.student_emis_no || null,
            school_id: location?.state?.data?.school_id || null,
            year: location?.state?.data?.year ? Number(location?.state?.data?.year) : null,
            standard_id: location?.state?.data?.standard_id || null,
            gender: location?.state?.data?.gender || null,
            email: location?.state?.data?.email || null,
            DOB: location?.state?.data?.dob || null,
            address: location?.state?.data?.address || null,
            religion: location?.state?.data?.religion || null,
            community: location?.state?.data?.community || null,
            phone: location?.state?.data?.contact || null,
            father_name: location?.state?.data?.father_name || null,
            mother_name: location?.state?.data?.mother_name || null,
            father_phone: location?.state?.data?.father_phone || null,
            mother_phone: location?.state?.data?.mother_phone || null,
            country: 'India',
            state: 'Tamil Nadu',
            city: 'Thirupur',
            financial_type: Number(location?.state?.data?.financial_type) || null,
            parenting_status: Number(location?.state?.data?.parenting_status) || null,
            parents_occupation: Number(location?.state?.data?.parents_occupation) || null,
            Parents_education: Number(location?.state?.data?.Parents_education) || null,
            locality_type: Number(location?.state?.data?.locality_type) || null,
            school_code: location?.state?.data?.school_code || null,
            group: location?.state?.data?.group || null,
            address2:"",
            guardian_name:"",
            guardian_number:"",
        }
    });

  const { t } = useTranslation();
  

  const navigate = useNavigate();

  console.log("location",location.state.data)
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state);
  const schoolDetails: any = useSelector(selectSchoolList)
  const standardDetails: any = useSelector(selectStandardList);
  const standardList: any = standardDetails?.data || [];

  const schoolList: any = schoolDetails?.data || [];
  const userData = user?.getProfile?.data?.data || [];
  const [checked, setChecked] = React.useState(false);

  const handleSchoolChange = (value: any,short_code:any) => {
      console.log("value",value)
      setValue('school_id', value, {
          shouldValidate: true,
      });
      setValue('school_code', short_code, {
          shouldValidate: true,
      });
  
  

};
  const onSubmit = (data:any

  ) => {
      const handleSuccess=()=>{
     
         navigate(APP_ROUTES.STUDENT_LIST)

      }
      data.student_information_id=location?.state?.data?.StudentInformations?.id
      data.id=location?.state?.data?.id
      const payload:any={
...data,
handleSuccess,
navigate
      }
      console.log("payload",payload)
  
      dispatch(updateStudentInProgress(payload))
  };





  const handleReportingManager = (value: any) => {
      setValue('group', value, {
          shouldValidate: true,
      });
  };
  
  const handleGender = (value: any) => {
      setValue('gender', value, {
          shouldValidate: true,
      });
  };


  const mobileNo = watch('phone');
  const fatherMobileNo=watch('father_phone')
  const motherMobileNo=watch('mother_phone')
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {

      const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
      setValue('email', emailValue, { shouldValidate: true });
      trigger('email');
  };
  
  const parseMobileNo = (mobileNo: any) => {
    const allowedFirstDigits = ['6', '7', '8', '9'];
    const numberPattern = /\d+/g;
    const matches: any[] | null = String(mobileNo).match(numberPattern);
    if (matches) {
      const digits = matches.join('');
      if (allowedFirstDigits.includes(digits.charAt(0))) {
        return digits.substring(0, 10);
      }
    }
    return '';
  };
  useEffect(() => {
      
      dispatch(standardListInProgress());

      dispatch(schoolListInprogress())
      
  
  }, [dispatch]);
  useEffect(() => {
    if (mobileNo) {
      setValue('phone', parseMobileNo(mobileNo));
    }
  }, [mobileNo]);
;
  useEffect(() => {
      if (fatherMobileNo) {
        setValue('father_phone', parseMobileNo(fatherMobileNo));
      }
    }, [fatherMobileNo]);
    useEffect(() => {
      if (motherMobileNo) {
        setValue('mother_phone', parseMobileNo(motherMobileNo));
      }
    }, [motherMobileNo]);
    
    const handleFinancialType = (value: any) => {
      setValue('financial_type', value, {
          shouldValidate: true,
      });
  };
  const handleParentalStatus = (value: any) => {
      setValue('parenting_status', value, {
          shouldValidate: true,
      });
  };
  const handleOccupation = (value: any) => {
      setValue('parents_occupation', value, {
          shouldValidate: true,
      });
  };
  const handleEducation = (value: any) => {
      setValue('Parents_education', value, {
          shouldValidate: true,
      });
  };
  const handleLocality = (value: any) => {
      setValue('locality_type', value, {
          shouldValidate: true,
      });
  };
  const handleYearChange = (value: any) => {
      console.log("value",value)
      setValue('year', value, {
          shouldValidate: true,
      });
     
  };
  return (
    <Grid>
         <form onSubmit={handleSubmit(onSubmit)}>
         <Grid
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      className='donation-header'
    >
      <Typography fontSize={20} fontWeight={700}>
       { t("STUDENT_EDIT")}
      </Typography>
      

<><CustomButton
        type='button'
        text={`${t("GO_BACK")}`}
      
        className=' px-10 py-5 my-5'
        fontSize={14}
       
        onClick={() => {
          navigate(-1);
        }}
        color={'secondary'}
      /></>
     
    </Grid>
        <><Grid container>
   
                
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t("FIRSTNAME")}:</InputLabel>
            <FormInput
                name="first_name"
                type="text"
                placeholder={`${t("FIRSTNAME")}....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `${t("PLEASE_ENTER_FIRST_NAME")}`,
                    },
                }}
                inputProps={{
                    ...register('first_name', {}),
                }}
                errors={errors}
            />
        </Grid>{' '}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t("LASTNAME")}:</InputLabel>
            <FormInput
                name="last_name"
                type="text"
                placeholder={`${t("LASTNAME")}....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `${t("PLEASE_ENTER_LAST_NAME")}`,
                    },
                }}
                inputProps={{
                    ...register('last_name', {}),
                }}
                errors={errors}
            />
        </Grid>{' '}	
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t("MIDDLE_NAME")}:</InputLabel>
            <FormInput
                name="middle_name"
                type="text"
                placeholder={`${t("MIDDLE_NAME")}....`}
                control={control}
                className="input-from"
                
                inputProps={{
                    ...register('middle_name', {}),
                }}
                errors={errors}
            />
        </Grid>{' '}		
        
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t("EMIS_NO")}:</InputLabel>
            <FormInput
                name="student_emis_no"
                type="text"
                placeholder={`${t('EMIS_NO')}...`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `${t("PLEASE_ENTER_LAST_EMI")}`,
                        
                    },
                }}
                inputProps={{
                    ...register('student_emis_no', {}),
                }}
                errors={errors}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
      <InputLabel>{t("SCHOOL")}:</InputLabel>
    <Controller
     control={control}
     name={'school_id'}
      render={({ field }) => (
        <Select
            options={schoolList} 
            isSearchable
            placeholder={`${t("SCHOOL")}...`}
            value={
                schoolList.find(
                    (option:any) => option.id === field.value
                ) || null
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(selectedOption) => {
                handleSchoolChange(selectedOption?.id || '',selectedOption.short_code);
                field.onChange(selectedOption?.id || '');
            }}
            onBlur={() => field.onBlur()}
            isClearable
        />
    )}
    rules={{ required: `${t("PLEASE_SELECT_YOUR_SCHOOL_TYPE")}` }}
/>
{errors?.school_id?.message ? (
    <Typography color={theme.palette.error.main} fontSize={12}>
        <>{errors?.school_id?.message}</>
    </Typography>
) : null}
</Grid>
<Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                <InputLabel>{t("Year")}:</InputLabel>
                    <Controller
                        control={control}
                        name={'year'}
                        render={({ field }) => (
                            <Select
                                options={yearsList}
                                isSearchable
                                placeholder={"Select Group"}
                                value={
                                    yearsList.find(
                                        (option: any) => option.id === watch('year')
                                    ) || null
                                }
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(selectedOption) => {
                                    handleYearChange(selectedOption?.id || '');
                                    field.onChange(selectedOption?.id || '');
                                }}
                                onBlur={() => field.onBlur()}
                                isClearable
                                defaultValue={ location?.state?.data?.year||null}
                            />
                        )}
                        rules={{ required: `${t("This_Field_Is_Required")}` }}
                        defaultValue={Number(location?.state?.data?.year)||null}
                    />
                    {errors?.year?.message ? (
                        <Typography color={theme.palette.error.main} fontSize={12}>
                             {errors.year.message}
                        </Typography>
                    ) : null}
                </Grid>
<Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                    <InputLabel>{t("STANDARD")}:</InputLabel>
                    <Controller
                        control={control}
                        name={'standard_id'}
                        render={({ field }) => (
                            <Select
                                options={standardList}
                                isSearchable
                                placeholder={`${t("STANDARD")}...`}
                                value={
                                    standardList.find(
                                        (option: any) => option.id === watch('standard_id')
                                    ) || null
                                }
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(selectedOption) => {
                                    handleReportingManager(selectedOption?.id || '');
                                    field.onChange(selectedOption?.id || '');
                                }}
                                onBlur={() => field.onBlur()}
                                isClearable
                                defaultValue={location?.state?.data?.standard_id||null}
                            />
                        )}
                        rules={{ required: `${t('STANDARD_IS_REQUIRED')}` }}
                        defaultValue={location?.state?.data?.standard_id||null}
                    />
                    {errors?.standard_id?.message ? (
                        <Typography color={theme.palette.error.main} fontSize={12}>
                            {errors?.standard_id?.message}
                        </Typography>
                    ) : null}
                </Grid>
                {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                    <InputLabel>{t('Gender')}:</InputLabel>
                    <Controller
                        control={control}
                        name={'gender'}
                        render={({ field }) => (
                            <Select
                                options={genderType}
                                isSearchable
                                placeholder={`${t('Gender')}...`}
                                value={
                                    genderType.find(
                                        (option: any) => option.id === watch('gender')
                                    ) || null
                                }
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(selectedOption) => {
                                    handleGender(selectedOption?.id || '');
                                    field.onChange(selectedOption?.id || '');
                                }}
                                onBlur={() => field.onBlur()}
                                isClearable
                                defaultValue={location?.state?.data?.gender||null}
                            />
                        )}
                        rules={{ required: `${t("Select_Gender")}`}}
                        defaultValue={location?.state?.data?.gender||null}
                    />
                    {errors?.gender?.message ? (
                        <Typography color={theme.palette.error.main} fontSize={12}>
                            {errors?.gender?.message}
                        </Typography>
                    ) : null}
                </Grid> */}
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                <InputLabel>{t('EMAIL')}:</InputLabel>
        <FormInput
          name='email'
          type='text'
          placeholder={`${t('EMAIL')}...`}
          control={control}
          className='input-from'
          rules={{
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: `${t('INVALID_EMAIL_ADDRESS')}`,
            },
           
        }}
          inputProps={{
            ...register('email', {}),
            onChange: handleEmailChange 
          }}
          errors={errors}
        />
      </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        
            <InputLabel>{t("DATE_OF_BIRTH")}:</InputLabel>
            <FormControl fullWidth required>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                         value={getValues('DOB') ? dayjs(getValues('DOB')) : null}
                         onChange={(date:any) => setValue('DOB', date)}
                    
                        slotProps={{
                            textField: {
                                size: 'small',
                                ...register('DOB', {
                                    // required: `${t('PLEASE_ENTER_DOB')}`,
                                }),
                            },
                        }}
                    />
                </LocalizationProvider>

                {errors?.DOB && (
                    <Typography color={theme.palette.error.main} fontSize={12}>
                        {errors?.DOB?.message}
                    </Typography>
                )}
            </FormControl>
        
        </Grid>
        
        
        {/* <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t("ADDRESS")}:</InputLabel>
            <FormInput
                name="address"
                type="text"
                placeholder={`${t('ADDRESS')}....`}
                control={control}
                className="input-from"
                rules={{
                    required: {
                        value: true,
                        message: `${t('PLEASE_ENTER_A_ADDRESS')}`,
                    },
                }}
                inputProps={{
                    ...register('address', {}),
                }}
                errors={errors}
            />
        </Grid> */}

    
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t('RELIGION')}:</InputLabel>
            <Controller
                control={control}
                name={'religion'}
                render={({ field }) => (
                    <Select
                        options={reportingManagerList}
                        isSearchable
                        placeholder={`${t('RELIGION')}...`}
                        value={
                            reportingManagerList.find(
                                (option:any) => option.id === parseInt(watch('religion'))
                            ) || null
                        }
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        onChange={(selectedOption) => {
                            handleReportingManager(selectedOption?.id || '');
                            field.onChange(selectedOption?.id || '');
                        }}
                        onBlur={() => field.onBlur()}
                        isClearable
                        defaultValue={location.state?.data?.religion||null}
                    />
                )}
                // rules={{ required: `${t('PLEASE_ENTER_RELIGION')}`}}
                defaultValue={location.state?.data?.religion||null}
            />

            {errors?.religion?.message ? (
                <Typography color={theme.palette.error.main} fontSize={12}>
                    <>{errors?.religion?.message}</>
                </Typography>
            ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t('COMMUNITY')}:</InputLabel>
            <Controller
                control={control}
                name={'community'}
                render={({ field }) => (
                    <Select
                        options={communityList}
                        isSearchable
                        placeholder={`${t('COMMUNITY')}...`}
                        value={
                            communityList.find(
                                (option) => option.id === parseInt(watch('community'))
                            ) || null
                        }
                        getOptionLabel={(option) => `${option.name}`}
                        getOptionValue={(option) => option.id}
                        onChange={(selectedOption) => {
                            handleReportingManager(selectedOption?.id || '');
                            field.onChange(selectedOption?.id || '');
                        }}
                        onBlur={() => field.onBlur()}
                        isClearable
                        defaultValue={location.state?.data?.community||null}
                    />
                )}
                // rules={{ required: `${t('PLEASE_ENTER_COMMUNITY')}`}}
                defaultValue={location.state?.data?.community||null}

            />

            {errors?.community?.message ? (
                <Typography color={theme.palette.error.main} fontSize={12}>
                    <>{errors?.community?.message}</>
                </Typography>
            ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}  p={2}>
        <InputLabel>{t('MOBILE_NUMBER')}:</InputLabel>
        <FormInput
          name='phone'
          type='text'
          placeholder={`${t('MOBILE_NUMBER')}...`}
          control={control}
          className='input-from'
          rules={{
            required: {
                value: true,
                message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
            },
            maxLength: {
                value: 10,
                message: `${t('MOBILE_NUMBER_SHOULD_NOT_EXCEED')}`,
            },
            pattern: {
              value: /^[0-9]*$/,
              message: `${t('ONLY_NUMERIC_CHARACTERS_ALLOWED')}`,
          },
          minLength: {
            value: 10,
            message: `${t('MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG')}`,
        },}}
          inputProps={{
            ...register('phone', {}),
          }}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t('father_name')}:</InputLabel>
            <FormInput
                name="father_name"
                type="text"
                placeholder={`${t('father_name')} ....`}
                control={control}
                className="input-from"
                
                inputProps={{
                    ...register('father_name', {}),
                }}
                errors={errors}
            />
        </Grid>{' '}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t('mother_name')}:</InputLabel>
            <FormInput
                name="mother_name"
                type="text"
                placeholder={`${t('mother_name')}....`}
                control={control}
                className="input-from"
                
                inputProps={{
                    ...register('mother_name', {}),
                }}
                errors={errors}
            />
        </Grid>{' '}
      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}  p={2}>
      <InputLabel>{t('FATHERS_PHONE')}:</InputLabel>
        <FormInput
          name='father_phone'
          type='text'
          placeholder={`${t('FATHERS_PHONE')}...`}
          control={control}
          className='input-from'
          inputProps={{
            ...register('father_phone', {}),
          }}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4} xl={4}  p={2}>
      <InputLabel>{t('MOTHERS_PHONE')}:</InputLabel>
        <FormInput
          name='mother_phone'
          type='text'
          placeholder={`${t('MOTHERS_PHONE')}...`}
          control={control}
          className='input-from'
          inputProps={{
            ...register('mother_phone', {}),
          }}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t('COUNTRY')}:</InputLabel>
            <FormInput
                name="country"
                type="text"
                defaultValue={`${t('India')}`}
                control={control}
                className="input-from"
                readOnly
                
                inputProps={{
                    ...register('country', {}),
                }}
                errors={errors}
            />
        </Grid>{' '}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t('STATE')}:</InputLabel>
            <FormInput
                name="state"
                type="text"
                defaultValue={`${t('Tamil_Nadu')}`}
                control={control}
                className="input-from"
                readOnly
                
                inputProps={{
                    ...register('state', {}),
                }}
                errors={errors}
            />
        </Grid>{' '}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
        <InputLabel>{t('CITY')}:</InputLabel>
            <FormInput
                name="city"
                type="text"
                defaultValue={`${t('Thirupur')}`}
                control={control}
                className="input-from"
                readOnly
                
                inputProps={{
                    ...register('city', {}),
                }}
                errors={errors}
            />
        </Grid>{' '}
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                    <InputLabel>{t('FINANCIAL_TYPE')}:</InputLabel>
                    <Controller
                        control={control}
                        name={'financial_type'}
                        render={({ field }) => (
                            <Select
                                options={financialType}
                                isSearchable
                                placeholder={`${t('Select_Financial_Type')}`}
                                value={
                                    financialType.find(
                                        (option: any) => option.id === watch('financial_type')
                                    ) || null
                                }
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(selectedOption) => {
                                   handleFinancialType(selectedOption?.id || '');
                                    field.onChange(selectedOption?.id || '');
                                }}
                                onBlur={() => field.onBlur()}
                                isClearable
                                defaultValue={location.state?.data?.financial_type||null}
                            />
                        )}
                        // rules={{ required: `${t('Select_Financial_Type')}` }}
                        defaultValue={location.state?.data?.financial_type||null}
                    />
                    {errors?.financial_type?.message ? (
                        <Typography color={theme.palette.error.main} fontSize={12}>
                            {errors?.financial_type?.message}
                        </Typography>
                    ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                    <InputLabel>{t('PARENTING_STATUS')}:</InputLabel>
                    <Controller
                        control={control}
                        name={'parenting_status'}
                        render={({ field }) => (
                            <Select
                                options={parentalStatus}
                                isSearchable
                                placeholder={`${t('Select_PARENTING_STATUS')}`}
                                value={
                                    parentalStatus.find(
                                        (option: any) => option.id === watch('parenting_status')
                                    ) || null
                                }
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(selectedOption) => {
                                    handleParentalStatus(selectedOption?.id || '');
                                    field.onChange(selectedOption?.id || '');
                                }}
                                onBlur={() => field.onBlur()}
                                isClearable
                                defaultValue={location.state?.data?.parenting_status||null}
                            />
                            
                        )}
                        // rules={{ required: `${t('Select_PARENTING_STATUS')}` }}
                        defaultValue={location.state?.data?.parenting_status||null}
                    />
                    {errors?.parenting_status?.message ? (
                        <Typography color={theme.palette.error.main} fontSize={12}>
                            {errors?.parenting_status?.message}
                        </Typography>
                    ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                    <InputLabel>{t("PARENT_OCCUPATION")}:</InputLabel>
                    <Controller
                        control={control}
                        name={'parents_occupation'}
                        render={({ field }) => (
                            <Select
                                options={parentalOccupation}
                                isSearchable
                                placeholder={`${t('Select_PARENT_OCCUPATION')}`}
                                value={
                                    parentalOccupation.find(
                                        (option: any) => option.id === watch('parents_occupation')
                                    ) || null
                                }
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(selectedOption) => {
                                    handleOccupation(selectedOption?.id || '');
                                    field.onChange(selectedOption?.id || '');
                                }}
                                onBlur={() => field.onBlur()}
                                isClearable
                            
                                defaultValue={location.state?.data?.parents_occupation||null}
                            />
                        )}
                        defaultValue={location.state?.data?.parents_occupation||null}
                        // rules={{ required: `${t('Select_PARENT_OCCUPATION')}` }}
                    />
                    {errors?.parents_occupation?.message ? (
                        <Typography color={theme.palette.error.main} fontSize={12}>
                            {errors?.parents_occupation?.message}
                        </Typography>
                    ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                    <InputLabel>{t('parent_education')}:</InputLabel>
                    <Controller
                        control={control}
                        name={'Parents_education'}
                        render={({ field }) => (
                            <Select
                                options={parentalEducation}
                                isSearchable
                                placeholder={`${t('Select_Education')}`}
                                value={
                                    parentalEducation.find(
                                        (option: any) => option.id === watch('Parents_education')
                                    ) || null
                                }
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(selectedOption) => {
                                    handleReportingManager(selectedOption?.id || '');
                                    field.onChange(selectedOption?.id || '');
                                }}
                                onBlur={() => field.onBlur()}
                                isClearable
                                defaultValue={location.state?.data?.Parents_education||null}
                            />
                        )}
                        defaultValue={location.state?.data?.Parents_education||null}
                        // rules={{ required:`${t('PARENT_EDUCATION_IS_REQUIRED')}` }}
                    />
                    {errors?.Parents_education?.message ? (
                        <Typography color={theme.palette.error.main} fontSize={12}>
                            {errors?.Parents_education?.message}
                        </Typography>
                    ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                    <InputLabel>{t('LOCALITY')}:</InputLabel>
                    <Controller
                        control={control}
                        name={'locality_type'}
                        render={({ field }) => (
                            <Select
                                options={locality}
                                isSearchable
                                placeholder={`${t('Select_Locality')}`}
                                value={
                                    locality.find(
                                        (option: any) => option.id === watch('locality_type')
                                    ) || null
                                }
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(selectedOption) => {
                                    handleLocality(selectedOption?.id || '');
                                    field.onChange(selectedOption?.id || '');
                                }}
                                onBlur={() => field.onBlur()}
                                isClearable
                                defaultValue={location.state?.data?.locality_type||null}
                            />
                        )}
                        defaultValue={location.state?.data?.locality_type||null}
                        // rules={{ required:`${t('LOCALITY_IS_REQUIRED')}` }}
                    />
                    {errors?.Parents_education?.message ? (
                        <Typography color={theme.palette.error.main} fontSize={12}>
                            {errors?.Parents_education?.message}
                        </Typography>
                    ) : null}
        </Grid>

    


        
    
    
    
        
        <Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
            <CustomButton
                type="submit"
                text={t('Submit')}
                className="p-12 secondary-button"
            />
        </Grid>
    </Grid></>

        </form>
    </Grid>
);
};

export default StudentEditForm;
