import { Grid, InputLabel, MenuItem,  Select , TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import VolunteerHeader from './StudentHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../../../common/ui/TableComponent';
import CustomButton from '../../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { useDispatch, useSelector } from 'react-redux';


import { 
  courseListInprogress,
  csvStudentListInProgress,
  groupListInprogress,
  resetQuestionList,
  schoolListInprogress, 
  selectCourseList, 
  selectCsvstudentsList, 
  selectCsvstudentsListLoading, 
  selectGroupList, 
  selectSchoolList, 
  selectStandardList, 
  selectstudentsList, 
  selectstudentsListLoading, 
  standardListInProgress, 
  studentsListInProgress 
} from '../../../../store/students/_students.slice';
import CircularProgress from '@mui/material/CircularProgress';
import { surveyDetailsInProgress } from '../../../../store/survey/_survey.slice';
import { Controller, useForm } from 'react-hook-form';
import { passFailStatus, studentStatus, surveyStatus, yearsList } from '../../../../common/constants/FormItems';
import { StudentModel } from '../../../../models/StudentModel';

function Student() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location =useLocation()
  const dispatch = useDispatch();
  
  const schoolDetails: any = useSelector(selectSchoolList);
  const currentYear = new Date().getFullYear();


  const schoolList: any = schoolDetails?.data || [];
  const { control, watch, handleSubmit, setValue } = useForm({
    defaultValues: {
      standard_id: null,
      school_id: null,
      result: null,
      is_survey_completed: null,
     student_emis_no:null,
      search: null,
      student_status:null,
      year:null,
      course_id:null,
      college_id:null,
      subject_code:null
    }
  });
  const studentDetails = useSelector(selectstudentsList);

  const standardDetails: any = useSelector(selectStandardList);
  const csvDetails: any = useSelector(selectCsvstudentsList);
  const csvStudentListLodaing:any=useSelector(selectCsvstudentsListLoading)
  const studentListLoading = useSelector(selectstudentsListLoading);
  const studentList = studentDetails?.data || [];
  const [accumulatedStudentList, setAccumulatedStudentList] = useState<StudentModel[]>([]);
  const studentTotalRecords = studentDetails?.totalRecords || 0;
  const standardList: any = standardDetails?.data || [];
  const [currentPage, setCurrentPage] = useState<number>(1);
  const screentype=location?.state?.type
 


  const courseDetails:any=useSelector(selectCourseList)
  const courseList: any = courseDetails?.data || [];
  const StudentStatusWatcher=watch('student_status')
  const standardWatcher=watch("standard_id")
  console.log("standardWatcher",standardWatcher)
  const courseWatcher=watch("course_id")
  console.log("studentList",studentList)
  const csvDatas:any=csvDetails?.data || [] 
  useEffect(() => {
    if (studentList.length > 0) {
      const studentListIds = new Set(studentList.map((student:any) => student.id));
      const filteredAccumulatedList = accumulatedStudentList.filter((student: any) => !studentListIds.has(student.id));
      const resultArray = [...studentList, ...filteredAccumulatedList];
      setAccumulatedStudentList(resultArray);
    }
  }, [studentList]);
console.log("accumulatedStudentList",accumulatedStudentList)
  const handlefilter = () => {
    setAccumulatedStudentList([]);
    const {standard_id, school_id, result, is_survey_completed ,student_emis_no,year,search,course_id,student_status} = watch();
    const payload: any = {
      standard_id:location?.state?.standard_id?location?.state?.standard_id:standard_id,
      school_id:location?.state?.school_id?location?.state?.school_id: school_id,
      result: location?.state?.result?location?.state?.result:result,
      is_survey_completed:location?.state?.is_survey_completed?location?.state?.is_survey_completed: is_survey_completed,
      pageNo: 1,
      pagination: true,
     student_emis_no:search,
     student_status: location?.state?.student_status ?? (course_id ? 1 : student_status),
     year:location?.state?.year?location?.state?.year:year,
     course_id:course_id,
    
    };

    dispatch(studentsListInProgress(payload));
  
    // dispatch(csvStudentListInProgress(payload2 as any));
  
  }
  useEffect(() => {
    dispatch(resetQuestionList());
  }, []);
  const surveyCompletedClick = (studentId: any) => {
    const studentIds = studentId?.row?.id;
    navigate(APP_ROUTES.SURVEY_DETAILS, { state: studentIds });
    dispatch(surveyDetailsInProgress(studentIds));
  }
  
  const editClick = (studentId: any) => {
    const studentIds = studentId?.row;
    navigate(APP_ROUTES.STUDENT_FORM, {
      state: {
        data: studentIds,
        type: 'Edit',
        tab: 2,
      },
    });

  }
  const studentEditClick = (studentId: any) => {
    const studentIds = studentId?.row;
    navigate(APP_ROUTES.STUDENT_EDIT_FORM, {
      state: {
        data: studentIds,
        type: 'Edit',
        tab: 2,
      },
    });

  }

  useEffect(() => {
    const { standard_id, school_id, result, is_survey_completed ,course_id,student_emis_no,student_status,year,search,college_id,subject_code} = watch();
    const payload: any = {
      standard_id:location?.state?.standard_id?location?.state?.standard_id:standard_id,
      school_id:location?.state?.school_id?location?.state?.school_id: school_id,
      result: location?.state?.result?location?.state?.result:result,
      is_survey_completed:location?.state?.is_survey_completed?location?.state?.is_survey_completed: is_survey_completed,
      pageNo: 1,
      pagination: true,
     student_emis_no:search,
     student_status:location?.state?.student_status?location?.state?.student_status:student_status,
     year:location?.state?.year?location?.state?.year:year,
     course_id:location?.state?.course_id?location?.state?.course_id:course_id,
     college_id:location?.state?.college_id?location?.state?.college_id:college_id,
     subject_code:location?.state?.subject_code?location?.state?.subject_code:subject_code,

    };
    const payload2: any = {
      standard_id:location?.state?.standard_id?location?.state?.standard_id:standard_id,
      school_id:location?.state?.school_id?location?.state?.school_id: school_id,
      result: location?.state?.result?location?.state?.result:result,
      is_survey_completed:location?.state?.is_survey_completed?location?.state?.is_survey_completed: is_survey_completed,
      pageNo: 1,
      pagination: false,
     student_emis_no:search,
     student_status:location?.state?.student_status?location?.state?.student_status:student_status,
     year:location?.state?.year?location?.state?.year:year,
     course_id:location?.state?.course_id?location?.state?.course_id:course_id

    };
    dispatch(studentsListInProgress(payload as any));
  
      // dispatch(csvStudentListInProgress(payload2 as any));
  
  }, []);

  useEffect(() => {
    dispatch(standardListInProgress());
    dispatch(schoolListInprogress());
    dispatch(courseListInprogress())
   
  }, []);
  
  const columns3 = [
    {
      field: 'student_emis_no',
      headerName: `${t('Student_emis_no')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'firstName',
      headerName: `${t('First_Name')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    // {
    //   field: 'email',
    //   headerName: `${t('EMAIL')}`,
    //   disableColumnMenu: true,
    //   flex: 1,
    // },
    {
      field: 'contact',
      headerName: `${t('MOBILE_NUMBER')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    // {
    //   field: 'dob',
    //   headerName: `${t('DOB')}`,
    //   disableColumnMenu: true,
    //   flex: 1,
    // },
    {
      field: 'courseName',
      headerName: `${t('course_name')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'collegeName',
      headerName: `${t('college_name')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'school_name',
      headerName: `${t('SCHOOL_NAME')}`,
      disableColumnMenu: true,
      flex: 1,
    },
  
  
    {
      field: 'Action',
      headerName: `${t('Action')}`,
      disableColumnMenu: true,
      width: 350,
      renderCell: (params: any) => (
        <>
       
       { !params?.row?.answers  ? (
            <CustomButton
              text={t("Add_Survey")}
              className='mx-5'
              color={'primary'}
              onClick={() => {
            
                navigate(APP_ROUTES.STUDENT_FORM, {
                  state: {
                    data: params.row,
                    type: 'addSurvey',
                    tab: 1,
                  },
                });
              }}
            />
          ) : (<>
          {params?.row?.answers?.is_new==="Y"&&(
             <CustomButton
             text={t("Survey_Completed")}  
             className='mx-3 color-green'
             onClick={() => { surveyCompletedClick(params) }}
             color='success'
           />
          )}
           
            {console.log(params?.row?.answers?.is_new,"params?.row?.answers?.is_new")}
            
            <>{params?.row?.answers&&params?.row?.answers?.standard_id==="a3c15d59-31d5-49fe-a002-f741e23ff80e"&&(
              <>
              {params?.row?.answers?.is_new==="N"?
              <CustomButton
              text={t("Add_Survey")}
              className='mx-5'
              color={'primary'}
              onClick={() => {
            
                navigate(APP_ROUTES.STUDENT_FORM, {
                  state: {
                    data: params.row,
                    type: 'addSurvey',
                    tab: 1,
                  },
                });
              }}
            />
              :
          <CustomButton
          text={t("EDIT")}  
         className='mx-3'
         onClick={() => { editClick(params) }}
         color='warning'
       />}
            </>

            )}</>
            </>
          )}
           <CustomButton
          text={t("STUDENT_EDIT")}  
         className='mx-3'
         onClick={() => { studentEditClick(params) }}
         color='warning'/>
        </>
      ),
    },
  ];
 
 
  const columns = [
    {
      field: 'student_emis_no',
      headerName: `${t('Student_emis_no')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'firstName',
      headerName: `${t('First_Name')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    // {
    //   field: 'email',
    //   headerName: `${t('EMAIL')}`,
    //   disableColumnMenu: true,
    //   flex: 1,
    // },
    {
      field: 'contact',
      headerName: `${t('MOBILE_NUMBER')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    // {
    //   field: 'dob',
    //   headerName: `${t('DOB')}`,
    //   disableColumnMenu: true,
    //   flex: 1,
    // },
    {
      field: 'courseName',
      headerName: `${t('course_name')}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'collegeName',
      headerName: `${t('college_name')}`,
      disableColumnMenu: true,
      flex: 1,
    },
   
  
    {
      field: 'Action',
      headerName: `${t('Action')}`,
      disableColumnMenu: true,
      width: 320,
      renderCell: (params: any) => (
        <>
       
          { !params?.row?.answers  ? (
            <CustomButton
              text={t("Add_Survey")}
              className='mx-5'
              color={'primary'}
              onClick={() => {
            
                navigate(APP_ROUTES.STUDENT_FORM, {
                  state: {
                    data: params.row,
                    type: 'addSurvey',
                    tab: 1,
                  },
                });
              }}
            />
          ) : (<>
          {params?.row?.answers?.is_new==="Y"&&(
             <CustomButton
             text={t("Survey_Completed")}  
             className='mx-3 color-green'
             onClick={() => { surveyCompletedClick(params) }}
             color='success'
           />
          )}
           
            {console.log(params?.row?.answers?.is_new,"params?.row?.answers?.is_new")}
            
            <>{params?.row?.answers&&params?.row?.answers?.standard_id==="a3c15d59-31d5-49fe-a002-f741e23ff80e"&&(
              <>
              {params?.row?.answers?.is_new==="N"?
              <CustomButton
              text={t("Add_Survey")}
              className='mx-5'
              color={'primary'}
              onClick={() => {
            
                navigate(APP_ROUTES.STUDENT_FORM, {
                  state: {
                    data: params.row,
                    type: 'addSurvey',
                    tab: 1,
                  },
                });
              }}
            />
              :
          <CustomButton
          text={t("EDIT")}  
         className='mx-3'
         onClick={() => { editClick(params) }}
         color='warning'
       />}
            </>

            )}</>
            </>
          )}
           <CustomButton
          text={t("STUDENT_EDIT")}  
         className='mx-3'
         onClick={() => { studentEditClick(params) }}
         color='info'/>
        </>
      ),
    },
  ];
  console.log("studentList",studentList)
  const rows = studentList.map((student: any) => {
    const answers = student?.Answers?.[0] || {};
    const information=student?.StudentInformations?.[0]||{}
    const school_name = student?.Answers?.[0]?.School?.name || 'N/A';
  
    return {
      student_emis_no: student.student_emis_no,
      firstName: student.first_name,
      lastName: student.last_name,
      email: student.email,
      contact: student.phone,
      dob: student.DOB,
      id: student.id,
      surveyStatus: student.survey_status,
      school_code: student.school_code,
      middle_name: student.middle_name,
      community: student.community,
      religion: student.religion,
      father_name: student.father_name,
      mother_name: student.mother_name,
      father_phone: student.father_phone,
      mother_phone: student.mother_phone,
      guardian_name: student.guardian_name,
      guardian_number: student.guardian_number,
      address: student.address,
      address2: student.address2,  // Correctly map address2
      country: student.country,  // Add missing properties if needed
      state: student.state,
      city: student.city,
      financial_type: student.financial_type,
      parenting_status: student.parenting_status,
      parents_occupation: student.parents_occupation,
      locality_type: student.locality_type,
      school_id: student.school_id,
      standard_id: student.standard_id,
      year: student.year,
      created_at: student.created_at,
      updated_at: student.updated_at,
      courseName: answers.course_name || 'N/A',
      collegeName: answers.college_name || 'N/A',
      answers: student.Answers?.[0],
      school_name: school_name,
      StudentInformations:information
    };
  });
  
console.log("rows",rows)
  // const rows = studentList.map((student: any) => ({
  //   const answers = student.Answers[0] || {};
   

       
   
        
      
         
      
        
  // }));
  const rows2 = accumulatedStudentList.map((student: any) => ({
    student_emis_no: student.student_emis_no,
    firstName: student.first_name,
    lastName: student.last_name,
    email: student.email,
    contact: student.phone,
    dob: student.DOB,
    id: student.id,
    surveyStatus: student?.survey_status===1?"Not-Completed":"Completed",
    school_code: student.school_code,
     middle_name:student.middle_name,
     community:student.community,
     religion:student.religion,
     father_name:student.father_name,
     mother_name:student.mother_name,
     father_phone:student.father_phone,
     mother_phone:student.mother_phone,
     guardian_name:student.guardian_name,
     guardian_number:student.guardian_number,
     address:student.address,
     address2:student.address,

         
      
        
  }));
   

  const handlePagination = (data: any) => {
    setCurrentPage(data);
    const { standard_id, school_id, result, is_survey_completed ,course_id,student_emis_no,student_status,year,search,college_id,subject_code} = watch();
   
    
    const payloads = {
      standard_id:location?.state?.standard_id?location?.state?.standard_id:standard_id,
      school_id:location?.state?.school_id?location?.state?.school_id: school_id,
      result: location?.state?.result?location?.state?.result:result,
      is_survey_completed:location?.state?.is_survey_completed?location?.state?.is_survey_completed: is_survey_completed,
    
      pagination: true,
     student_emis_no:search,
     student_status:location?.state?.student_status?location?.state?.student_status:student_status,
     year:location?.state?.year?location?.state?.year:year,
     course_id:location?.state?.course_id?location?.state?.course_id:course_id,
     college_id:location?.state?.college_id?location?.state?.college_id:college_id,
     subject_code:location?.state?.subject_code?location?.state?.subject_code:subject_code,
      
    pageNo: data?.page + 1,
   
    };
    dispatch(studentsListInProgress(payloads as any));
  };
const handleOnchange=(value:any)=>{
  console.log("csvClick")
  setAccumulatedStudentList([]);
 
  let timerId;
  if (timerId) {
    clearTimeout(timerId);
  }
  timerId = setTimeout(() => {
    const { standard_id, school_id, result, is_survey_completed ,student_emis_no,student_status,year ,course_id,search} = watch();
  const payloads = {
    standard_id:location?.state?.standard_id?location?.state?.standard_id:standard_id,
      school_id:location?.state?.school_id?location?.state?.school_id: school_id,
      result: location?.state?.result?location?.state?.result:result,
      is_survey_completed:location?.state?.is_survey_completed?location?.state?.is_survey_completed: is_survey_completed,
    
      pagination: true,
     student_emis_no:value,
     student_status:location?.state?.student_status?location?.state?.student_status:student_status,
     year:location?.state?.year?location?.state?.year:year,
    pageNo: 1,
    course_id:location?.state?.course_id?location?.state?.course_id:course_id

   

  };
  dispatch(studentsListInProgress(payloads as any));
  }, 500);
 

}
const handleCourseChange = (value: any,name:any) => {
  console.log("value",value,"name",name)
  setValue('course_id', value, {
      shouldValidate: true,
  });

     
 




};
const handleCsvClick=()=>{
 
  const { standard_id, school_id, result, is_survey_completed ,course_id,student_emis_no,student_status,year,search} = watch();
  const payload2: any = {
    standard_id:location?.state?.standard_id?location?.state?.standard_id:standard_id,
    school_id:location?.state?.school_id?location?.state?.school_id: school_id,
    result: location?.state?.result?location?.state?.result:result,
    is_survey_completed:location?.state?.is_survey_completed?location?.state?.is_survey_completed: is_survey_completed,
    pageNo: 1,
    pagination: false,
   student_emis_no:search,
   student_status:location?.state?.student_status?location?.state?.student_status:student_status,
   year:location?.state?.year?location?.state?.year:year,
   course_id:location?.state?.course_id?location?.state?.course_id:course_id

  };

  // dispatch(csvStudentListInProgress(payload2 as any));
}



  return (
    <Grid container>
      <Grid item xs={12}>
        <VolunteerHeader />
      </Grid>
    
     
        <>
         
          <Grid container  display={"flex"} alignItems={"center"}>
         
         {screentype&&screentype==="streamBased"?(<>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
              <InputLabel>{t('School')}:</InputLabel>
              <Controller
                control={control}
                name="school_id"
          
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    displayEmpty
                  >
                  
                    {schoolList.map((school: any) => (
                      <MenuItem key={school.id} value={school.id}>
                        {school.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
						<InputLabel>{t('Year')}:</InputLabel>
						<Controller
							control={control}
							name="year"
							render={({ field }) => (
								<Select
									{...field}
									fullWidth
									value={field.value}
									onChange={(e) => {
										field.onChange(e.target.value);
									}}
								>
									{yearsList.map((year:any) => (
										<MenuItem key={year.id} value={year.id}>
											{year.name}
										</MenuItem>
									))}
								</Select>
							)}
							rules={{ required: "Year is required" }}
						/>
					</Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2}>
              <InputLabel>{t('Select_Stream')}:</InputLabel>
              <Controller
                control={control}
                name="course_id"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    value={field.value}
                    onChange={(e:any) => {
                      field.onChange(e?.target?.value);
                    
                    }}
                    displayEmpty
                  >
                    
                    {courseList.map((status: any) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid></>):(<>
              <Grid item xs={12} sm={6} md={6} lg={2} xl={2} p={2}>
              <InputLabel>{t('School')}:</InputLabel>
              <Controller
                control={control}
                name="school_id"
          
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    displayEmpty
                  >
                  
                    {schoolList.map((school: any) => (
                      <MenuItem key={school.id} value={school.id}>
                        {school.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2} p={2}>
              <InputLabel>{t('Pass')}/{t('Fail')}:</InputLabel>
              <Controller
                control={control}
                name="result"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    displayEmpty
                  >
                    
                    {passFailStatus.map((status: any) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          

            <Grid item xs={12} sm={6} md={6} lg={2} xl={2} p={2}>
              <InputLabel>{t('Standard')}:</InputLabel>
              <Controller
                control={control}
                name="standard_id"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      console.log("e.target.value",e.target.value)
                    }}
                    displayEmpty
                  >
                   
                    {standardList.map((standard: any) => (
                      <MenuItem key={standard.id} value={standard.id}>
                        {standard.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          
            <Grid item xs={12} sm={6} md={6} lg={2} xl={2} p={2}>
              <InputLabel>{t('Survey_Status')}:</InputLabel>
              <Controller
                control={control}
                name="is_survey_completed"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    displayEmpty
                  >
                   
                    {surveyStatus.map((status: any) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={2} xl={2} p={2}>
              <InputLabel>{t('Student_Status')}:</InputLabel>
              <Controller
                control={control}
                name="student_status"
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    displayEmpty
                  >
                   
                    {studentStatus.map((status: any) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid></>)}
        
           
      
    
          

           
            <Grid item xs={12} sm={6} md={6} lg={1} xl={1} p={2} mt={2.5}>
              <CustomButton
                onClick={handlefilter}
                text={t("Filter")}
                className="p-12 secondary-button"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <Grid item xs={12}  className='search-by-text' display={"flex"} justifyContent={"flex-end"}>
          <Grid item xs={12} sm={4.5} md={4.5} lg={4} xl={4} p={2} mt={2.5}>
          <InputLabel>{t('Search_by_text')}:</InputLabel>
              <TextField
                fullWidth
              
                onChange={(e:any) =>handleOnchange(e.target.value)}
              /></Grid>
            </Grid>
            {studentListLoading ?
        <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress />
        </Grid>
        :
            <TableComponent
              totalRecords={studentTotalRecords}
              columns={screentype==="streamBased"?columns3:columns}
              rows={rows}
              pagination={false}
              csvData={rows2 }
              handleCsvClick={handleCsvClick}
              handlePagination={handlePagination}
              paginationModel={{
                page: Number(studentDetails?.currentPage) - 1 || 0,
                pageSize: Number(studentDetails?.pagesize) || 5,
              }}
              type={"student_list"}
            />}
          </Grid>
        </>
      
    </Grid>
  );
}

export default Student;
