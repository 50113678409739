import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../../../common/ui/TableComponent';
import CustomButton from '../../../../common/ui/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import corousel2 from '../../../../common/icons/800px-TamilNadu_Logo.svg.png';
import corousel1 from '../../../../common/icons/noDataFoundTwo.png';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import {
  selectSurveyDetailsList,
  selectSurveyDetailsListLoading,
  surveyDetailsInProgress,
} from '../../../../store/survey/_survey.slice';
import {
  communityList,
  financialType,
  healthStatusOption,
  locality,
  parentalEducation,
  parentalOccupation,
  parentalStatus,
  reportingManagerList,
} from '../../../../common/constants/FormItems';

function SurveyDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const surveyDetails: any = useSelector(selectSurveyDetailsList);
  const studentListLoading = useSelector(selectSurveyDetailsListLoading);
  const surveyList = surveyDetails?.data || [];
  const marks = surveyList?.Answers?.[0]?.studentMarks || [];
  const medium = surveyList?.Answers?.[0]?.medium;
  const hardCodedAnswers= surveyList?.Answers?.[0];
  console.log("hardCodedAnswers",surveyDetails)

  const answers = surveyList?.Answers?.[0]?.questions_answer || [];
  console.log('surveyList', answers);

  const getLocalityName = (category: any) => {
    const localityObj = locality.find((loc) => loc.Category === category);
    return localityObj ? localityObj.name : 'Nill';
  };
  const getParentStatus = (category: any) => {
    const localityObj = healthStatusOption.find((loc) => loc.Category === category);
    return localityObj ? localityObj.name : 'Nill';
  };
  const religionStatus = (category: any) => {
    const localityObj = reportingManagerList.find((loc) => loc.Category === category);
    return localityObj ? localityObj.name : 'Nill';
  };
  const communityStatus = (category: any) => {
    const localityObj = communityList.find((loc) => loc.Category === category);
    return localityObj ? localityObj.name : 'Nill';
  };

  const getParentparentalEducation = (category: any) => {
    const localityObj = parentalEducation.find((loc) => loc.Category === category);
    return localityObj ? localityObj.name : 'Nill';
  };

  const getparentalOccuptaion = (category: any) => {
    const localityObj = parentalOccupation.find((loc) => loc.Category === category);
    return localityObj ? localityObj.name : 'Nill';
  };
  const getparentingStatus = (category: any) => {
    const localityObj = parentalStatus.find((loc) => loc.Category === category);
    return localityObj ? localityObj.name : 'Nill';
  };
  const getFinacialStatus = (category: any) => {
    const localityObj = financialType.find((loc) => loc.Category === category);
    return localityObj ? localityObj.name : 'Nill';
  };

  const surveyCompletedClick = (studentId: any) => {
    const studentIds = studentId?.row?.id;
    dispatch(surveyDetailsInProgress(studentIds));
  };

  useEffect(() => {
    dispatch(surveyDetailsInProgress(location?.state));
  }, [dispatch, location.state]);

  const columns = [
    {
      field: 'subject',
      headerName: `${t("SUBJECT")}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'medium',
      headerName: `${t("Marks")}`,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: 'marks',
      headerName: `${t("Medium")}`,
      disableColumnMenu: true,
      flex: 1,
    },
  ];
 
  const hardcodedQuestions = [
    {
        "questions_id": "8abd25f4-8a7d-4407-a3dc-b357ceef85a5",
        "question": "நீங்கள் எந்த கல்லூரியிலாவது சேர்ந்துள்ளீர்களா?",
        "options": [
            {
                "option": "பயிற்சி முறைகளின் குறைபாடு"
            },
            {
                "option": "கவனச்சிதறல்"
            },
            {
                "option": "நீங்கள் மெதுவாகப் புரிந்துகொள்ளும் நபர்?"
            },
            {
                "option":`${Number(hardCodedAnswers?.higher_studies)===1?"ஆம்":"இல்லை"}`
            }
        ],
        "option4": `${Number(hardCodedAnswers?.higher_studies)===1?"ஆம்":"இல்லை"}`
    },
  
    {
        "questions_id": "6abf0e4c-995e-4028-9a78-64d8880876da",
        "question": `${Number(hardCodedAnswers?.higher_studies)===1?"எந்த வகையான உயர்கல்வியில் சேர விரும்புகிறீர்கள்?":"நீங்கள் எந்த கல்லூரிக்காவது விண்ணபித்துள்ளீர்களா?"}`,
        "options": [
            {
                "option": "தொடர்ச்சியான ஊக்குவிப்பு"
            },
            {
                "option": "தகவல் தொழில்நுட்ப ஆதரவு"
            },
            {
                "option": "கூடுதல் பயிற்சி வகுப்புகள்"
            },
            {
                "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
            }
        ],
        "option4": `${Number(hardCodedAnswers?.higher_studies)===1?hardCodedAnswers?.course_name:Number(hardCodedAnswers?.applied_college)===1?"ஆம்":"இல்லை"}` 
    },
    {
      "question_id": "6abf0e4c-995e-4028-9a78-64d8880876da",
      "question": `${Number(hardCodedAnswers?.higher_studies)===1?"நீங்கள் சேர விரும்பும் கல்லூரி?":Number(hardCodedAnswers?.applied_college)===1?"எந்த வகையான உயர்கல்வியில் சேர விரும்புகிறீர்கள்?":"திறன் சார்ந்த படிப்பில் ஆர்வமாக உள்ளீர்களா?"}`,
      "options": [
          {
              "option": "தொடர்ச்சியான ஊக்குவிப்பு"
          },
          {
              "option": "தகவல் தொழில்நுட்ப ஆதரவு"
          },
          {
              "option": "கூடுதல் பயிற்சி வகுப்புகள்"
          },
          {
              "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
          }
      ],
      "option4": `${Number(hardCodedAnswers?.higher_studies)===1?hardCodedAnswers?.college_name:Number(hardCodedAnswers?.applied_college)===1?hardCodedAnswers?.course_name:Number(hardCodedAnswers?.skill_based)===1?"ஆம்":"இல்லை"}` 
  },
  {
    "question_id": "6abf0e4c-995e-4028-9a78-64d8880876da",
    "question": `${Number(hardCodedAnswers?.higher_studies)===2?Number(hardCodedAnswers?.applied_college)===1?"நீங்கள் சேர விரும்பும் கல்லூரி?":Number(hardCodedAnswers?.skill_based)===1?"திறன் அடிப்படையிலான படிப்புகள்:":"தற்போது என்ன செய்துகொண்டுள்ளீர்கள்?":"உயர்கல்விக்கான வழிகாட்டி வகுப்புகள் தேவை படுகிறதா?"}`,
    "options": [
        {
            "option": "தொடர்ச்சியான ஊக்குவிப்பு"
        },
        {
            "option": "தகவல் தொழில்நுட்ப ஆதரவு"
        },
        {
            "option": "கூடுதல் பயிற்சி வகுப்புகள்"
        },
        {
            "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
        }
    ],
    "option4": `${Number(hardCodedAnswers?.higher_studies)===2?Number(hardCodedAnswers?.applied_college)===1?hardCodedAnswers?.college_name:Number(hardCodedAnswers?.skill_based)===1?hardCodedAnswers?.SkillBasedCourse?.name:hardCodedAnswers?.current_work:Number(hardCodedAnswers?.career_guidance)===1?"ஆம்":"இல்லை"}` 
},
// {
//   "question_id": "6abf0e4c-995e-4028-9a78-64d8880876da",
//   "question": `உயர்கல்விக்கான வழிகாட்டி வகுப்புகள் தேவை படுகிறதா?`,
//   "options": [
//       {
//           "option": "தொடர்ச்சியான ஊக்குவிப்பு"
//       },
//       {
//           "option": "தகவல் தொழில்நுட்ப ஆதரவு"
//       },
//       {
//           "option": "கூடுதல் பயிற்சி வகுப்புகள்"
//       },
//       {
//           "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
//       }
//   ],
//   "option4": `${Number(hardCodedAnswers?.career_guidance)===1?"ஆம்":"இல்லை"}` 
// },
{
  "question_id": "6abf0e4c-995e-4028-9a78-64d8880876da",
  "question": `பெற்றோர் உடல் நலம் குன்றியிருப்பின்`,
  "options": [
      {
          "option": "தொடர்ச்சியான ஊக்குவிப்பு"
      },
      {
          "option": "தகவல் தொழில்நுட்ப ஆதரவு"
      },
      {
          "option": "கூடுதல் பயிற்சி வகுப்புகள்"
      },
      {
          "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
      }
  ],
  "option4": `${getParentStatus(surveyList?.Answers?.[0]?.parent_status)}` 
},



]

if (Number(hardCodedAnswers?.result)===2) {
  hardcodedQuestions.push({
    "question_id": "6abf0e4c-995e-4028-9a78-64d8880876da",
    "question": `குறிப்பிட்ட பாடங்களில் சிரமம் இருக்கிறதா?`,
    "options": [
        {
            "option": "தொடர்ச்சியான ஊக்குவிப்பு"
        },
        {
            "option": "தகவல் தொழில்நுட்ப ஆதரவு"
        },
        {
            "option": "கூடுதல் பயிற்சி வகுப்புகள்"
        },
        {
            "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
        }
    ],
    "option4": `${Number(hardCodedAnswers?.struggling_subject)===1?"ஆம்":"இல்லை"}` 
  });
}
if (Number(hardCodedAnswers?.struggling_subject) === 1) {
  hardcodedQuestions.push({
      "question_id": "new-question-id", // unique question ID
      "question": "எந்த பாடம்?",
      "options": [
          {
              "option": "தொடர்ச்சியான ஊக்குவிப்பு"
          },
          {
              "option": "தகவல் தொழில்நுட்ப ஆதரவு"
          },
          {
              "option": "கூடுதல் பயிற்சி வகுப்புகள்"
          },
          {
              "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
          }
      ],
      "option4": `${hardCodedAnswers?.struggling_subject_reson}` // Default option or based on some other condition
  });
}
const hardcodedQuestions10= [
  {
      "questions_id": "8abd25f4-8a7d-4407-a3dc-b357ceef85a5",
      "question": "நீங்கள் உயர்கல்வியில் பயில விருப்பம் உள்ளவரா?",
      "options": [
          {
              "option": "பயிற்சி முறைகளின் குறைபாடு"
          },
          {
              "option": "கவனச்சிதறல்"
          },
          {
              "option": "நீங்கள் மெதுவாகப் புரிந்துகொள்ளும் நபர்?"
          },
          {
              "option":`${Number(hardCodedAnswers?.higher_studies)===1?"ஆம்":"இல்லை"}`
          }
      ],
      "option4": `${Number(hardCodedAnswers?.higher_studies)===1?"ஆம்":"இல்லை"}`
  },

  {
      "questions_id": "6abf0e4c-995e-4028-9a78-64d8880876da",
      "question": `${Number(hardCodedAnswers?.higher_studies)===1?"எந்த வகையான உயர்கல்வியில் சேர விரும்புகிறீர்கள்?":"திறன் சார்ந்த படிப்பில் ஆர்வமாக உள்ளீர்களா?"}`,
      "options": [
          {
              "option": "தொடர்ச்சியான ஊக்குவிப்பு"
          },
          {
              "option": "தகவல் தொழில்நுட்ப ஆதரவு"
          },
          {
              "option": "கூடுதல் பயிற்சி வகுப்புகள்"
          },
          {
              "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
          }
      ],
      "option4": `${Number(hardCodedAnswers?.higher_studies)===1?hardCodedAnswers?.course_name:Number(hardCodedAnswers?.skill_based)===1?"ஆம்":"இல்லை"}` 
  },

{
  "question_id": "6abf0e4c-995e-4028-9a78-64d8880876da",
  "question": `${Number(hardCodedAnswers?.higher_studies)===2?Number(hardCodedAnswers?.skill_based)===1?hardCodedAnswers?.SkillBasedCourse:hardCodedAnswers?.current_work:"உயர்கல்விக்கான வழிகாட்டி வகுப்புகள் தேவை படுகிறதா?"}`,
  "options": [
      {
          "option": "தொடர்ச்சியான ஊக்குவிப்பு"
      },
      {
          "option": "தகவல் தொழில்நுட்ப ஆதரவு"
      },
      {
          "option": "கூடுதல் பயிற்சி வகுப்புகள்"
      },
      {
          "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
      }
  ],
  "option4": `${Number(hardCodedAnswers?.higher_studies)===2?Number(hardCodedAnswers?.skill_based)===1?hardCodedAnswers?.SkillBasedCourse:hardCodedAnswers?.current_work:Number(hardCodedAnswers?.current_work)===1?"ஆம்":"இல்லை"}` 
},
{
"question_id": "6abf0e4c-995e-4028-9a78-64d8880876da",
"question": `பெற்றோர் உடல் நலம் குன்றியிருப்பின்`,
"options": [
    {
        "option": "தொடர்ச்சியான ஊக்குவிப்பு"
    },
    {
        "option": "தகவல் தொழில்நுட்ப ஆதரவு"
    },
    {
        "option": "கூடுதல் பயிற்சி வகுப்புகள்"
    },
    {
        "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
    }
],
"option4": `${getParentStatus(surveyList?.Answers?.[0]?.parent_status)}` 
},
 
]
if (Number(hardCodedAnswers?.result)===2) {
  hardcodedQuestions10.push({
    "question_id": "6abf0e4c-995e-4028-9a78-64d8880876da",
    "question": `குறிப்பிட்ட பாடங்களில் சிரமம் இருக்கிறதா?`,
    "options": [
        {
            "option": "தொடர்ச்சியான ஊக்குவிப்பு"
        },
        {
            "option": "தகவல் தொழில்நுட்ப ஆதரவு"
        },
        {
            "option": "கூடுதல் பயிற்சி வகுப்புகள்"
        },
        {
            "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
        }
    ],
    "option4": `${Number(hardCodedAnswers?.struggling_subject)===1?"ஆம்":"இல்லை"}` 
  });
}
if (Number(hardCodedAnswers?.struggling_subject) === 1) {
  hardcodedQuestions10.push({
      "question_id": "new-question-id", // unique question ID
      "question": "எந்த பாடம்?",
      "options": [
          {
              "option": "தொடர்ச்சியான ஊக்குவிப்பு"
          },
          {
              "option": "தகவல் தொழில்நுட்ப ஆதரவு"
          },
          {
              "option": "கூடுதல் பயிற்சி வகுப்புகள்"
          },
          {
              "option": "விரிவான மற்றும் புரிந்துகொள்ளக்கூடிய விளக்கங்கள்"
          }
      ],
      "option4": `${hardCodedAnswers?.struggling_subject_reson}` // Default option or based on some other condition
  });
}

  const combinedAnswers = [...hardcodedQuestions, ...answers];
  const combinedAnswers10 = [...hardcodedQuestions10, ...answers];

  function renderRow(props:any) {
    const { index, style } = props;
    const item = hardCodedAnswers?.Standard?.id === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20" ? combinedAnswers10[index] : combinedAnswers[index];

    if (!item || !item.options) {
      return null;
    }
    const answerKey = item;
    return (
      <ListItem
        style={{ ...style, whiteSpace: 'normal', wordBreak: 'break-word', padding: '10px 0' }}
        key={index}
        component="div"
        disablePadding
      >
        <ListItemButton>
          <ListItemText
            primary={`${index + 1}. ${item.question || item.questions}`}
            secondary={`Answer: ${Object.values(answerKey)[3]}`}
            primaryTypographyProps={{
              style: { whiteSpace: 'normal', wordBreak: 'break-word', marginBottom: '10px' },
            }}
            secondaryTypographyProps={{ style: { whiteSpace: 'normal', wordBreak: 'break-word' } }}
          />
        </ListItemButton>
      </ListItem>
    );
  }
  const rows = marks?.map((survey: any) => ({
    id: survey?.id,
    subject: survey?.studentSubject?.subject,
    medium: Number(medium) === 1 ? 'English' : 'Tamil',
    marks: survey?.total_marks,
    result: survey?.result,
  }));
  const combinedItemCount = hardCodedAnswers?.Standard?.id === "ab2cc5ed-52d9-45a1-bb38-5d830354fd20" ? combinedAnswers10.length : combinedAnswers.length;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="donation-header">
          <Typography fontSize={20} fontWeight={700}>
        {t("Survey_Details")}
          </Typography>

          <>
            <CustomButton
              type="button"
              text= {t("GO_BACK")}
              startIcon={SubdirectoryArrowRightIcon}
              color={'primary'}
              className="p-8 secondary-button"
              onClick={() => {
                navigate(-1);
              }}
            />
          </>
        </Grid>
      </Grid>
      <Grid item xs={12}></Grid>
      {studentListLoading ? (
        <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} my={5} display={'flex'} justifyContent={'center'}>
            <img src={corousel2} className="tamilnadu-government-logo" />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <span className="header-text">{t("Student_Name")}:</span>
              {surveyList?.first_name}
            </Typography>
            <Typography>
              <span className="header-text">{t("Student_emis_no")}:</span>
              {surveyList?.student_emis_no}
            </Typography>
            <Typography>
              <span className="header-text">{t("Year")}:</span>
              {Number(surveyList?.Answers?.[0]?.year)}
            </Typography>
          </Grid>
          {console.log('marks', marks.length > 0)}
          {marks.length > 0 ? (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} my={5}>
              <TableComponent columns={columns} rows={rows} pagination={false} hideFooterPagination={true} />
              <Grid item xs={12} display={'flex'} justifyContent={'flex-start'}>
                <Grid>
                  <Typography>
                    <Typography>
                      <span className="header-text">{t("Total")}:</span>
                      {surveyList?.Answers?.[0]?.total_mark}
                    </Typography>
                    <span className="header-text">{t("Result")}:</span>
                    {Number(surveyList?.Answers?.[0]?.result) === 1 ? 'Pass' : 'Fail'}
                  </Typography>
                  <Typography>
                    <span className="header-text">{t("Percentage")}:</span>
                    {surveyList?.Answers?.[0]?.percentage}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={6} my={5}>
              <div className="no-data-image-div">
                <img src={corousel1} className="no-data-image" />
              </div>
            </Grid>
          )}
          <Grid xs={12} sm={12} md={6} lg={6} xl={6} py={5}>
            {combinedItemCount > 0? (
              <Box sx={{ width: '100%', maxWidth: '500px', bgcolor: 'background.paper' }}>
                <FixedSizeList height={400} width="100%" itemSize={100} itemCount={combinedItemCount} overscanCount={5}>
                  {renderRow}
                </FixedSizeList>
              </Box>
            ) : (
              <>
                <div className="no-data-image-div">
                  <img src={corousel1} className="no-data-image" />
                </div>
              </>
            )}
          </Grid>
          <Grid container item xs={12} py={5} justifyContent={'space-around'} spacing={2}>
      <Grid item xs={12} md={6}>
        <TableContainer component={Paper} sx={{ height: '100%' }}>
          <Table sx={{ border: 1, borderColor: 'primary.main', backgroundColor: '#f0f8ff', height: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography fontWeight={800}>{t('personal_details')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>{t('school_name')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.Answers?.[0]?.School?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('medium')}</Typography>
                </TableCell>
                <TableCell>{Number(medium) === 1 ? 'English' : 'Tamil'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('supplementary_applied')}</Typography>
                </TableCell>
                <TableCell>{Number(surveyList?.Answers?.[0]?.supplimentry) === 1 ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('career_guidance')}</Typography>
                </TableCell>
                <TableCell>{Number(surveyList?.Answers?.[0]?.career_guidance) === 1 ? 'Yes' : 'No'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('locality')}</Typography>
                </TableCell>
                <TableCell>{getLocalityName(surveyList?.locality_type)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('year')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.Answers?.[0]?.year ? surveyList?.Answers?.[0]?.year : 'Nill'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('address')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.address ? surveyList?.address : 'Nill'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('city')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.city ? surveyList?.city : 'Nill'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('religion')}</Typography>
                </TableCell>
                <TableCell>{religionStatus(Number(surveyList?.religion))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('community')}</Typography>
                </TableCell>
                <TableCell>{communityStatus(Number(surveyList?.community))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('financial_status')}</Typography>
                </TableCell>
                <TableCell>{getFinacialStatus(surveyList?.financial_type)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('college_name')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.Answers?.[0]?.college_name ? surveyList?.Answers?.[0]?.college_name : 'Nill'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('course_name')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.Answers?.[0]?.course_name ? surveyList?.Answers?.[0]?.course_name : 'Nill'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={6}>
        <TableContainer component={Paper} sx={{ height: '100%' }}>
          <Table sx={{ border: 1, borderColor: 'secondary.main', backgroundColor: '#f9f9f9', height: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography fontWeight={800}>{t('parent_details')}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>{t('parents_status')}</Typography>
                </TableCell>
                <TableCell>{getparentingStatus(surveyList?.parenting_status)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('parents_occupation')}</Typography>
                </TableCell>
                <TableCell>{getparentalOccuptaion(surveyList?.parents_occupation)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('parent_education')}</Typography>
                </TableCell>
                <TableCell>{getParentparentalEducation(surveyList?.Parents_education)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('parent_health_status')}</Typography>
                </TableCell>
                <TableCell>{getParentStatus(surveyList?.Answers?.[0]?.parent_status)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('mobile_number')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.phone ? surveyList?.phone : 'Nill'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('father_mobile_number')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.father_phone ? surveyList?.father_phone : 'Nill'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('mother_mobile_number')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.mother_phone ? surveyList?.mother_phone : 'Nill'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('guardian_mobile_number')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.guardian_number ? surveyList?.guardian_number : 'Nill'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('father_name')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.father_name ? surveyList?.father_name : 'Nill'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('mother_name')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.mother_name ? surveyList?.mother_name : 'Nill'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>{t('guardian_name')}</Typography>
                </TableCell>
                <TableCell>{surveyList?.guardian_name ? surveyList?.guardian_name : 'Nill'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
        </>
      )}
    </Grid>
  );
}

export default SurveyDetails;
