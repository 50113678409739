import { CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import theme from '../../common/theme';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../common/constants/Routes';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../common/ui/TableComponent';
import { dashboardCollegeListInprogress, selectDashboardCollegeList, selectDashboardCollegeListLoading } from '../../store/students/_students.slice';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../common/ui/CustomButton';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

const CollegeListScreen = (
	) => {
	const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location=useLocation();

   const locationYear=location?.state?.year
    const collegDetails = useSelector(selectDashboardCollegeList);
    const collegDetailsLoading = useSelector(selectDashboardCollegeListLoading);

    const collegeData = collegDetails?.data || [];
    console.log("collegeData",location?.state?.dataType)
    // const standardData = dashboardDetails?.data || [];
    useEffect(() => {
        const payload:any={
            courses_id:location?.state?.course_id,
            school_id:location?.state?.school_id,
            student_status:location?.state?.student_status,
            year:locationYear
        }
        dispatch(dashboardCollegeListInprogress(payload));
            
        }, [dispatch]);
    const columns = [
        { field: 'id', headerName: `Id`, disableColumnMenu: true,   width: 50, },
        { field: 'name', headerName: `${t('name')}`, disableColumnMenu: true, flex:1 , },
        
        { field: 'count', headerName: `${t('count')}`, disableColumnMenu: true,  width: 150 },
        {
            field: 'Action',
            headerName: `${t('Action')}`,
            disableColumnMenu: true,
            width: 150,
            renderCell: (params: any) => (
              
              <>
                     {console.log(params.row,"param")} 
                     {location?.state?.dataType==="courseApplied"?  <CustomButton text={`${t('view')}`} className="mx-3" color={'secondary'} onClick={() => 

navigate(APP_ROUTES.STUDENT_LIST, {state:{ standard_id: location?.state?.standard_id, course_id: location?.state?.course_id, year: 2024, student_status: 3,school_id:location?.state?.school_id,type:"streamBased",college_id:params?.row?.college_id}})} />:  <CustomButton text={`${t('view')}`} className="mx-3" color={'secondary'} onClick={() => 

    navigate(APP_ROUTES.STUDENT_LIST, {state:{ standard_id: location?.state?.standard_id, course_id: location?.state?.course_id, year: 2024, student_status: 1,school_id:location?.state?.school_id,type:"streamBased",college_id:params?.row?.college_id}})} />}
                  
           </>
            ),
          },
  
     
    ]
    const rows2 = collegeData && collegeData.map((school:any, index:any) => ({
        id: index + 1,
        name: school.name,
        count: school.count,
        college_id:school.id
      }));

    const handlePagination = (data: any) => {
        const payloads = {
            pageNo: data?.page + 1,
            pagination: true,
            year: null
        };
        // dispatch(schoolDetailsInProgress(payloads as any));
    };
    useEffect(() => {
	const payload:any={
        courses_id:location?.state?.course_id,
        school_id:location?.state?.school_id,
        student_status:location?.state?.student_status,
        year:locationYear
    }
    dispatch(dashboardCollegeListInprogress(payload));
		
	}, [dispatch]);
	return (
	<>
    <Grid item xs={12} my={5}>

    {collegDetailsLoading ?
             <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
             <CircularProgress />
           </Grid>
            :
            <>
            <Grid item xs={12}>
            <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="donation-header">
              <Typography fontSize={20} fontWeight={700}>
            {t("college_list")}
              </Typography>
    
              <>
                <CustomButton
                  type="button"
                  text= {t("GO_BACK")}
                  startIcon={SubdirectoryArrowRightIcon}
                  color={'primary'}
                  className="p-8 secondary-button"
                  onClick={() => {
                    navigate(-1);   
                  }}
                />
              </>
            </Grid>
          </Grid>
    <TableComponent
            columns={columns}
            rows={rows2}
            pagination={false}
            hideFooterPagination={true}
        />
        </>}
                   
                </Grid></>
	);
};

export default CollegeListScreen;
