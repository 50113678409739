import { Grid, Typography, Card, CardContent, CircularProgress, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import person from '../../common/icons/image-card-1.png';
import person2 from '../../common/icons/image-card-2.png';
import person3 from '../../common/icons/image-card-3.png';
import person4 from '../../common/icons/image-card-4.png';
import { BarChart } from '@mui/x-charts/BarChart';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PieChart } from '@mui/x-charts/PieChart';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import theme from '../../common/theme';
import { useTranslation } from 'react-i18next';
import { dashboardListInProgress, selectDashboardDetailsList, selectDashboardDetailsListLoading } from '../../store/survey/_survey.slice';
import { yearsList } from '../../common/constants/FormItems';
import './dashboard.scss';
import { APP_ROUTES } from '../../common/constants/Routes';
import { Link } from 'react-router-dom';

const DashboardScreen: React.FC = () => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3, // Show 3 slides at a time
		slidesToScroll: 1,
		autoplay: true, // Enable automatic sliding
        autoplaySpeed: 3000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	const { t } = useTranslation();
	const currentYear = new Date().getFullYear();	
	const { control, watch, handleSubmit, setValue } = useForm({
		defaultValues: {
			year: currentYear
		}
	});
	const dashboardLoading = useSelector(selectDashboardDetailsListLoading);
	const dashboardDetails = useSelector(selectDashboardDetailsList);
	const dispatch = useDispatch();
	const year = watch('year');

	useEffect(() => {
		const year = watch('year');
		dispatch(dashboardListInProgress({ year }as any));
	}, [dispatch, watch('year')]);
	const courses :any= [
        { id: "1", name: "Bachelor of Engineering" },
        { id: "2", name: "Arts & Science" },
        { id: "3", name: "Law" },
        { id: "4", name: "Agriculture" },
        { id: "5", name: "Medical" },
        { id: "6", name: "Nursing" },
        { id: "7", name: "Journalism" },
        { id: "8", name: "Others" }
    ];
	const standardData = dashboardDetails?.data || [];
	console.log(standardData,"standardData")
	const courseColors :any= {
        "Bachelor of Engineering": '#FFECB3',
        "Arts & Science": '#E8EAF6',
        "Law": '#FFEBEE',
        "Agriculture": '#C8E6C9',
        "Medical": '#FFE0B2',
        "Nursing": '#ADD8E6',
        "Journalism": '#F8BBD0',
        "Others": '#E0F7FA'
    };

	const standard12 = standardData.find((item: any) => item.standardName === "12") || {};
	const standard10 = standardData.find((item: any) => item.standardName === "10") || {};

	const data = {
		standard_12: {
			passCount: standard12.passCount || 0,
			failCount: Math.abs(standard12.overAllFailCount) || 0,
		},
		standard_10: {
			passCount: standard10.passCount || 0,
			failCount: Math.abs(standard10.overAllFailCount) || 0,
		},
	};

	const categories = ['HSC', 'SSLC'];
	const passedData = [data.standard_12.passCount, data.standard_10.passCount];
	const failedData = [data.standard_12.failCount, data.standard_10.failCount];
	const colors = ['#4CAF50', '#F44336']; // Green for passed, Red for failed
	const overallStudent = Number(dashboardDetails?.total_student)
	let totalFailedStudents;
if (standardData[0]?.overAllFailCount !== undefined && standardData[1]?.overAllFailCount !== undefined) {
    totalFailedStudents = Number(standardData[0].overAllFailCount) + Number(standardData[1].overAllFailCount);
} else if (standardData[0]?.overAllFailCount !== undefined) {
    totalFailedStudents = Number(standardData[0].overAllFailCount);
} else if (standardData[1]?.overAllFailCount !== undefined) {
    totalFailedStudents = Number(standardData[1].overAllFailCount);
} else {
    totalFailedStudents = 0; // or any default value you want to use when both values are unavailable
}

	console.log("Number",Number(standardData[0]?.overAllFailCount)+Number(standardData[1]?.overAllFailCount),standardData)
	const percentage = Math.round(totalFailedStudents / overallStudent * 100);

	const totalStudents = overallStudent;

	const surveyCompleted = Number(dashboardDetails?.total_student);
	const surveyPending = Number(dashboardDetails?.serverNotCompetedCount);
	const surveyCompletedPercentage = Math.round(standardData[0]?.totalNumberOfStudent / totalStudents * 100);
	const surveyPendingPercentage = Math.round(standardData[1]?.totalNumberOfStudent / totalStudents * 100);

	const series = [
		{
			color: colors[0],
			data: passedData,
		},
		{
			color: colors[1],
			data: failedData,
		},
	];

	return (
		<>
			{dashboardLoading  ?

				<Grid container display={"flex"} justifyContent={"center"} alignItems={"center"}>
					<CircularProgress />

				</Grid>
				:

				<Grid container>
					<Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
						<InputLabel>{t('Year')}:</InputLabel>
						<Controller
							control={control}
							name="year"
							render={({ field }) => (
								<Select
									{...field}
									fullWidth
									value={field.value}
									onChange={(e) => {
										field.onChange(e.target.value);
									}}
								>
									{yearsList.map((year) => (
										<MenuItem key={year.id} value={year.id}>
											{year.name}
										</MenuItem>
									))}
								</Select>
							)}
							rules={{ required: "Year is required" }}
						/>
					</Grid>
		 
		<Grid item xs={12} className="dashboard-grid" mb={5}>
			<Slider {...settings}>
				<Grid item p={2}>
					<Card className='card-grid'>
						<CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Grid>
										<Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
													{t('TOTAL_STUDENTS')}
												</Typography>
												<Typography variant="h6">{totalStudents}</Typography>
											</Stack>
										</Stack>
									</Grid>
									<Grid>
										<img src={person3} className='profile-img-3' />
									</Grid>
								</Grid>
								<div>
									<LinearProgress value={100} variant="determinate" color='success' />
								</div>
							</Stack>
						</CardContent>
					</Card>
				</Grid>

				<Grid item p={2}>
					<Card className='card-grid'>
						<CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
									className="dashboard-grid"
								>
									<Grid>
										<Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
													{t('hsc_students')}
												</Typography>
												<Typography variant="h6" className='card-heading-1'>{standardData[0]?.totalNumberOfStudent?standardData[0]?.totalNumberOfStudent:0}</Typography>
											</Stack>
										</Stack>
									</Grid>
									<Grid>
										<img src={person} className='profile-img-1' />
									</Grid>
								</Grid>
								<div>
									<LinearProgress value={surveyCompletedPercentage} variant="determinate" color='primary' />
								</div>
							</Stack>
						</CardContent>
					</Card>
				</Grid>

				<Grid item p={2}>
					<Card className='card-grid'>
						<CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
									className="dashboard-grid"
								>
									<Grid>
										<Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
													{t('sslc_students')}
												</Typography>
												<Typography variant="h6">{standardData[1]?.totalNumberOfStudent?standardData[1]?.totalNumberOfStudent:0}</Typography>
											</Stack>
										</Stack>
									</Grid>
									<Grid>
										<img src={person2} className='profile-img-2' />
									</Grid>
								</Grid>
								<div>
									<LinearProgress value={surveyPendingPercentage} variant="determinate" color='error' />
								</div>
							</Stack>
						</CardContent>
					</Card>
				</Grid>

				<Grid item p={2}>
					<Card className='card-grid'>
						<CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Grid>
										<Stack direction="row" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-1'>
													{t("failed_students")}<br />
												
												</Typography>
												<Typography variant="h6">{totalFailedStudents}</Typography>
											</Stack>
										</Stack>
									</Grid>
									<Grid>
										<img src={person4} className='profile-img-4' />
									</Grid>
								</Grid>
								<div>
									<LinearProgress value={percentage} variant="determinate" color='warning' />
								</div>
							</Stack>
						</CardContent>
					</Card>
				</Grid>
			</Slider>
		</Grid>
					{/* Pass Fail comparison */}
					<Grid
						item
						xs={12}
						container
						display="flex"
						justifyContent="space-between"
						className="dashboard-grid"
						alignItems={"center"}
					>
						<Grid xs={12} sm={12} md={12} lg={5} xl={6} p={2}>
							<Typography variant="h6" gutterBottom className='card-heading-1'>
								{t("PASS_FAIL_COMPARITION")}:
							</Typography>
							<BarChart
								xAxis={[{ scaleType: 'band', data: categories }]}
								series={series}
							
								height={300}
							/>
						</Grid>
						<Grid xs={12} sm={12} md={12} lg={7} xl={6}>
							<Grid container spacing={3} >
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<Typography variant="h6" gutterBottom className='card-heading-1'>
									{t("12")}:
									</Typography>
									<PieChart
										series={[
											{
												data: [{ value: data.standard_12.passCount, label: `${t("Pass")}`}, { value: data.standard_12.failCount, label: `${t("Fail")}` }],
												highlightScope: { faded: 'global', highlighted: 'item' },
												faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
												
											}
										]}
										slotProps={{
											legend: {  direction: 'column',
											position: { vertical: 'top', horizontal: 'right' },
											itemMarkWidth: 9,
										  itemMarkHeight: 9,
										  markGap: 6,
										  itemGap: 2,
											
											}
										  }}
										height={300}
										
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
									<Typography variant="h6" gutterBottom className='card-heading-1'>
									{t("10")}:
									</Typography>
									<PieChart
										series={[
											{
												data: [{ value: data.standard_10.passCount, label:  `${t("Pass")}`}, { value: data.standard_10.failCount, label:  `${t("Fail")}` }],
												highlightScope: { faded: 'global', highlighted: 'item' },
												faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
											}
										]}
										slotProps={{
											legend: {  direction: 'column',
											position: { vertical: 'top', horizontal: 'right' },
											itemMarkWidth: 9,
										  itemMarkHeight: 9,
										  markGap: 6,
										  itemGap: 2,
											
											}
										  }}
										height={300}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
		
			{/* <Typography className='school-wise-details-text' mb={2}>{t("sslc_students_status")}</Typography> */}
        {/* <Grid item xs={12} container display="flex" className="dashboard-grid"> */}
		
						{/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="btn">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",year:year,student_status:3}}>
							<Card className='card-grid' style={{ backgroundColor: '#FADBD8' }}>
								<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
														{t("higher_studies_applied")}
														</Typography>
														<Typography variant="h6">{standardData[1]?.higherStudieAppliedCounts?standardData[1]?.higherStudieAppliedCounts:0} </Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link>
						</Grid> */}
						{/* Card for Survey Completed */}
						{/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="btn">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",year:year,student_status:1}}>						
							<Card className='card-grid' style={{ backgroundColor: '#D1F2EB' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
														{t("higher_studies_joined")}
														</Typography>
														<Typography variant="h6">{standardData[1]?.higherStudieJoinCounts?standardData[1]?.higherStudieJoinCounts:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card></Link>
						</Grid> */}
						{/* Card for Survey Pending */}
						{/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="btn">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",year:year,student_status:2}}>
							<Card className='card-grid' style={{ backgroundColor: '#C5E1A5' }}>
              <CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
														{t("higher_studies_not_joined")}
														</Typography>
														<Typography variant="h6">{standardData[1]?.higherStudieNotJoinCounts?standardData[1]?.higherStudieNotJoinCounts:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card>
							</Link>
						</Grid> */}
						{/* Card for Completed Percentage */}
						{/* <Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="btn">
						<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"ab2cc5ed-52d9-45a1-bb38-5d830354fd20",year:year,student_status:4}}>
							<Card className='card-grid' style={{ backgroundColor: '#E1D5E7' }}>
							<CardContent>
									<Stack spacing={2}>
										<Grid
											item
											xs={12}
											container
											display="flex"
											justifyContent="space-between"
											alignItems={"center"}
										>
											<Grid>
												<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
													<Stack spacing={1}>
														<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
														{t("skill_based_course_joined")}
														</Typography>
														<Typography variant="h6">{standardData[1]?.skillBasedCourseJoinCounts?standardData[1]?.skillBasedCourseJoinCounts:0}</Typography>
													</Stack>
												</Stack>
											</Grid>
											
										</Grid>
										
									</Stack>
								</CardContent>
							</Card></Link>
						</Grid> */}
	{/* </Grid>  */}
	<Grid item xs={12} container display="flex" className="dashboard-grid">
		<Grid item xs={6}>
	<Typography className='school-wise-details-text'  mb={2}>{t("12")}</Typography>
					<Grid item xs={12} container display="flex" className="dashboard-grid">
				
				<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2} className="btn">
	  <Link to={APP_ROUTES.SCHOOL}>
					<Card className='card-grid'style={{ backgroundColor: '#DCEDC8' }}>
						<CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Grid>
										<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
												{t("hsc_passed")}
												</Typography>
												<Typography variant="h6">{standardData[0]?.passCount?standardData[0]?.passCount:0}</Typography>
											</Stack>
										</Stack>
									</Grid>
									
								</Grid>
								
							</Stack>
						</CardContent>
					</Card>
	  </Link>
				</Grid>
				{/* Card for Survey Completed */}
				<Grid item xs={12} sm={6} md={6} lg={6} xl={6}  p={2} className="btn">
<Link to={APP_ROUTES.SUBJECT_WISE_LIST_SCREEN}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",year:year,student_status:1,type:"dashboard"}}>
					<Card className='card-grid'style={{ backgroundColor: '#D7CCC8' }}>
					<CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Grid>
										<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
												{t("hsc_failed")}
												</Typography>
												<Typography variant="h6">{standardData[0]?.overAllFailCount?standardData[0]?.overAllFailCount:0}</Typography>
											</Stack>
										</Stack>
									</Grid>
									
								</Grid>
								
							</Stack>
						</CardContent>
					</Card></Link>
				</Grid>
				</Grid>
				</Grid>
				<Grid item xs={6}>
				{/* Card for Survey Pending */}
				<Typography className='school-wise-details-text'  mb={2}>{t("10")}</Typography>
				<Grid item xs={12} container display="flex" className="dashboard-grid">
				<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2} className="btn">
	<Link to={APP_ROUTES.SCHOOL}>              
					<Card className='card-grid' style={{ backgroundColor: '#FFCDD2' }}>
	  <CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Grid>
										<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
												{t("sslc_passed")}
												</Typography>
												<Typography variant="h6">{standardData[1]?.passCount?standardData[1]?.passCount:0}</Typography>
											</Stack>
										</Stack>
									</Grid>
									
								</Grid>
								
							</Stack>
						</CardContent>
					</Card></Link>
				</Grid>
				{/* Card for Completed Percentage */}
				<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2} className="btn">
	<Link to={APP_ROUTES.SUBJECT_WISE_LIST_SCREEN}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",year:year,student_status:4}}>
					<Card className='card-grid' style={{ backgroundColor: '#E1BEE7'}}>
					<CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Grid>
										<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
												{t("sslc_failed")}
												</Typography>
												<Typography variant="h6">{standardData[1]?.overAllFailCount?standardData[1]?.overAllFailCount:0}</Typography>
											</Stack>
										</Stack>
									</Grid>
									
								</Grid>
								
							</Stack>
						</CardContent>
					</Card>
	  </Link>
				</Grid>
				</Grid>
				</Grid>
				</Grid>
		
					<Typography className='school-wise-details-text'  mb={2}>{t("hsc_students_status")}</Typography>
					<Grid item xs={12} container display="flex" className="dashboard-grid">
				
				<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="btn">
	  <Link to={APP_ROUTES.SCHOOL}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",dataType:"higher_studies_applied",year:year,student_status:3,type:"dashboard",higer_studies_applied_data:standardData[0]?.higherStudieAppliedCounts?standardData[0]?.higherStudieAppliedCounts:0}}>
					<Card className='card-grid'style={{ backgroundColor: '#FFFDE7' }}>
						<CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Grid>
										<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
												{t("higher_studies_applied")}
												</Typography>
												<Typography variant="h6">{standardData[0]?.higherStudieAppliedCounts?standardData[0]?.higherStudieAppliedCounts:0}</Typography>
											</Stack>
										</Stack>
									</Grid>
									
								</Grid>
								
							</Stack>
						</CardContent>
					</Card>
	  </Link>
				</Grid>
				{/* Card for Survey Completed */}
				<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="btn">
<Link to={APP_ROUTES.SCHOOL}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",year:year,dataType:"higher_studies_joined",student_status:1,type:"dashboard",higer_studies_joined_data:standardData[0]?.higherStudieJoinCounts?standardData[0]?.higherStudieJoinCounts:0}}>
					<Card className='card-grid'style={{ backgroundColor: '#FCE4EC' }}>
					<CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Grid>
										<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
												{t("higher_studies_joined")}
												</Typography>
												<Typography variant="h6">{standardData[0]?.higherStudieJoinCounts?standardData[0]?.higherStudieJoinCounts:0}</Typography>
											</Stack>
										</Stack>
									</Grid>
									
								</Grid>
								
							</Stack>
						</CardContent>
					</Card></Link>
				</Grid>
				{/* Card for Survey Pending */}
				<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="btn">
	<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",year:year,student_status:2}}>              
					<Card className='card-grid' style={{ backgroundColor: '#ECEFF1' }}>
	  <CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Grid>
										<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
												{t("higher_studies_not_joined")}
												</Typography>
												<Typography variant="h6">{standardData[0]?.higherStudieNotJoinCounts?standardData[0]?.higherStudieNotJoinCounts:0}</Typography>
											</Stack>
										</Stack>
									</Grid>
									
								</Grid>
								
							</Stack>
						</CardContent>
					</Card></Link>
				</Grid>
				{/* Card for Completed Percentage */}
				<Grid item xs={12} sm={6} md={6} lg={3} xl={3} p={2} className="btn">
	<Link to={APP_ROUTES.STUDENT_LIST}state={{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",year:year,student_status:4}}>
					<Card className='card-grid' style={{ backgroundColor: '#E1BEE7'}}>
					<CardContent>
							<Stack spacing={2}>
								<Grid
									item
									xs={12}
									container
									display="flex"
									justifyContent="space-between"
									alignItems={"center"}
								>
									<Grid>
										<Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
											<Stack spacing={1}>
												<Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
												{t("skill_based_course_joined")}
												</Typography>
												<Typography variant="h6">{standardData[0]?.skillBasedCourseJoinCounts?standardData[0]?.skillBasedCourseJoinCounts:0}</Typography>
											</Stack>
										</Stack>
									</Grid>
									
								</Grid>
								
							</Stack>
						</CardContent>
					</Card>
	  </Link>
				</Grid>
			</Grid>
			<Typography className='school-wise-details-text' mb={2}>{t("hsc_students_stream_based_counts")}</Typography>
			<Grid item xs={12} container display="flex" className="dashboard-grid">
                {dashboardDetails?.courseJoinCount?.map((course:any, index:any) => {
                    const courseName = courses?.find((c: any) => c?.id === course?.course_id)?.name || "Others";
                    return (
                        <Grid item xs={12} sm={6} md={6} lg={2.4} xl={2.4} p={2} className="btn" key={index}>
                            <Link to={APP_ROUTES.STUDENT_LIST} state={{ standard_id: course.standard_id, course_id: course.course_id, year: year, student_status: 1 ,type:"streamBased"}}>
                                <Card className='card-grid' style={{ backgroundColor: courseColors[courseName] }}>
                                    <CardContent>
                                        <Stack spacing={2}>
                                            <Grid item xs={12} container display="flex" justifyContent="space-between" alignItems="center">
                                                <Grid>
                                                    <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
                                                        <Stack spacing={1}>
                                                            <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
                                                                {t(courseName.replace(/\s+/g, '_').toLowerCase())}
                                                            </Typography>
                                                            <Typography variant="h6">{course.count}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    );
                })}
            </Grid>
		
				</Grid>
			}
		</>
	);
}

export default DashboardScreen;
