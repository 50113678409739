import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../common/ui/TableComponent';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../../common/constants/Routes';
import QuestionHeader from './QuestionHeader';
import { useDispatch, useSelector } from 'react-redux';
import { csvquestionsListInProgress, deletequestionsInProgress, questionsDetailsInProgress, selectCsvquestionsDetailsList, selectquestionsDetailsList, selectquestionsDetailsListLoading } from '../../../store/questions/_questions.slice';
import { CircularProgress } from '@material-ui/core';
import { answerType } from '../../../common/utils';

function Questions() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const questionList = useSelector(selectquestionsDetailsList);
    const csvquestionsList = useSelector(selectCsvquestionsDetailsList);
    const schoolDetailsLoading = useSelector(selectquestionsDetailsListLoading);
    const qusetionDetails = questionList?.data || [];
    const qusetionTotalRecords = questionList?.totalRecords || 0;
    const csvQuestionDetails= csvquestionsList?.data || [];
    console.log(csvQuestionDetails, "csvQuestionDetails");
    const [open, setOpen] = useState(false);
    const [id, setId] = useState('');

    const handleDelete = () => {
        setOpen(true);
    };

    const handleDeleteAndSetId = (id:any) => {
        const handleSuccess=()=>{
            const payloads:any = {
                pageNo: 1,
                pagination: true,
            }
            dispatch(questionsDetailsInProgress(payloads));
        }
       
        setId(id);
        const payload:any={
            id:id,
            handleSuccess
        }
    dispatch(deletequestionsInProgress(payload))
    };

    useEffect(() => {
        const payloads:any = {
            pageNo: 1,
            pagination: true,
        }
        const payload2:any = {
            pageNo: 1,
            pagination: false,
        }
        dispatch(questionsDetailsInProgress(payloads));
        dispatch(csvquestionsListInProgress(payload2));
    }, [dispatch]);

    const handlePagination = (data:any) => {
        const payloads :any= {
            pageNo: data?.page + 1,
            pagination: true,
        };
        dispatch(questionsDetailsInProgress(payloads));
    };

    const columns = [
        {
            field: 'question',
            headerName: `${t('Questions')}`,
            disableColumnMenu: true,
            flex: 1,
        },
        // {
        //     field: 'standard',
        //     headerName: `${t('Standard')}`,
        //     disableColumnMenu: true,
        //     flex: 1,
        // },
        {
            field: 'year',
            headerName: `${t('year')}`,
            disableColumnMenu: true,
            flex: 1,
        },
        {
            field: 'answer_type',
            headerName: `${t('Question_Type')}`,
            description: 'This column has a value getter and is not sortable.',
            disableColumnMenu: true,
            flex: 1,
            render: (data:any) => {
                console.log('data', data);
                return answerType?.find((val :any) => val.value === data).label;
            },
        },
        {
            field: 'Action',
            headerName: `${t('Action')}`,
            description: 'This column has a value getter and is not sortable.',
            disableColumnMenu: true,
            width: 200,
            renderCell: (params:any) => (
                <>
                    <CustomButton
                        text={`${t('EDIT')}`}
                        className="mx-5"
                        color={'secondary'}
                        onClick={() => {
                            navigate(APP_ROUTES.QUESTIONFORM, {
                                state: {
                                    data: params.row,
                                    type: 'edit',
                                },
                            });
                        }}
                    />
                    <CustomButton
                        text={`${t('DELETE')}`}
                        className="mx-3 color-gray"
                        onClick={() => handleDeleteAndSetId(params.row.id)}
                    />
                </>
            ),
        },
    ];

    const rows = qusetionDetails.map((student:any, index:any) => ({
        id: student.id,
        standard_id: student.standard_id,
        standard: student.standard,
        year: student.year,
        question: student.questions,
        answer_type: Number(student.questions_type) === 1 ? "Pass" : "Fail",
        full_details: student // Pass the entire student object
    }));
    const rows2 = qusetionDetails.map((student:any, index:any) => ({
        id: student.id,
        standard_id: student.standard_id,
        standard: student.standard,
        year: student.year,
        question: student.questions,
        answer_type: Number(student.questions_type) === 1 ? "Pass" : "Fail",
        full_details: student // Pass the entire student object
    }));

    return (
        <Grid container>
            <Grid item xs={12}>
                <QuestionHeader />
            </Grid>
            {schoolDetailsLoading ?
                <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress />
                </Grid> :
                <Grid item xs={12} my={5}>
                    <TableComponent
                        totalRecords={qusetionTotalRecords}
                        columns={columns}
                        rows={rows}
                        pagination={false}
                        csvData={rows2}
                        type={"question"}
                        hideFooterPagination={false}
                        handlePagination={handlePagination}
                        paginationModel={{
                            page: Number(questionList?.currentPage) - 1 || 0,
                            pageSize: Number(questionList?.pagesize) || 5,
                        }}
                    />
                </Grid>}
        </Grid>
    );
}

export default Questions;
