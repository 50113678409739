import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { Controller, get, useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import MailIcon from '@mui/icons-material/Mail';

import { useTranslation } from 'react-i18next';

import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { APP_ROUTES } from '../../common/constants/Routes';
import LabelWithIcon from '../../common/ui/LabelWithIcon';
import theme from '../../common/theme';
import FormInput from '../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../common/ui/CustomButton';
import { useDispatch, useSelector } from 'react-redux';

import { setAlertInfo } from '../../common/Common.slice';
import CircularIndeterminate from '../../common/ui/CircularLoading';
import { changePasswordRequest } from '../../store/auth/_auth.slice';

const ChangePassword = ({ editData }: { editData?: any }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const [passwordMatch, setPasswordMatch] = useState(true);
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		trigger,
		getValues,

		resetField,
	} = useForm({
		defaultValues: {
			email: '',
			newPassword: '',
			confirm_password: '',
			password:''
		},
	});

	// const handleReset = () => {
	// 	setValue('current_password', '');
	// 	setValue('new_password', '');
	// 	setValue('confirm_password', '');
	// };
	const email=localStorage.getItem('email');
	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const newPassword = getValues('newPassword');
		if (name === 'confirmPassword' && newPassword !== value) {
			setPasswordMatch(false);
		} else {
			setPasswordMatch(true);
		}
	};

	const onSubmit = (data: any) => {
		console.log("data",data)
		data.email=email
		const handleSuccess=()=>{
			navigate(APP_ROUTES.DASHBOARD)
		}
		const payload={
			...data,
			handleSuccess
		}
		dispatch(changePasswordRequest(payload as any))
	};
	const validatePassword = (value: any) => {
		const currentPassword = getValues('password');
		if (value === currentPassword) {
			return t('NEW_PASSWORD_CANNOT_BE_SAME_AS_CURRENT_PASSWORD');
		}
		return true;
	};
	const validateConfirmPassword = (value: any) => {
		const newPassword = getValues('newPassword');
		if (value != newPassword) {
			return t('NEW_PASSWORD_AND CONFIRM_PASSWORD_SHOULD_BE_SAME');
		}
		return true;
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid
				container
				className="mt-30"
				justifyContent={'center'}
				alignItems={'center'}
			>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					<Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
						<Typography
							fontSize={20}
							fontWeight={600}
							color={theme.palette.info.dark}
						>
							{t('CHANGE_PASSWORD')}
						</Typography>
						<CustomButton
							type="button"
							text={t('GO_BACK')}
							startIcon={SubdirectoryArrowRightIcon}
							color={'primary'}
							className="p-8 color-gray"
							onClick={() => {
								navigate(APP_ROUTES.DASHBOARD);
							}}
						/>
					</Grid>

					{loading ? (
						<Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
							<CircularIndeterminate />
						</Grid>
					) : (
						<>
							{' '}
							<Grid my={1}>
								<LabelWithIcon
									label={t('CURRENT_PASSWORD')}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 18,
									}}
									justifyContent="flex-start"
								/>
							</Grid>
							<Grid item xs={12} my={1}>
								<FormInput
									placeholder={`${t('CURRENT_PASSWORD')}...`}
									className="username-input"
									name="password"
									type="password"
									control={control}
									rules={{
										required: t('PLEASE_ENTER_CURRENT_PASSWORD'),
									}}
									inputProps={{
										className: 'text-center',
										...register('password'),
									}}
									errors={errors}
								/>
							</Grid>
							<Grid my={1}>
								<LabelWithIcon
									label={t('NEW_PASSWORD')}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 18,
									}}
									justifyContent="flex-start"
								/>
							</Grid>
							<Grid item my={1}>
								<FormInput
									placeholder={`${t('NEW_PASSWORD')}...`}
									className="username-input"
									name="newPassword"
									type="password"
									control={control}
									rules={{
										required: t('PLEASE_ENTER_NEW_PASSWORD'),
										validate: validatePassword,
									}}
									inputProps={{
										className: 'text-center',
										...register('newPassword'),
										onChange: handleChange,
									}}
									errors={errors}
								/>
							</Grid>
							<Grid my={1}>
								<LabelWithIcon
									label={t('CONFIRM_PASSWORD')}
									labelProps={{
										color: theme.palette.common.black,
										fontSize: 18,
									}}
									justifyContent="flex-start"
								/>
							</Grid>
							<Grid item my={1}>
								<FormInput
									placeholder={`${t('CONFIRM_PASSWORD')}...`}
									className="username-input"
									name="confirm_password"
									type="password"
									control={control}
									rules={{
										required: t('PLEASE_CONFORM_YOUR_PASSWORD'),
										validate: validateConfirmPassword,
									}}
									inputProps={{
										className: 'text-center',
										...register('confirm_password'),
										onChange: handleChange,
									}}
									errors={errors}
								/>
								{!passwordMatch && (
							<Typography>Passwords does not matching</Typography>
						)}
							</Grid>
							<Grid justifyContent="center" display="flex" my={5}>
								<CustomButton
									type="submit"
									text={t('SUBMIT')}
									width={200}
									disabled={!passwordMatch}
									color="warning"
									className="p-10"
								/>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>{' '}
		</form>
	);
};

export default ChangePassword;
