import { Grid, InputLabel, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import SvgConverter from '../../../common/SvgConverter';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { useForm } from 'react-hook-form';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useLocation, useNavigate } from 'react-router-dom';

import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import UserProfileImage from '../../../common/UserProfileImage';
import Person2Icon from '../../../common/icons/profile-png-5.png';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { genderType } from '../../../common/constants/FormItems';
import { updateProfileProgress } from '../../../store/students/_students.slice';

const EditProfile = ({
  text,
  handleSubmit,
  register,
  reset,
  errors,
  control,
  setValue,
  watch,
  trigger
}: {
  text: any;
  handleSubmit: any;
  register: any;
  reset: any;
  errors: any;
  control: any;
  setValue: any;
  watch: any;
  trigger?:any
}) => {
const{t}=useTranslation()
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch=useDispatch()
  const  {user}= useSelector((state:any) => state);
 
  
  const userData = user?.getProfile
  ?.data?.data || [];   
  
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {

		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};
  const Id=localStorage.getItem("id")
  const onSubmit = (data: any) => {
   
   data.id=Id
    const handleSuccess=()=>{
      navigate(APP_ROUTES.DASHBOARD);
      localStorage.setItem("email",data?.email)
    }
   const payload:any={
    data,
    handleSuccess
   }
   dispatch(updateProfileProgress(payload))
    
  };
  
  return (
    <Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          display='flex'
          justifyContent='center'
          // className='login-form'
        >
          <Grid container xs={12} p={2}>
            <Grid item xs={6} alignItems={'center'} display={'flex'}>
              <Typography
                fontSize={20}
                fontWeight={600}
                color={theme.palette.info.dark}
              >
                {text}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              alignItems={'center'}
              justifyContent={'flex-end'}
              display={'flex'}
            >
             
            </Grid>
          </Grid>
     
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <InputLabel>{t('First_Name')}:</InputLabel>
            <FormInput
              name='first_name'
              type='text'
              placeholder={`${t('First_Name')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: `${t('PLEASE_ENTER_FIRST_NAME')}`,
                },
              }}
              inputProps={{
                ...register('first_name', {}),
              }}
              errors={errors}
             
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <InputLabel>{t('Last_Name')}:</InputLabel>
            <FormInput
              name='last_name'
              type='text'
              placeholder={`${t('Last_Name')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message:`${t('PLEASE_ENTER_LAST_NAME')}`,
                },
              }}
              inputProps={{
                ...register('last_name', {}),
              }}
              errors={errors}
              defaultValue={userData?.
                lastName||""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <InputLabel>{t('EMAIL')}:</InputLabel>
            <FormInput
              name='email'
              type='text'
              placeholder={`${t('EMAIL')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                    value: true,
                    message: `${t('PLEASE_ENTER_EMAIL')}`,
                },
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: `${t('INVALID_EMAIL_ADDRESS')}`,
                },
            }}
              inputProps={{
                ...register('email', {}),
              }}
              errors={errors}
              defaultValue={userData?.
                email||""}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <InputLabel>{t('MOBILE_NUMBER')}:</InputLabel>
            <FormInput
              name='phone'
              type='text'
              placeholder={`${t('MOBILE_NUMBER')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                    value: true,
                    message: `${t('PLEASE_ENTER_MOBILE_NUMBER')}`,
                },
                maxLength: {
                    value: 10,
                    message: `${t('MOBILE_NUMBER_SHOULD_NOT_EXCEED')}`,
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: `${t('ONLY_NUMERIC_CHARACTERS_ALLOWED')}`,
              },
              minLength: {
                value: 10,
                message: `${t('MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG')}`,
            },
            }}
              inputProps={{
                ...register('phone', {}),
              }}
              errors={errors}
              defaultValue={userData?.
                phone||""}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <InputLabel>Email:</InputLabel>
          <FormInput
              name='email'
              type='text'
              placeholder={`Email...`}
              control={control}
              className='input-from'
              rules={{
				pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: `Invalid Email Address`,
                },
               
            }}
              inputProps={{
                ...register('email', {}),
                onChange: handleEmailChange 
              }}
              errors={errors}
            />
            </Grid> */}
          {/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
            <LabelWithIcon
              label={`${t('Gender')}`}
              justifyContent='flex-start'
              className='mb-6 '
            />
            <CustomSelectBox
              options={genderType}
              ClassName='form-select'
              placeholder={`${t('Gender')}...`}
              inputProps={register('gender_type', {
                required: {
                  value: true,
                  message: `${t('Select_Gender')}`,
                },
              })}
              name={'gender_type'}
              error={errors}
               
            />
          </Grid> */}
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <InputLabel>{t('DOB')}:</InputLabel>
            
            <FormInput
              name='DOB'
              type='date'
              placeholder={`${t('DOB')}...`}
              control={control}
              className='input-from'
              rules={{
                required: {
                  value: true,
                  message: `${t('PLEASE_ENTER_DOB')}`,
                },
              }}
              inputProps={{
                ...register('DOB', {}),
              }}
              errors={errors}
            
            />
          </Grid>
       
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
          <InputLabel>{t('ADDRESS')}:</InputLabel>
            <FormInput
              hideCountButton
              multipleLine
              name='address'
              type='text'
              placeholder={`${t('ADDRESS')}...`}
              control={control}
              className='input-from-text'
            
              inputProps={{
                ...register('address', {}),
              }}
              errors={errors}
              defaultValue={userData?.address||""}
            />
          </Grid>
          <Grid item xs={12} justifyContent='flex-end' display={'flex'} my={5}>
            <CustomButton
              type='submit'
              // startIcon={AddIcon}
              text={`${t('Update_Profile')}`}
              color={'warning'}
              className='p-12'
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EditProfile;
