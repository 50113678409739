import { Grid, Typography } from '@mui/material';
import React from 'react';
// import CustomButton from '../../../common/ui/CustomButton';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
// import { APP_ROUTES } from '../../../common/constants/Routes';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';
import CustomButton from '../../../common/ui/CustomButton';
import { APP_ROUTES } from '../../../common/constants/Routes';

function QuestionHeader() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const handleNavigate = () => {};

	return (
		<Grid
			display={'flex'}
			justifyContent={'space-between'}
			alignItems={'center'}
			className="donation-header"
		>
			<Typography fontSize={20} fontWeight={700}>
				{t('Make_A_QUESTONS')}
			</Typography>

			<>
				<CustomButton
					type="button"
					text={`${t('Add_Questions')}`}
					startIcon={AddIcon}
					className=" px-10 py-5 my-5"
					fontSize={14}
					onClick={() => {
						navigate(APP_ROUTES.QUESTIONFORM);
					}}
					color={'secondary'}
				/>
			</>
		</Grid>
	);
}

export default QuestionHeader;
