import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../../common/ui/CustomButton';
import TableComponent from '../../common/ui/TableComponent';
import { dashboardCollegeListInprogress, selectsubjectWiseList, selectsubjectWiseListLoading, subjectWiseListInprogress } from '../../store/students/_students.slice';
import { APP_ROUTES } from '../../common/constants/Routes';
import { CircularProgress } from '@material-ui/core';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

const SubjectListScreen = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const locationYear = location?.state?.year;
    const subjectWiseDetails = useSelector(selectsubjectWiseList);
    const subjectWiseDetailsLoading = useSelector(selectsubjectWiseListLoading);
    const subjectData = subjectWiseDetails?.data || [];
    console.log("subjectData",subjectData)

    useEffect(() => {
        const payload :any= {
            standard_id: location?.state?.standard_id,
            year: locationYear
        };
        dispatch(subjectWiseListInprogress(payload));
    }, [dispatch, location?.state?.standard_id, locationYear]);

    const columns = [
        { field: 'id', headerName: 'Id', disableColumnMenu: true, width: 50 },
        { field: 'subject', headerName: t('SUBJECT'), disableColumnMenu: true, flex: 1 },
        { field: 'pass_count', headerName: t('pass_count'), disableColumnMenu: true, width: 100 },
        { field: 'fail_count', headerName: t('fail_count'), disableColumnMenu: true, width: 100 },
     
        { field: 'total', headerName: t('Total'), disableColumnMenu: true, width: 100 },
        {
            field: 'Action',
            headerName: t('Action'),
            disableColumnMenu: true,
            width: 150,
            renderCell: (params:any) => (
                <>
         
                        <CustomButton text={t('view')} className="mx-3" color="secondary" onClick={() =>
                            navigate(APP_ROUTES.STUDENT_LIST, {
                                state: {
                                    subject_code:params?.row?.subject_code,
                                    year: locationYear,
                                   
                                }
                            })} /> 
                </>
            ),
        },
    ];

    const rows = subjectData.map((subject:any, index:any) => ({
        id: index + 1,
        subject: subject.subject,
        pass_count: subject.pass_count,
        fail_count: subject.fail_count,
        absent_count: subject.absent_count,
        total: subject.total,
        subject_code: subject.subject_code
    }));

    return (
        <Grid item xs={12} my={5}>
            {subjectWiseDetailsLoading?
             <Grid item xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
             <CircularProgress />
           </Grid>
            :
            <>
                <Grid item xs={12}>
            <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} className="donation-header">
              <Typography fontSize={20} fontWeight={700}>
            {t("Subjects_Wise_List")}
              </Typography>
    
              <>
                <CustomButton
                  type="button"
                  text= {t("GO_BACK")}
                  startIcon={SubdirectoryArrowRightIcon}
                  color={'primary'}
                  className="p-8 secondary-button"
                  onClick={() => {
                    navigate(-1);   
                  }}
                />
              </>
            </Grid>
          </Grid>
            <TableComponent
            columns={columns}
            rows={rows}
            pagination={false}
            hideFooterPagination={true}
        /></>
        }
           
        </Grid>
    );
};

export default SubjectListScreen;
