import { delay, put, call, takeLatest } from 'redux-saga/effects';



import { AxiosResponse } from 'axios';

import { dashboardListInError, dashboardListInProgress, dashboardListInSuccess, saveSurveyInError, saveSurveyInProgress, saveSurveyInSuccess, surveyDetailsInError, surveyDetailsInProgress, surveyDetailsInSuccess, updateSurveyInError, updateSurveyInProgress, updateSurveyInSuccess } from './_survey.slice';
import { addSurveyEffect, getDashboardDetailsEffect, getSurveyDetailstEffect, updateSurveyEffect } from './_survey.service';
import { updateStudentInSuccess } from '../students/_students.slice';

function* saveSurveyWatcher({
    payload,
}: ReturnType<typeof saveSurveyInProgress>) {
    const { navigate, ...requestPayload }: any = payload;
    try {
        console.log("sagaPaylod",payload)
        const { data }: AxiosResponse<any> = yield call(addSurveyEffect, payload);
        yield put(saveSurveyInSuccess(data));
        if (payload.handleSuccess) {
            payload.handleSuccess();	
        }
    } catch (e: any) {
        yield put(saveSurveyInError(e.message));
    }
}
function* updateSurveyWatcher(action: any) {
	try {
	  const payload = action.payload;
	  console.log("sagaPayload", payload);
  
	 
	  const { data }: AxiosResponse<any> = yield call(updateSurveyEffect, payload);
	  yield put(updateSurveyInSuccess(data));
  if(payload.handleSuccess){
	payload.handleSuccess()
  }
  
	  
	} catch (e: any) {
		yield put(updateSurveyInError(e.message));
	}
  }
function* getSurveyDetailsWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getSurveyDetailstEffect, payload);
		console.log('data', data);
		yield put(
			surveyDetailsInSuccess({
				data: data,
				message:"Survey Details Fetched Successfully"
			})
		);
	} catch (error) {
		yield put(
			surveyDetailsInError({
				message: 'error',
			})
		);
	}
}
function* getDashboardDetailsWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getDashboardDetailsEffect, payload);
		console.log('data', data);
		yield put(
			dashboardListInSuccess({
				data: data,
				message:""
			})
		);
	} catch (error) {
		yield put(
			dashboardListInError({
				message: 'error',
			})
		);
	}
}
export default function* studentSaga() {
	yield takeLatest(saveSurveyInProgress.type, saveSurveyWatcher);
    yield takeLatest(surveyDetailsInProgress.type, getSurveyDetailsWatcher);
	yield takeLatest(dashboardListInProgress.type, getDashboardDetailsWatcher);
	yield takeLatest(updateSurveyInProgress.type, updateSurveyWatcher);
	

}
