import { StudentModel } from '../../models/StudentModel';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_TYPE } from '../../models/CommonModels';
import { RootState, useAppSelector } from '../store';

interface StudentDetailsTypes extends INITIAL_STATE_TYPE {
	data: null | StudentModel;
}

interface StudentListTypes extends INITIAL_STATE_TYPE {
	data: StudentModel[];
}

interface InitialStateTypes {
	skillBasedCoursesList:any;
	courseList:any,
	schoolList:any;
	collegeList:any;
	dashboardCollegeList:any;
	districtList:any;
	subjectWiseList:any;
	groupList:any;
	subjetcListByGroupId:any;
	questionListBystandard:any;
	standardList: StudentListTypes;
	studentsList:any;
	studentDetails: StudentDetailsTypes;
	savestudent: INITIAL_STATE_TYPE;
	addstudentandsurvey: any;

	updateStudent:INITIAL_STATE_TYPE;
	deleteStudent:INITIAL_STATE_TYPE;
	getProfile:any;
	updateProfile:any;
	csvStudentList:any;

}

const initialState: InitialStateTypes = {
	skillBasedCoursesList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	courseList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	schoolList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	collegeList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	dashboardCollegeList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	districtList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	subjectWiseList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	groupList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	subjetcListByGroupId: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	questionListBystandard: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	standardList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	studentsList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	studentDetails: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: null,
	},
	savestudent: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	addstudentandsurvey: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	
	updateStudent: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	deleteStudent: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	getProfile: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: null,
	},
	updateProfile: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	csvStudentList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	
};

const studentslice = createSlice({
	name: 'students',
	initialState,
	reducers: {
		resetQuestionList: (state) => {
			state.questionListBystandard.data = [];
			state.questionListBystandard.loading = false;
			state.questionListBystandard.success = false;
			state.questionListBystandard.error = false;
			state.questionListBystandard.message = null;
		  },
		skillBasedCourseListInprogress: (state) => {
			state.skillBasedCoursesList.data = [];
			state.skillBasedCoursesList.loading = true;
			state.skillBasedCoursesList.success = false;
			state.skillBasedCoursesList.error = false;
			state.skillBasedCoursesList.message = null;
		},
		skillBasedCourseListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.skillBasedCoursesList.data = payload.data;
			state.skillBasedCoursesList.loading = false;
			state.skillBasedCoursesList.success = true;
			state.skillBasedCoursesList.message = payload.message;
		},
		skillBasedCourseListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.skillBasedCoursesList.data = [];
			state.skillBasedCoursesList.loading = false;
			state.skillBasedCoursesList.error = true;
			state.skillBasedCoursesList.message = payload.message;
		},
		courseListInprogress: (state) => {
			state.courseList.data = [];
			state.courseList.loading = true;
			state.courseList.success = false;
			state.courseList.error = false;
			state.courseList.message = null;
		},
		courseListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.courseList.data = payload.data;
			state.courseList.loading = false;
			state.courseList.success = true;
			state.courseList.message = payload.message;
		},
	courseListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.courseList.data = [];
			state.courseList.loading = false;
			state.courseList.error = true;
			state.courseList.message = payload.message;
		},
		schoolListInprogress: (state) => {
			state.schoolList.data = [];
			state.schoolList.loading = true;
			state.schoolList.success = false;
			state.schoolList.error = false;
			state.schoolList.message = null;
		},
		schoolListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.schoolList.data = payload.data;
			state.schoolList.loading = false;
			state.schoolList.success = true;
			state.schoolList.message = payload.message;
		},
		schoolListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.schoolList.data = [];
			state.schoolList.loading = false;
			state.schoolList.error = true;
			state.schoolList.message = payload.message;
		},
		districtListInprogress: (state) => {
			state.districtList.data = [];
			state.districtList.loading = true;
			state.districtList.success = false;
			state.districtList.error = false;
			state.districtList.message = null;
		},
		districtListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.districtList.data = payload.data;
			state.districtList.loading = false;
			state.districtList.success = true;
			state.districtList.message = payload.message;
		},
		districtListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.districtList.data = [];
			state.districtList.loading = false;
			state.districtList.error = true;
			state.districtList.message = payload.message;
		},
		subjectWiseListInprogress: (state) => {
			state.subjectWiseList.data = [];
			state.subjectWiseList.loading = true;
			state.subjectWiseList.success = false;
			state.subjectWiseList.error = false;
			state.subjectWiseList.message = null;
		},
		subjectWiseListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.subjectWiseList.data = payload.data;
			state.subjectWiseList.loading = false;
			state.subjectWiseList.success = true;
			state.subjectWiseList.message = payload.message;
		},
		subjectWiseListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.subjectWiseList.data = [];
			state.subjectWiseList.loading = false;
			state.subjectWiseList.error = true;
			state.subjectWiseList.message = payload.message;
		},
		dashboardCollegeListInprogress: (state) => {
			state.dashboardCollegeList.data = [];
			state.dashboardCollegeList.loading = true;
			state.dashboardCollegeList.success = false;
			state.dashboardCollegeList.error = false;
			state.dashboardCollegeList.message = null;
		},
		dashboardCollegeListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.dashboardCollegeList.data = payload.data;
			state.dashboardCollegeList.loading = false;
			state.dashboardCollegeList.success = true;
			state.dashboardCollegeList.message = payload.message;
		},
		dashboardCollegeListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.dashboardCollegeList.data = [];
			state.dashboardCollegeList.loading = false;
			state.dashboardCollegeList.error = true;
			state.dashboardCollegeList.message = payload.message;
		},
		collegeListInprogress: (state) => {
			state.collegeList.data = [];
			state.collegeList.loading = true;
			state.collegeList.success = false;
			state.collegeList.error = false;
			state.collegeList.message = null;
		},
		collegeListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.collegeList.data = payload.data;
			state.collegeList.loading = false;
			state.collegeList.success = true;
			state.collegeList.message = payload.message;
		},
		collegeListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.collegeList.data = [];
			state.collegeList.loading = false;
			state.collegeList.error = true;
			state.collegeList.message = payload.message;
		},
		groupListInprogress: (state) => {
			state.groupList.data = [];
			state.groupList.loading = true;
			state.groupList.success = false;
			state.groupList.error = false;
			state.groupList.message = null;
		},
		groupListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.groupList.data = payload.data;
			state.groupList.loading = false;
			state.groupList.success = true;
			state.groupList.message = payload.message;
		},
		groupListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.groupList.data = [];
			state.groupList.loading = false;
			state.groupList.error = true;
			state.groupList.message = payload.message;
		},
		subjetcListByGroupIdInprogress: (state) => {
			state.subjetcListByGroupId.data = [];
			state.subjetcListByGroupId.loading = true;
			state.subjetcListByGroupId.success = false;
			state.subjetcListByGroupId.error = false;
			state.subjetcListByGroupId.message = null;
		},
		subjetcListByGroupIdInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.subjetcListByGroupId.data = payload.data;
			state.subjetcListByGroupId.loading = false;
			state.subjetcListByGroupId.success = true;
			state.subjetcListByGroupId.message = payload.message;
		},
		subjetcListByGroupIdInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.subjetcListByGroupId.data = [];
			state.subjetcListByGroupId.loading = false;
			state.subjetcListByGroupId.error = true;
			state.subjetcListByGroupId.message = payload.message;
		},
		questionListBystandardInprogress: (state) => {
			state.questionListBystandard.data = [];
			state.questionListBystandard.loading = true;
			state.questionListBystandard.success = false;
			state.questionListBystandard.error = false;
			state.questionListBystandard.message = null;
		},
		questionListBystandardInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.questionListBystandard.data = payload.data;
			state.questionListBystandard.loading = false;
			state.questionListBystandard.success = true;
			state.questionListBystandard.message = payload.message;
		},
		questionListBystandardInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.questionListBystandard.data = [];
			state.questionListBystandard.loading = false;
			state.questionListBystandard.error = true;
			state.questionListBystandard.message = payload.message;
		},
		standardListInProgress: (state) => {
			state.standardList.data = [];
			state.standardList.loading = true;
			state.standardList.success = false;
			state.standardList.error = false;
			state.standardList.message = null;
		},
		standardListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.standardList.data = payload.data;
			state.standardList.loading = false;
			state.standardList.success = true;
			state.standardList.message = payload.message;
		},
		standardListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.standardList.data = [];
			state.standardList.loading = false;
			state.standardList.error = true;
			state.standardList.message = payload.message;
		},
		studentsListInProgress: (state) => {
			state.studentsList.data = [];
			state.studentsList.loading = true;
			state.studentsList.success = false;
			state.studentsList.error = false;
			state.studentsList.message = null;
		},
		studentsListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.studentsList.data = payload.data;
			state.studentsList.loading = false;
			state.studentsList.success = true;
			state.studentsList.message = payload.message;
		},
		studentsListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.studentsList.data = [];
			state.studentsList.loading = false;
			state.studentsList.error = true;
			state.studentsList.message = payload.message;
		},

		studentDetailsInProgress: (state, {}: PayloadAction<string>) => {
			state.studentDetails.loading = true;
			state.studentDetails.success = false;
			state.studentDetails.error = false;
			state.studentDetails.message = null;
		},
		studentDetailsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel; message: string }>
		) => {
			state.studentDetails.data = payload.data;
			state.studentDetails.loading = false;
			state.studentDetails.success = true;
			state.studentDetails.message = payload.message;
		},
		studentDetailsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.studentDetails.data = null;
			state.studentDetails.loading = false;
			state.studentDetails.error = true;
			state.studentDetails.message = payload.message;
		},

		savestudentInProgress: (state, {}: PayloadAction<StudentModel>) => {
			state.savestudent.loading = true;
			state.savestudent.success = false;
			state.savestudent.error = false;
			state.savestudent.message = null;
		},
		savestudentInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.savestudent.loading = false;
			state.savestudent.success = true;
			state.savestudent.message = payload.message;
		},
		savestudentInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.savestudent.loading = false;
			state.savestudent.error = true;
			state.savestudent.message = payload.message;
		},
		savestudentandsurveyInProgress: (state, {}: any) => {
			state.addstudentandsurvey.loading = true;
			state.addstudentandsurvey.success = false;
			state.addstudentandsurvey.error = false;
			state.addstudentandsurvey.message = null;
		},
		savestudentandsurveyInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.addstudentandsurvey.loading = false;
			state.addstudentandsurvey.success = true;
			state.addstudentandsurvey.message = payload.message;
		},
		savestudentandsurveyInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.addstudentandsurvey.loading = false;
			state.addstudentandsurvey.error = true;
			state.addstudentandsurvey.message = payload.message;
		},
		updateStudentInProgress: (state, {}: PayloadAction<StudentModel>) => {
			state.updateStudent.loading = true;
			state.updateStudent.success = false;
			state.updateStudent.error = false;
			state.updateStudent.message = null;
		},
		updateStudentInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.updateStudent.loading = false;
			state.updateStudent.success = true;
			state.updateStudent.message = payload.message;
		},
		updateStudentInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.updateStudent.loading = false;
			state.updateStudent.error = true;
			state.updateStudent.message = payload.message;
		},
		deleteStudentInProgress: (state, {}: PayloadAction<StudentModel>) => {
			state.deleteStudent.loading = true;
			state.deleteStudent.success = false;
			state.deleteStudent.error = false;
			state.deleteStudent.message = null;
		},
		deleteStudentInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.deleteStudent.loading = false;
			state.deleteStudent.success = true;
			state.deleteStudent.message = payload.message;
		},
		deleteStudentInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.deleteStudent.loading = false;
			state.deleteStudent.error = true;
			state.deleteStudent.message = payload.message;
		},
		getProfileInProgress: (state, {}: PayloadAction<string>) => {
			state.getProfile.loading = true;
			state.getProfile.success = false;
			state.getProfile.error = false;
			state.getProfile.message = null;
		},
		getProfileInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.getProfile.data = payload.data;
			state.getProfile.loading = false;
			state.getProfile.success = true;
			state.getProfile.message = payload.message;
		},
		getProfileInInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.getProfile.data = null;
			state.getProfile.loading = false;
			state.getProfile.error = true;
			state.getProfile.message = payload.message;
		},
		updateProfileProgress: (state, {}: PayloadAction<StudentModel>) => {
			state.updateProfile.loading = true;
			state.updateProfile.success = false;
			state.updateProfile.error = false;
			state.updateProfile.message = null;
		},
		updateProfileInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.updateProfile.loading = false;
			state.updateProfile.success = true;
			state.updateProfile.message = payload.message;
		},
		updateProfileInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.updateProfile.loading = false;
			state.updateProfile.error = true;
			state.updateProfile.message = payload.message;
		},
		csvStudentListInProgress: (state) => {
			state.csvStudentList.data = [];
			state.csvStudentList.loading = true;
			state.csvStudentList.success = false;
			state.csvStudentList.error = false;
			state.csvStudentList.message = null;
		},
		csvStudentListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: StudentModel[]; message: string }>
		) => {
			state.csvStudentList.data = payload.data;
			state.csvStudentList.loading = false;
			state.csvStudentList.success = true;
			state.csvStudentList.message = payload.message;
		},
		csvStudentListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.csvStudentList.data = [];
			state.csvStudentList.loading = false;
			state.csvStudentList.error = true;
			state.csvStudentList.message = payload.message;
		},

	},
});

export const {
	studentDetailsInProgress,
	studentDetailsInSuccess,
	studentDetailsInError,
	savestudentInProgress,
	savestudentInSuccess,
	savestudentInError,
	updateStudentInProgress,
	updateStudentInError,
	updateStudentInSuccess,
	deleteStudentInError,
	deleteStudentInProgress,
	deleteStudentInSuccess,
	studentsListInProgress,
	studentsListInSuccess,
	studentsListInError,
	standardListInProgress,
	standardListInSuccess,
	standardListInError,
	questionListBystandardInprogress,
	questionListBystandardInSuccess,
	questionListBystandardInError,
	groupListInprogress,
	groupListInSuccess,
	groupListInError,
	subjetcListByGroupIdInprogress,
	subjetcListByGroupIdInSuccess,
	subjetcListByGroupIdInError,
	schoolListInprogress,
	schoolListInSuccess,
	schoolListInError,
	courseListInprogress,
	courseListInSuccess,
	courseListInError,
	skillBasedCourseListInprogress,
	skillBasedCourseListInSuccess,
	skillBasedCourseListInError,
	getProfileInProgress,
	getProfileInSuccess,
	getProfileInInError ,
	updateProfileInSuccess,
	updateProfileProgress,
	updateProfileInError,
	csvStudentListInProgress,
	csvStudentListInError,
	csvStudentListInSuccess,
	resetQuestionList,
	collegeListInprogress,
	collegeListInSuccess,
	collegeListInError,
	savestudentandsurveyInProgress,
	savestudentandsurveyInSuccess,
	savestudentandsurveyInError,
	districtListInprogress,
	districtListInSuccess,
	districtListInError,
	dashboardCollegeListInprogress,
	dashboardCollegeListInSuccess,
	dashboardCollegeListInError,
	subjectWiseListInprogress,
	subjectWiseListInSuccess,
	subjectWiseListInError
	
} = studentslice.actions;
export const selectSkillBasedCourseLoading = (state: RootState) =>
	state.students.skillBasedCoursesList.loading;
export const selectSkillBasedCourse = (state: RootState) =>
	state.students.skillBasedCoursesList.data;
export const SkillBasedCourseSuccess = (state: RootState) =>
	state.students.skillBasedCoursesList.success;
export const selectCourseListLoading = (state: RootState) =>
	state.students.courseList.loading;
export const selectCourseList = (state: RootState) =>
	state.students.courseList.data;
export const courselListSuccess = (state: RootState) =>
	state.students.courseList.success;
export const selectSchoolListLoading = (state: RootState) =>
	state.students.schoolList.loading;
export const selectSchoolList = (state: RootState) =>
	state.students.schoolList.data;
export const SchoolListSuccess = (state: RootState) =>
	
	state.students.schoolList.success;

	export const selectGroupListLoading = (state: RootState) =>
	state.students.groupList.loading;
export const selectGroupList = (state: RootState) =>
	state.students.groupList.data;
export const GroupListSuccess = (state: RootState) =>
	state.students.groupList.success;
export const selectsubjectListByIdLoading = (state: RootState) =>
	state.students.subjetcListByGroupId.loading;
export const selectsubjectListById = (state: RootState) =>
	state.students.subjetcListByGroupId.data;
export const subjectListByIdSuccess = (state: RootState) =>
	state.students.subjetcListByGroupId.success;
export const selectStandardListLoading = (state: RootState) =>
	state.students.standardList.loading;
export const selectStandardList = (state: RootState) =>
	state.students.standardList.data;
export const StandardListSuccess = (state: RootState) =>
	state.students.standardList.success;
export const selectQuestionListByStandardLoading = (state: RootState) =>
	state.students.questionListBystandard.loading;
export const selectQuestionListByStandard = (state: RootState) =>
	state.students.questionListBystandard.data;
export const selectQuestionListByStandardSuccess = (state: RootState) =>
	state.students.questionListBystandard.success;

export const selectstudentsListLoading = (state: RootState) =>
	state.students.studentsList.loading;
export const selectstudentsList = (state: RootState) =>
	state.students.studentsList.data;
export const StudentListSuccess = (state: RootState) =>
	state.students.studentsList.success;

export const selectSavestudentLoading = (state: RootState) =>
	state.students.savestudent.loading;
export const selectaddstudentandsurveyLoading = (state: RootState) =>
	state.students.addstudentandsurvey.loading;
	export const selectUpdatestudentLoading = (state: RootState) =>
	state.students.updateStudent.loading;
	export const selectDeletestudentLoading = (state: RootState) =>
	state.students.deleteStudent.loading;
export const selectstudentListLoading = (state: RootState) =>
	state.students.studentDetails.loading;
export const selectstudentDetails = (state: RootState) =>
	state.students.studentDetails.data;
export const getProfileLoading = (state: RootState) =>
	state.students.getProfile.loading;
export const selectGetProfileDetails = (state: RootState) =>
	state.students.getProfile.data;
export const selectUpdateProfileLoading = (state: RootState) =>
	state.students.updateProfile.loading;
export const selectCsvstudentsListLoading = (state: RootState) =>
	state.students.csvStudentList.loading;
export const selectCsvstudentsList = (state: RootState) =>
	state.students.csvStudentList.data;
export const CsvStudentListSuccess = (state: RootState) =>
	state.students.csvStudentList.success;
export const selectCollegeListLoading = (state: RootState) =>
	state.students.collegeList.loading;
export const selectCollegeList = (state: RootState) =>
	state.students.collegeList.data;
export const collegeListSuccess = (state: RootState) =>
	
	state.students.collegeList.success;
	export const selectDistrictListLoading = (state: RootState) =>
		state.students.districtList.loading;
	export const selectDistrictList = (state: RootState) =>
		state.students.districtList.data;
	export const selectDistrictListSuccess = (state: RootState) =>
		
		state.students.districtList.success;
		export const selectDashboardCollegeListLoading = (state: RootState) =>
			state.students.dashboardCollegeList.loading;
		export const selectDashboardCollegeList = (state: RootState) =>
			state.students.dashboardCollegeList.data;
		export const DashboardCollegeListSuccess = (state: RootState) =>
			state.students.dashboardCollegeList.success;
		export const selectsubjectWiseListLoading = (state: RootState) =>
			state.students.subjectWiseList.loading;
		export const selectsubjectWiseList = (state: RootState) =>
			state.students.subjectWiseList.data;
		export const selectsubjectWiseListSuccess = (state: RootState) =>
			state.students.subjectWiseList.success;



export default studentslice;
