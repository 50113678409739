import { Instance ,candidateInstances, testingInstance} from '../../common/axiosInstance';
import ROUTES from '../../common/routes';
const token=localStorage.getItem('accessToken')



export const getStudentListEffect = (payload: any) => {
  let url = `/student/getstudentlist?pagination_required=${payload?.pagination}&pageNo=${payload?.pageNo}&pageSize=10&student_emis_no=${payload?.student_emis_no}&standard_id=${payload?.standard_id}&school_id=${payload?.school_id}&result=${payload?.result}&is_survey_completed=${payload?.is_survey_completed}&student_status=${payload?.student_status}&year=${payload?.year}&course_id=${payload?.course_id}`;

  if (payload?.college_id) {
    url += `&college_id=${payload.college_id}`;
  }
  if (payload?.subject_code) {
    url += `&subject_code=${payload.subject_code}`;
  }

  return testingInstance.request({
    url: url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getSkillBasedCourseListEffect = (payload:any) => {

  return testingInstance.request({
    url: `/coures/getSkillBasedCourses`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};
export const getCoursetEffect = (payload:any) => {

  return testingInstance.request({
    url: `/coures/getCourses`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};
export const getSchoolListEffect = (payload:any) => {
  console.log("serviceCalled")
  return testingInstance.request({
    url: `/school/getschools`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};
export const getCollegeListEffect = (payload:any) => {
  console.log("serviceCalled",payload)
  return testingInstance.request({
    url: `/college/list?courses_id=${payload.courses_id}&district_id=${payload.district_id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};
export const getSubjectWiseListEffect = (payload:any) => {
  console.log("serviceCalled",payload)
  return testingInstance.request({
    url: `/answer/dashboard2?year=${payload?.year}&standard_id=${payload?.standard_id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};
export const getDashboardCollegeListEffect = (payload: any) => {
  console.log("serviceCalled", payload);

  let url = `/college/answer?courses_id=${payload?.courses_id}&student_status=${payload?.student_status}&year=${payload?.year}`;
  
  if (payload?.school_id) {
    url += `&school_id=${payload.school_id}`;
  }

  return testingInstance.request({
    url: url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getDistrictListEffect = (payload:any) => {

  return testingInstance.request({
    url: `/districts/list`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};
export const getstandarsListEffect = (payload:any) => {
  console.log("serviceCalled")
  return testingInstance.request({
    url: `/standard/getstandards`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};
export const getGroupListEffect = (payload:any) => {
  console.log("serviceCalled")
  return testingInstance.request({
    url: `/groups/getgroups`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};
export const getSubjectByGroupIdEffect = (payload:any) => {
  console.log("serviceCalled",payload)
  return testingInstance.request({
    url: `/groups/getsubjects?group_id=${payload}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};
export const getQuestionByStandardEffect = (payload:any) => {
  console.log("serviceCalled")
  return testingInstance.request({
    url: `/question/getquestionbystandard?standard_id=${payload.standard_id}&year=${payload.year}&questions_type=${payload.question_type}&pagination_required=false`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};

export const getstudentListEffect = (payload:any) => {
    return Instance.request({
      url: `/student/list?pagination_required=${payload.pagination_required}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };

  export const addstudentEffect = (formData:any) => {

    return candidateInstances.request({
      url: `/student/add`,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  export const addstudentandSurveyEffect = (formData:any) => {

    return candidateInstances.request({
      url: `/answer/inseranswerstudent`,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  export const updatestudentEffect = (formData:any) => {
 console.log("service called",formData)
    return testingInstance.request({
      url: `student/update/${formData?.id}`,
      method: "PUT",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  export const deletestudentEffect = (formData:any) => {
   
    return candidateInstances.request({
      url: `/student/delete/${formData?.id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    });

  }
  export const getstudentEffect = (formData:any) => {
 
    return Instance.request({
      url: `/student/details/${formData}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  export const addStudentEffect = (formData:any) => {

    return testingInstance.request({
      url: `/student/addStudent`,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  export const getProfileEffect = (formData:any) => {
 
    return testingInstance.request({
      url: `/auth/profile/${formData?.id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  export const updateProfileEffect = (formData:any) => {
    console.log("service called",formData)
       return testingInstance.request({
         url: `/auth/updateProfile/${formData?.data?.id}`,
         method: "PUT",
         data: formData?.data,
         headers: {
           Authorization: `Bearer ${token}`,
           "Content-Type": "application/json",
         },
       });
     }
     
export const getCsvStudentListEffect = (payload:any) => {

  let url = `/student/getstudentlist?pagination_required=${payload?.pagination}&pageNo=${payload?.pageNo}&pageSize=10&student_emis_no=${payload?.student_emis_no}&standard_id=${payload?.standard_id}&school_id=${payload?.school_id}&result=${payload?.result}&is_survey_completed=${payload?.is_survey_completed}&student_status=${payload?.student_status}&year=${payload.year}&course_id=${payload?.course_id}`;
  
  if (payload?.college_id) {
    url += `&college_id=${payload.college_id}`;
  }
  return testingInstance.request({
    url: url,
  
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },  
  });
};
