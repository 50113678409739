import { delay, put, call, takeLatest } from 'redux-saga/effects';
import { StudentListSuccess, collegeListInError, collegeListInSuccess, collegeListInprogress, courseListInSuccess, courseListInprogress, csvStudentListInError, csvStudentListInProgress, csvStudentListInSuccess, dashboardCollegeListInError, dashboardCollegeListInSuccess, dashboardCollegeListInprogress, districtListInSuccess, districtListInprogress, getProfileInInError, getProfileInProgress, getProfileInSuccess, groupListInSuccess, groupListInprogress, questionListBystandardInError, questionListBystandardInSuccess, questionListBystandardInprogress, savestudentInError, savestudentInProgress, savestudentInSuccess, savestudentandsurveyInProgress, schoolListInSuccess, schoolListInprogress, skillBasedCourseListInSuccess, skillBasedCourseListInprogress, standardListInProgress, standardListInSuccess, studentsListInError, studentsListInProgress, studentsListInSuccess, subjectWiseListInSuccess, subjectWiseListInprogress, subjetcListByGroupIdInSuccess, subjetcListByGroupIdInprogress, updateProfileInError, updateProfileInSuccess, updateProfileProgress, updateStudentInError, updateStudentInProgress, updateStudentInSuccess } from './_students.slice';


import { AxiosResponse } from 'axios';
import { addStudentEffect, addstudentandSurveyEffect, getCollegeListEffect, getCoursetEffect, getCsvStudentListEffect, getDashboardCollegeListEffect, getDistrictListEffect, getGroupListEffect, getProfileEffect, getQuestionByStandardEffect, getSchoolListEffect, getSkillBasedCourseListEffect, getStudentListEffect, getSubjectByGroupIdEffect, getSubjectWiseListEffect, getstandarsListEffect, updateProfileEffect, updatestudentEffect } from './_students.service';
import { APP_ROUTES } from '../../common/constants/Routes';

function* getStandardListWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getstandarsListEffect, payload);
		console.log('data', data);
		yield put(
			standardListInSuccess({
				data: data,
				message:""
			})
		);
	} catch (error) {
		// yield put(
		// 	partiesListInError({
		// 		message: 'error',
		// 	})
		// );
	}
}
function* getQuestionBystandardWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled",payload)
		yield delay(3000);
		const { data } = yield call(getQuestionByStandardEffect, payload);
		if(payload.
			handleSuccess
			){
				payload.
			handleSuccess()
			}
		yield put(
			questionListBystandardInSuccess({
				data: data,
				message:""
				
			
			})
		);
	} catch (error) {
		yield put(
			questionListBystandardInError({
				message: 'error',
			})
		);
	}
}
function* getGroupListWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getGroupListEffect, payload);
		console.log('data', data);
		yield put(
			groupListInSuccess({
				data: data,
				message:""
				
			
			})
		);
	} catch (error) {
		// yield put(
		// 	partiesListInError({
		// 		message: 'error',
		// 	})
		// );
	}
}
function* getSubjectListWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled",payload)
		yield delay(3000);
		const { data } = yield call(getSubjectByGroupIdEffect, payload.payload.group_id);
		console.log('data', data);
		yield put(
			subjetcListByGroupIdInSuccess({
				data: data,
				message:""
				
			
			})
		);
	} catch (error) {
		// yield put(
		// 	partiesListInError({
		// 		message: 'error',
		// 	})
		// );
	}
}

function* getSchoolListWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getSchoolListEffect, payload);
		console.log('data', data);
		yield put(
			schoolListInSuccess({
				data: data,
				message:""
				
			
			})
		);
	} catch (error) {
		// yield put(
		// 	partiesListInError({
		// 		message: 'error',
		// 	})
		// );
	}
}
function* getCollegeListWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getCollegeListEffect, payload);
		console.log('data', data);
		yield put(
			collegeListInSuccess({
				data: data,
				message:""
				
			
			})
		);
	} catch (error) {
		yield put(
			collegeListInError({
				message: 'error',
			})
		);
	}
}
function* getDashboradCollegeListWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getDashboardCollegeListEffect, payload);
		console.log('data', data);
		yield put(
			dashboardCollegeListInSuccess({
				data: data,
				message:""
				
			
			})
		);
	} catch (error) {
		yield put(
			dashboardCollegeListInError({
				message: 'error',
			})
		);
	}
}
function* getDistrictListWatcher({
	payload
}: any) {
	try {
		
		yield delay(3000);
		const { data } = yield call(getDistrictListEffect, payload);
		console.log('data', data);
		yield put(
			districtListInSuccess({
				data: data,
				message:""
				
			
			})
		);
	} catch (error) {
		// yield put(
		// 	partiesListInError({
		// 		message: 'error',
		// 	})
		// );
	}
}
function* getSubjectWiseListWatcher({
	payload
}: any) {
	try {
		yield delay(3000);
		const { data } = yield call(getSubjectWiseListEffect, payload);
		console.log('data', data);
		yield put(
			subjectWiseListInSuccess({
				data: data,
				message:""})
		);
	} catch (error) {
		// yield put(
		// 	partiesListInError({
		// 		message: 'error',
		// 	})
		// );
	}
}

function* getCourseListWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getCoursetEffect, payload);
		console.log('data', data);
		yield put(
			courseListInSuccess({
				data: data,
				message:""
				
			
			})
		);
	} catch (error) {
		// yield put(
		// 	partiesListInError({
		// 		message: 'error',
		// 	})
		// );
	}
}
function* getSkillBasedCourseWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getSkillBasedCourseListEffect, payload);
		console.log('data', data);
		yield put(
			skillBasedCourseListInSuccess({
				data: data,
				message:""
				
			
			})
		);
	} catch (error) {
		// yield put(
		// 	partiesListInError({
		// 		message: 'error',
		// 	})
		// );
	}
}
function* getStudentListWatcher({
	payload
}: any) {

	console.log("payloadStudents",payload)
	try {
		yield delay(3000);
		const { data } = yield call(getStudentListEffect, payload);
		yield put(
			studentsListInSuccess({
				data: data,
				message:"List",
				
			})
		);
	} catch (error) {
		yield put(
			studentsListInError({
				message: 'error',
			})
		);
	}
}

function* saveStudentWatcher(action: any) {
	try {
	  const payload = action.payload;
	  console.log("sagaPayload", payload);
  
	 
	  const { data }: AxiosResponse<any> = yield call(addStudentEffect, payload);
  if(payload.handleSuccess){
	payload.handleSuccess()
  }
  if(payload.navigate){
	payload. navigate(APP_ROUTES.STUDENT_FORM, {
		state: {
		  data: data,
		  type: 'addSurvey',
		  tab: 1,
		},
	});
  }
	  yield put(savestudentInSuccess(data));
	} catch (e: any) {
		yield put(savestudentInError(e.message));
	}
  }
  function* saveStudentAndSurverWatcher(action: any) {
	try {
	  const payload = action.payload;
	  console.log("sagaPayload", payload);
  
	 
	  const { data }: AxiosResponse<any> = yield call(addstudentandSurveyEffect, payload);
	  yield put(savestudentInSuccess(data));
  if(payload.handleSuccess){
	payload.handleSuccess()
  }
 
	
	} catch (e: any) {
		yield put(savestudentInError(e.message));
	}
  }
  function* getProfileWatcher({
	payload
}: any) {

	console.log("payloadStudents",payload)
	try {
		yield delay(3000);
		const { data } = yield call(getProfileEffect, payload);
		yield put(
			getProfileInSuccess({
				data: data,
				message:"List",
				
			})
		);
	} catch (error) {
		yield put(
			getProfileInInError ({
				message: 'error',
			})
		);
	}
}
function* updateProfileWatcher(action: any) {
	try {
	  const payload = action.payload;
	  console.log("sagaPayload", payload);
  
	 
	  const { data }: AxiosResponse<any> = yield call(updateProfileEffect, payload);
  if(payload.handleSuccess){
	payload.handleSuccess()
  }
  
	  yield put(updateProfileInSuccess(data));
	} catch (e: any) {
		yield put(updateProfileInError(e.message));
	}
  }
//   function* getCsvStudentListWatcher({
// 	payload
// }: any) {

// 	console.log("payloadStudents",payload)
// 	try {
// 		yield delay(3000);
// 		const { data } = yield call(getCsvStudentListEffect, payload);
// 		yield put(
// 			csvStudentListInSuccess({
// 				data: data,
// 				message:"List",
				
// 			})
// 		);
// 	} catch (error) {
// 		yield put(
// 			csvStudentListInError({
// 				message: 'error',
// 			})
// 		);
// 	}
// }
function* updateStudentWatcher(action: any) {
	try {
	  const payload = action.payload;
	  console.log("sagaPayload", payload);
  
	 
	  const { data }: AxiosResponse<any> = yield call(updatestudentEffect, payload);
  if(payload.handleSuccess){
	payload.handleSuccess()
  }

	  yield put(updateStudentInSuccess(data));
	} catch (e: any) {
		
		yield put(updateStudentInError(e.message));
	}
  }
export default function* studentSaga() {
	yield takeLatest(standardListInProgress.type, getStandardListWatcher);
	yield takeLatest(questionListBystandardInprogress.type, getQuestionBystandardWatcher);
	yield takeLatest(groupListInprogress.type, getGroupListWatcher);
	yield takeLatest(subjetcListByGroupIdInprogress.type, getSubjectListWatcher);
	yield takeLatest(schoolListInprogress.type, getSchoolListWatcher);
	yield takeLatest(collegeListInprogress.type, getCollegeListWatcher);
	yield takeLatest(courseListInprogress.type, getCourseListWatcher);
	yield takeLatest(skillBasedCourseListInprogress.type, getSkillBasedCourseWatcher);
	yield takeLatest(studentsListInProgress.type, getStudentListWatcher);
	yield takeLatest(savestudentInProgress.type, saveStudentWatcher);
	yield takeLatest(savestudentandsurveyInProgress.type, saveStudentAndSurverWatcher);
	yield takeLatest(getProfileInProgress.type, getProfileWatcher);
	yield takeLatest(updateProfileProgress.type, updateProfileWatcher);
	yield takeLatest(updateStudentInProgress.type, updateStudentWatcher);
	yield takeLatest(districtListInprogress.type, getDistrictListWatcher);
	yield takeLatest(dashboardCollegeListInprogress.type, getDashboradCollegeListWatcher);
	yield takeLatest(subjectWiseListInprogress.type, getSubjectWiseListWatcher);

}
