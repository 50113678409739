import { CardContent, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../common/ui/TableComponent';
import CustomButton from '../../../common/ui/CustomButton';
import { Link,useLocation, useNavigate } from 'react-router-dom';

import corousel1 from '../../../../src/common/icons/noDataFoundTwo.png';
import { useTranslation } from 'react-i18next';
import SchoolHeader from './SchoolHeader';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { csvSchoolListInProgress, deleteSchoolInProgress, schoolDetailsInProgress, selectCsvSchoolDetailsList, selectschoolDetailsList, selectschoolDetailsListLoading } from '../../../store/school/_school.slice';
import { Card, CircularProgress, InputLabel, Select } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yearsList } from '../../../common/constants/FormItems';
import { dashboardListInProgress, selectDashboardDetailsList, selectDashboardDetailsListLoading } from '../../../store/survey/_survey.slice';

function Schools() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const year=location?.state?.year
   
    const locationType=location?.state?.type
    console.log("locationType",location?.state?.dataType)
    // console.log("locationType",location?.state?.higer_studies_applied_data)
    const student_status=location?.state?.student_status
 


    const [open, setOpen] = useState(false);
    const [id, setId] = useState('');
    const { control, watch, handleSubmit, setValue } = useForm({
        defaultValues: {
            year: '2024'
        }
    });

    const schoolDetails = useSelector(selectschoolDetailsList);
    const schoolDetailsLoading = useSelector(selectschoolDetailsListLoading);
    const schoolList = schoolDetails?.data || [];
    const schoolTotalRecords = schoolDetails?.totalRecords || 0;
    const csvSchoolDetails = useSelector(selectCsvSchoolDetailsList);
    const csvSchoolList=csvSchoolDetails?.data || [];
    console.log("schoolList",schoolList)
    const dashboardLoading = useSelector(selectDashboardDetailsListLoading);
	const dashboardDetails = useSelector(selectDashboardDetailsList);
	useEffect(() => {
		const year=location?.state?.year
        if(year){
            dispatch(dashboardListInProgress({ year }as any));
        }
		
	}, [dispatch]);

  

    const handleDelete = () => {
        setOpen(true);
    };

    const handleDeleteAndSetId = (id: any) => {
        const handleSuccess = () => {
            const payloads = {
                pageNo: 1,
                pagination: true,
            }
            dispatch(schoolDetailsInProgress(payloads as any));
        }
        const payload: any = {
            id: id,
            handleSuccess
        }
        dispatch(deleteSchoolInProgress(payload))
    };

    const handleView = (id: any, data: any) => {
        navigate(APP_ROUTES.SCHOOLWISE_DEATILS, {
            state: {
                data: id,
                overAlldate: data,
                type: "view",
            }
        });
    };

    useEffect(() => {
        const payloads = {
            pageNo: 1,
            pagination: true,
        }
       
        dispatch(schoolDetailsInProgress(payloads as any));
       
    }, [dispatch]);

    const handlePagination = (data: any) => {
        const payloads = {
            pageNo: data?.page + 1,
            pagination: true,
            year: null
        };
        dispatch(schoolDetailsInProgress(payloads as any));
    };

    const columns = [
        { field: 'name', headerName: `${t('name')}`, disableColumnMenu: true, flex: 1 },
        { field: 'pass_percentage_12', headerName: `${t('hsc_passed')}`, disableColumnMenu: true, flex: 1 },
        { field: 'fail_percentage_12', headerName: `${t('hsc_failed')}`, disableColumnMenu: true, flex: 1 },
        { field: 'pass_percentage_10', headerName: `${t('sslc_passed')}`, disableColumnMenu: true, flex: 1 },
        { field: 'fail_percentage_10', headerName: `${t('sslc_failed')}`, disableColumnMenu: true, flex: 1 },
       
        { field: 'suvey_completed_student_count', headerName: `${t('survey_completed')}`, disableColumnMenu: true, flex: 1 },
        { field: 'suvey_not_completed_student_count', headerName: `${t('survey_pending')}`, disableColumnMenu: true, flex: 1 },
        { field: 'higher_studies_join_percentage_12', headerName: `${t('HIGHR_STUDIES_JOINED_PERCENTAGE')}`, disableColumnMenu: true, flex: 1 },
    
        { field: 'Action', headerName: `${t('Action')}`, disableColumnMenu: true, width:location?.state?.higer_studies_applied_data >= 0|| location?.state?.higer_studies_joined_data >= 0?150:250, renderCell: (params: any) => (
            <>
             {console.log(params?.row?.higer_studies_joined_data === 0,"params",params?.row?.higher_studies_join_12)}
                {locationType==="dashboard"?<>
                    {student_status===1?
                   
                    <CustomButton text={`${t('view')}`} disabled={params?.row?.higher_studies_join_12 === 0 }className="mx-3" color={'secondary'} onClick={() => 

                        navigate(APP_ROUTES.STREAM_BASED_SCREEN, {state:{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",student_status:1,type:"schoolWiseDetails",location: params.row}})} />:
                        <CustomButton text={`${t('view')}`} className="mx-3" disabled={params?.row?.higher_studies_applied_12 === 0 } color={'secondary'} onClick={() => 
                  
                            navigate(APP_ROUTES.HIGHER_STUDIES_APPLIED_SCREEN, {state:{standard_id:"a3c15d59-31d5-49fe-a002-f741e23ff80e",student_status:3,type:"schoolWiseDetails",location: params.row}})} />}
                    
                </>:<>
                <CustomButton text={`${t('EDIT')}`} className="mx-5" color={'secondary'} onClick={() => navigate(APP_ROUTES.SCHOOLFORM, { state: { data: params.row, type: 'edit' } })} />
                <CustomButton text={`${t('DELETE')}`} className="mx-3 color-gray" onClick={() => handleDeleteAndSetId(params.row.id)} />
                <CustomButton text={`${t('view')}`} className="mx-3" color={'secondary'} onClick={() => handleView(params.row.id, params.row)} /></>}
                
            </>
        )},
    ];
    
    if (location?.state?.higer_studies_joined_data >=0 ) {
        console.log("columnaddedblock")
        const newColumns = [
            {
                field: 'higher_studies_join_12',
                headerName: `${t('HIGHER_STUDIES_JOINED_HSC')}`,
                disableColumnMenu: true,
                flex: 1,
            },
            
            {
                field: 'survey_count_12',
                headerName:`${t('SURVEY_COUNT_HSC')}`,
                disableColumnMenu: true,
                flex: 1,
            },
        ];
    
        // Find the index of the "Action" column
        const actionColumnIndex = columns.findIndex(column => column.field === 'Action');
    
        // Insert new columns before the "Action" column
        columns.splice(actionColumnIndex, 0, ...newColumns);
    }
    if(location?.state?.higer_studies_applied_data >= 0){
        const newColumns = [
            {
                field: 'higher_studies_applied_12',
                headerName: 'Higher Studies  Appli4d HSC',
                disableColumnMenu: true,
                flex: 1,
            },
            {
                field: 'survey_count_12',
                headerName: 'Survey Count HSC',
                disableColumnMenu: true,
                flex: 1,
            },
        ];
    
       
        const actionColumnIndex = columns.findIndex(column => column.field === 'Action');
    
        
        columns.splice(actionColumnIndex, 0, ...newColumns);

    }
 

    
    const rows = schoolList.map((school: any) => ({
        id: school.id,
        name: school.name,
        street: school.street,
        address: school.address,
        school_type: school.school_type_id,
        pass_percentage_10: school.pass_percentage_10,
        pass_percentage_12: school.pass_percentage_12,
        fail_percentage_10: school.fail_percentage_10,
        fail_percentage_12: school.fail_percentage_12,
        suvey_completed_student_count: school.suvey_completed_student_count,
        suvey_not_completed_student_count: school.suvey_not_completed_student_count,
        head_master_name: school.head_master_name,
        hm_number: school.hm_number,
        created_at: school.created_at,
        updated_at: school.updated_at,
        student_count: school.student_count,
        survey_count_10: school.survey_count_10,
        survey_count_12: school.survey_count_12,
        fail_count: school.fail_count,
        pass_count_10: school.pass_count_10,
        pass_count_12: school.pass_count_12,
		fail_count_10: school.fail_count_10,
		fail_count_12:school.fail_count_12,
        higher_studies_join_12: school.higher_studies_join_12,
        no_studies_12: school.no_studies_12,
        higher_studies_applied_12: school.higher_studies_applied_12,
        skill_based_studies_12: school.skill_based_studies_12,
        higher_studies_join_10: school.higher_studies_join_10,
        no_studies_10: school.no_studies_10,
        higher_studies_applied_10: school.higher_studies_applied_10,
        skill_based_studies_10: school.skill_based_studies_10,
        courseAppliedCount:school?.courseAppliedCount,
        courseJoinCount:school?.courseJoinCount,
        higher_studies_join_percentage_12:school?.higher_studies_join_percentage_12
     
        
    }));
    const rows2 = csvSchoolList.map((school: any) => ({
        id: school.id,
        name: school.name,
        street: school.street,
        address: school.address,
        school_type: school.school_type_id,
        pass_percentage_10: school.pass_percentage_10,
        pass_percentage_12: school.pass_percentage_12,
        fail_percentage_10: school.fail_percentage_10,
        fail_percentage_12: school.fail_percentage_12,
        suvey_completed_student_count: school.suvey_completed_student_count,
        suvey_not_completed_student_count: school.suvey_not_completed_student_count,
        head_master_name: school.head_master_name,
        hm_number: school.hm_number,
        created_at: school.created_at,
        updated_at: school.updated_at,
        student_count: school.student_count,
        survey_count_10: school.survey_count_10,
        survey_count_12: school.survey_count_12,
        fail_count: school.fail_count,
        pass_count_10: school.pass_count_10,
        pass_count_12: school.pass_count_12,
		fail_count_10: school.fail_count_10,
		fail_count_12:school.fail_count_12,
        higher_studies_join_12: school.higher_studies_join_12,
        no_studies_12: school.no_studies_12,
        higher_studies_applied_12: school.higher_studies_applied_12,
        skill_based_studies_12: school.skill_based_studies_12,
        higher_studies_join_10: school.higher_studies_join_10,
        no_studies_10: school.no_studies_10,
        higher_studies_applied_10: school.higher_studies_applied_10,
        skill_based_studies_10: school.skill_based_studies_10,
        courseAppliedCount:school?.courseAppliedCount,
        courseJoinCount:school?.courseJoinCount,
    }));

    useEffect(() => {
        const year = watch('year');
        const payloads: any = {
            pageNo: 1,
            pagination: true,
            year: year,
            search_query:null
        }
        const payload2: any = {
            pageNo: 1,
            pagination: false,
            year: year,
            search_query:null
        }
        dispatch(schoolDetailsInProgress(payloads));
        dispatch(csvSchoolListInProgress(payload2));
    }, [dispatch, watch('year')]);

    const handleOnchange = (search_query: any) => {
        const year = watch('year');
        const payloads: any = {
            pageNo: 1,
            pagination: true,
            school_type_id: null,
            year: year,
            search_query:search_query
           
        };
        dispatch(schoolDetailsInProgress(payloads));
    }
    const courses :any= [
        { id: "1", name: "Bachelor of Engineering" },
        { id: "2", name: "Arts & Science" },
        { id: "3", name: "Law" },
        { id: "4", name: "Agriculture" },
        { id: "5", name: "Medical" },
        { id: "6", name: "Nursing" },
        { id: "7", name: "Journalism" },
        { id: "8", name: "Others" }
    ];
    const courseColors :any= {
        "Bachelor of Engineering": '#FFECB3',
        "Arts & Science": '#E8EAF6',
        "Law": '#FFEBEE',
        "Agriculture": '#C8E6C9',
        "Medical": '#FFE0B2',
        "Nursing": '#ADD8E6',
        "Journalism": '#F8BBD0',
        "Others": '#E0F7FA'
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <SchoolHeader />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                <InputLabel>{t("year")}:</InputLabel>
                <Controller
                    control={control}
                    name="year"
                    render={({ field }) => (
                        <Select
                            {...field}
                            fullWidth
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                        >
                            {yearsList.map((year) => (
                                <MenuItem key={year.id} value={year.id}>
                                    {year.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    rules={{ required: "Year is required" }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={4} p={2}>
                <InputLabel>{t("search")}:</InputLabel>
                <TextField
                    fullWidth
                    placeholder={t("search")}
                    variant='standard'
                    onChange={(e: any) => handleOnchange(e.target.value)}
                />
            </Grid>
            {location?.state?.dataType &&(<>
        {location?.state?.dataType && location?.state?.dataType==="higher_studies_applied" ?(<> <Grid item xs={12} container display="flex" className="dashboard-grid">
               { dashboardDetails?.courseAppliedCount?.length > 0 ? (<>   {dashboardDetails?.courseAppliedCount?.map((course:any, index:any) => {
                    const courseName = courses.find((c: any) => c.id === course.course_id)?.name || "Others";
                    return (
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} p={2} className="btn" key={index}>
                            
                            <Link to={APP_ROUTES.COLLEGE_LIST_SCREEN} state={{ standard_id: course.standard_id, course_id: course.course_id, year: year, student_status: 3 ,type:"streamBased",dataType:"courseApplied"}}>
                                <Card className='card-grid' style={{ backgroundColor: courseColors[courseName] }}>
                                    <CardContent>
                                        <Stack spacing={2}>
                                            <Grid item xs={12} container display="flex" justifyContent="space-between" alignItems="center">
                                                <Grid>
                                                    <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
                                                        <Stack spacing={1}>
                                                            <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
                                                                {t(courseName.replace(/\s+/g, '_').toLowerCase())}
                                                            </Typography>
                                                            <Typography variant="h6">{course.count}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    );
                })}</>):(<>
                <Grid item xs={6} my={5}>
              <div className="no-data-image-div">
                <img src={corousel1} className="no-data-image" />
              </div>
            </Grid></>)}
             
            </Grid></>):(<>
                <Grid item xs={12} container display="flex" className="dashboard-grid">
                    {dashboardDetails?.courseJoinCount?.length > 0 ?(<>
                        {dashboardDetails?.courseJoinCount?.map((course:any, index:any) => {
                    const courseName = courses.find((c: any) => c.id === course.course_id)?.name || "Others";
                    return (
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} p={2} className="btn" key={index}>
                              <Link to={APP_ROUTES.COLLEGE_LIST_SCREEN} state={{ standard_id: course.standard_id, course_id: course.course_id, year: year, student_status: 1 ,type:"streamBased",dataType:"courseJoined"}}>
                                <Card className='card-grid' style={{ backgroundColor: courseColors[courseName] }}>
                                    <CardContent>
                                        <Stack
                                         spacing={2}>
                                            <Grid item xs={12} container display="flex" justifyContent="space-between" alignItems="center">
                                                <Grid>
                                                    <Stack direction="column" sx={{ alignItems: 'flex-start', justifyContent: 'space-between' }} spacing={3}>
                                                         <Stack spacing={1}>
                                                            <Typography color="text.secondary" gutterBottom variant="overline" className='card-text-2'>
                                                                {t(courseName.replace(/\s+/g, '_').toLowerCase())}
                                                            </Typography>
                                                            <Typography variant="h6">{course.count}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    );
                })}</>):(<>  <Grid item xs={6} my={5}>
              <div className="no-data-image-div">
                <img src={corousel1} className="no-data-image" />
              </div>
            </Grid>
           
           </>)}
             
            </Grid></>)}</>)}
            {schoolDetailsLoading ?
                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress />
                </Grid> :
                <Grid item xs={12} my={5}>
                    <TableComponent
                        columns={columns}
                        totalRecords={schoolTotalRecords}
                        csvData={rows2}
                        type={"school_List"}
                        rows={rows}
                        pagination={false}
                        hideFooterPagination={false}
                        handlePagination={handlePagination}
                        paginationModel={{
                            page: Number(schoolDetails?.currentPage) - 1 || 0,
                            pageSize: Number(schoolDetails?.pagesize) || 5,
                        }}
                    />
                </Grid>
            }
  

          
        </Grid>
    );
}

export default Schools;
