import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet, useLocation } from 'react-router-dom';
import { Grid, SwipeableDrawer } from '@mui/material';

import LeftMenuList from './components/LeftMenuList';
import AppFooter from './components/AppFooter';
import { APP_ROUTES, AUTH_ROUTES } from '../common/constants/Routes';
import TopHeader from './components/TopHeader';
import { findIndex } from 'lodash';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop: any) => prop !== 'open',
})<AppBarProps>(({ theme, open }: any) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const AppLayout = () => {
	const theme = useTheme();
	const appBarRef = React.useRef<HTMLDivElement>(null);
	const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
	const [drawerOpen, setDrawerOpen] = React.useState(window.innerWidth >= 900);
	const [contentHeight, setContentHeight] = React.useState(20);
	const location = useLocation();

	React.useEffect(() => {
		if (appBarRef.current) {
			setContentHeight(40); // Fixed height for simplicity
		}
	}, [appBarRef, location.pathname]);

	React.useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
			if (window.innerWidth >= 900) {
				setDrawerOpen(true);
			} else {
				setDrawerOpen(false);
			}
		};

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	return (
		<Box className="app-layout-container">
			<CssBaseline />
			<AppBar
				ref={appBarRef}
				position="sticky"
				sx={{ backgroundColor: theme.palette.common.white }}
				elevation={0}
			>
				<Toolbar className="p-0 tool-bar-container">
					<TopHeader
						setDrawerOpen={setDrawerOpen}
						contentHeight={contentHeight}
					/>
				</Toolbar>
			</AppBar>

			{screenWidth >= 900 ? (
				<Grid container className="left-menu-layout-div" >
					<Grid item xs={12} md={2.3} className="left-menu-section" >
						<LeftMenuList setOpen={setDrawerOpen} />
					</Grid>
					<Grid item xs={12} md={9.7} >
						<Box component="main" className="px-20 py-0">
							<Outlet />
						</Box>
					</Grid>
				</Grid>
			) : (
				<>
					<Box component="main" className="px-20 py-0">
						<Outlet />
					</Box>
					<SwipeableDrawer
						className="notification-drawer-container"
						anchor="left"
						open={drawerOpen}
						onClose={() => setDrawerOpen(false)}
						onOpen={() => setDrawerOpen(true)}
					>
						<LeftMenuList setOpen={setDrawerOpen} />
					</SwipeableDrawer>
				</>
			)}

			{findIndex(
				[AUTH_ROUTES.LOGIN],
				(val: any) => val === (location.pathname === '' ? '/' : location.pathname)
			) !== -1 ? (
				<></>
			) : (
				''
			)}
		</Box>
	);
};

export default AppLayout;
