import { Grid, InputLabel, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CustomButton from '../../../common/ui/CustomButton';
import { Controller, useForm } from 'react-hook-form';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import theme from '../../../common/theme';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { saveSchoolInProgress, schoolTypeInProgress, selectschoolTypeList, updateSchoolInProgress } from '../../../store/school/_school.slice';
import { useTranslation } from 'react-i18next';

function SchoolForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {t}=useTranslation()
  const schoolTypes = useSelector(selectschoolTypeList);

  const schoolList = schoolTypes?.data || [];
console.log("location?.state?.data?.name",location?.state?.data)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    control,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name:  location?.state?.data?.name||"",
      street:location?.state?.data?.street||"",
      address:location?.state?.data?.address||"",
      school_type_id:location?.state?.data?.school_type||"",

    },
  });

//   useEffect(() => {
//     if (location?.state?.type === 'edit') {
//       setValue('school_type_id', location?.state?.data?.school_type_id, { shouldValidate: true });
//       setValue('name', location?.state?.data?.name, { shouldValidate: true });
//       setValue('street', location?.state?.data?.street, { shouldValidate: true });
//       setValue('address', location?.state?.data?.address, { shouldValidate: true });
//     }
//   }, [location?.state?.type, setValue]);

  useEffect(() => {
    dispatch(schoolTypeInProgress());
  }, [dispatch]);

  const onSubmit = (data: any) => {
	if(location?.state?.type === 'edit' ){
		const handleSuccess=()=>{
			navigate(APP_ROUTES.SCHOOL)
		}
		data.id=location?.state?.data?.id
		const payload:any={
			...data,
			handleSuccess
		}
	   dispatch(updateSchoolInProgress(payload))
	}
	else{
		const handleSuccess=()=>{
			navigate(APP_ROUTES.SCHOOL)
		}
		const payload:any={
			...data,
			handleSuccess
		}
	   dispatch(saveSchoolInProgress(payload))
	}
	
  };

  const handleReportingManager = (value: any) => {
    setValue('school_type_id', value, {
      shouldValidate: true,
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid container xs={12} p={2} item>
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent={'space-between'}
              container
            >
              <Grid item>
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  color={theme.palette.common.black}
                >
                  {location?.state?.type === 'edit' ? `${t("title_edit")}` : `${t("title_add")}`}
                </Typography>
              </Grid>
              <Grid item>
                <CustomButton
                  type="button"
                  text={t("GO_BACK")}
                  startIcon={SubdirectoryArrowRightIcon}
                  color={'primary'}
                  className="p-8 secondary-button"
                  onClick={() => {
                    navigate(APP_ROUTES.SCHOOL);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              alignItems={'center'}
              justifyContent={'flex-end'}
              display={'flex'}
            ></Grid>
          </Grid>

          <Grid container xs={12}>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
              <InputLabel>{t("school_name")}</InputLabel>
              <FormInput
                name="name"
                type="text"
                placeholder={`${t("school_name")}...`}
                control={control}
                className="input-from"
                rules={{
                  required: {
                    value: true,
                    message: `${t("school_name_required")}`,
                  },
                }}
                inputProps={{
                  ...register('name', {}),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
              <InputLabel>{t('street')}</InputLabel>
              <FormInput
                name="street"
                type="text"
                placeholder={`${t('street')}...`}
                control={control}
                className="input-from"
                rules={{
                  required: {
                    value: true,
                    message:  `${t("street_required")}`,
                  },
                }}
                inputProps={{
                  ...register('street', {}),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
              <InputLabel>{t('address')}</InputLabel>
              <FormInput
                name="address"
                type="text"
                placeholder={`${t('address')}...`}
                control={control}
                className="input-from"
                rules={{
                  required: {
                    value: true,
                    message:`${t('address_required')}`,
                  },
                }}
                inputProps={{
                  ...register('address', {}),
                }}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
              <InputLabel>{t('school_type')}</InputLabel>
              <Controller
                control={control}
                name={'school_type_id'}
                render={({ field }) => (
                  <Select
                    options={schoolList}
                    isSearchable
                    placeholder={`${t('school_type')}...`}
                    value={
                      schoolList.find(
                        (option: any) =>
                          option?.id === watch('school_type_id')
                      ) || null
                    }
                    getOptionLabel={(option: any) => `${option.name}`}
                    getOptionValue={(option: any) => option.id}
                    onChange={(selectedOption: any) => {
                      handleReportingManager(selectedOption?.id || '');
                      field.onChange(selectedOption?.id || '');
                    }}
                    onBlur={() => field.onBlur()}
                    isClearable
					defaultValue={location?.state?.data?.school_type}
                  />
                )}
                rules={{ required: `${t('school_type_required')}` }}
              />

              {errors?.school_type_id?.message && isSubmitted ? (
                <Typography color={theme.palette.error.main} fontSize={12}>
                  <>{errors?.school_type_id?.message}</>
                </Typography>
              ) : null}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            justifyContent="flex-end"
            display={'flex'}
            my={5}
          >
            <CustomButton
              type="submit"
              text={t("SUBMIT")}
              className="p-12 secondary-button"
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default SchoolForm;
