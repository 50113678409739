import { Instance ,candidateInstances, testingInstance} from '../../common/axiosInstance';
import ROUTES from '../../common/routes';
const token=localStorage.getItem('accessToken')

  export const addQuestionsEffect = (formData:any) => {

    return testingInstance.request({
      url: `/question/insertquestions`,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  export const getQuestionsEffect = (payload:any) => {
    console.log("QusetionsagaCalledservicepayload",payload)
    return testingInstance.request({
      url: `/question/getquestionbystandard?standard_id=${payload?.standard_id}&year=${payload?.year}&pagination_required=${payload?.pagination}&pageNo=${payload?.pageNo}&pageSize=10&questions_type=${payload?.questions_type}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },  
    });
  };

  export const updateQuestionsEffect = (formData:any) => {
    console.log("service called",formData)
       return testingInstance.request({
         url: `/question/updatequestion/${formData?.id}`,
         method: "PUT",
         data: formData?.data,
         headers: {
           Authorization:  `Bearer ${token}`,
           "Content-Type": "application/json",
         },
       });
     }
     export const deleteQuestionsEffect = (formData:any) => {
      console.log("service called",formData)
         return testingInstance.request({
           url: `/question/deletequestion/${formData?.id}`,
           method: "DELETE",
           data: formData,
           headers: {
             Authorization:  `Bearer ${token}`,
             "Content-Type": "application/json",
           },
         });
       }
       export const getCsvQuestionsEffect = (payload:any) => {
        console.log("QusetionsagaCalledservicepayload",payload)
        return testingInstance.request({
          url: `/question/getquestionbystandard?standard_id=${payload?.standard_id}&year=${payload?.year}&pagination_required=${payload?.pagination}&pageNo=${payload?.pageNo}&pageSize=10&questions_type=${payload?.questions_type}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },  
        });
      };
