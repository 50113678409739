import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

function setup() {
	const store = configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware: any) =>
			getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
	});
	sagaMiddleware.run(rootSaga);
	return store;
}
const store = setup();

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
