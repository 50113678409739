import { Grid, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../common/ui/TableComponent';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SchoolHeader from './SchoolHeader';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { csvSchoolListInProgress, deleteSchoolInProgress, schoolDetailsInProgress, selectCsvSchoolDetailsList, selectschoolDetailsList, selectschoolDetailsListLoading } from '../../../store/school/_school.slice';
import { CircularProgress, InputLabel, Select } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { yearsList } from '../../../common/constants/FormItems';

function SchoolListScreen() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [id, setId] = useState('');
    const { control, watch, handleSubmit, setValue } = useForm({
        defaultValues: {
            year: '2024'
        }
    });

    const schoolDetails = useSelector(selectschoolDetailsList);
    const schoolDetailsLoading = useSelector(selectschoolDetailsListLoading);
    const schoolList = schoolDetails?.data || [];
    const schoolTotalRecords = schoolDetails?.totalRecords || 0;
    const csvSchoolDetails = useSelector(selectCsvSchoolDetailsList);
    const csvSchoolList=csvSchoolDetails?.data || [];
    console.log("schoolList",schoolList)

  

    const handleDelete = () => {
        setOpen(true);
    };

    const handleDeleteAndSetId = (id: any) => {
        const handleSuccess = () => {
            const payloads = {
                pageNo: 1,
                pagination: true,
            }
            dispatch(schoolDetailsInProgress(payloads as any));
        }
        const payload: any = {
            id: id,
            handleSuccess
        }
        dispatch(deleteSchoolInProgress(payload))
    };

    const handleView = (id: any, data: any) => {
        navigate(APP_ROUTES.SCHOOLWISE_DEATILS, {
            state: {
                data: id,
                overAlldate: data,
                type: "view",
            }
        });
    };

    useEffect(() => {
        const payloads = {
            pageNo: 1,
            pagination: true,
        }
       
        dispatch(schoolDetailsInProgress(payloads as any));
       
    }, [dispatch]);

    const handlePagination = (data: any) => {
        const payloads = {
            pageNo: data?.page + 1,
            pagination: true,
            year: null
        };
        dispatch(schoolDetailsInProgress(payloads as any));
    };

    const columns = [
        { field: 'name', headerName: `${t('name')}`, disableColumnMenu: true, flex: 1 },
        // { field: 'pass_percentage_12', headerName: `${t('hsc_passed')}`, disableColumnMenu: true, flex: 1 },
        // { field: 'fail_percentage_12', headerName: `${t('hsc_failed')}`, disableColumnMenu: true, flex: 1 },
        // { field: 'pass_percentage_10', headerName: `${t('sslc_passed')}`, disableColumnMenu: true, flex: 1 },
        // { field: 'fail_percentage_10', headerName: `${t('sslc_failed')}`, disableColumnMenu: true, flex: 1 },
        { field: 'suvey_completed_student_count', headerName: `${t('survey_completed')}`, disableColumnMenu: true, flex: 1 },
        { field: 'suvey_not_completed_student_count', headerName: `${t('survey_pending')}`, disableColumnMenu: true, flex: 1 },
        { field: 'Action', headerName: `${t('Action')}`, disableColumnMenu: true, width: 130, renderCell: (params: any) => (
            <>
                <CustomButton text={`${t('Add_Student')}`} className="mx-5" color={'secondary'} onClick={() => navigate(APP_ROUTES.STUDENT_ADD_SCREEN, { state: { data: params.row, type: 'edit' } })} />
             
            </>
        )},
    ];

    const rows = schoolList.map((school: any) => ({
        id: school.id,
        name: school.name,
        street: school.street,
        address: school.address,
        school_type: school.school_type_id,
        pass_percentage_10: school.pass_percentage_10,
        pass_percentage_12: school.pass_percentage_12,
        fail_percentage_10: school.fail_percentage_10,
        fail_percentage_12: school.fail_percentage_12,
        suvey_completed_student_count: school.suvey_completed_student_count,
        suvey_not_completed_student_count: school.suvey_not_completed_student_count,
        head_master_name: school.head_master_name,
        hm_number: school.hm_number,
        created_at: school.created_at,
        updated_at: school.updated_at,
        student_count: school.student_count,
        survey_count_10: school.survey_count_10,
        survey_count_12: school.survey_count_12,
        fail_count: school.fail_count,
        pass_count_10: school.pass_count_10,
        pass_count_12: school.pass_count_12,
		fail_count_10: school.fail_count_10,
		fail_count_12:school.fail_count_12,
        higher_studies_join_12: school.higher_studies_join_12,
        no_studies_12: school.no_studies_12,
        higher_studies_applied_12: school.higher_studies_applied_12,
        skill_based_studies_12: school.skill_based_studies_12,
        higher_studies_join_10: school.higher_studies_join_10,
        no_studies_10: school.no_studies_10,
        higher_studies_applied_10: school.higher_studies_applied_10,
        skill_based_studies_10: school.skill_based_studies_10,
        courseAppliedCount:school?.courseAppliedCount,
        courseJoinCount:school?.courseJoinCount,
    }));
    const rows2 = csvSchoolList.map((school: any) => ({
        id: school.id,
        name: school.name,
        street: school.street,
        address: school.address,
        school_type: school.school_type_id,
        pass_percentage_10: school.pass_percentage_10,
        pass_percentage_12: school.pass_percentage_12,
        fail_percentage_10: school.fail_percentage_10,
        fail_percentage_12: school.fail_percentage_12,
        suvey_completed_student_count: school.suvey_completed_student_count,
        suvey_not_completed_student_count: school.suvey_not_completed_student_count,
        head_master_name: school.head_master_name,
        hm_number: school.hm_number,
        created_at: school.created_at,
        updated_at: school.updated_at,
        student_count: school.student_count,
        survey_count_10: school.survey_count_10,
        survey_count_12: school.survey_count_12,
        fail_count: school.fail_count,
        pass_count_10: school.pass_count_10,
        pass_count_12: school.pass_count_12,
		fail_count_10: school.fail_count_10,
		fail_count_12:school.fail_count_12,
        higher_studies_join_12: school.higher_studies_join_12,
        no_studies_12: school.no_studies_12,
        higher_studies_applied_12: school.higher_studies_applied_12,
        skill_based_studies_12: school.skill_based_studies_12,
        higher_studies_join_10: school.higher_studies_join_10,
        no_studies_10: school.no_studies_10,
        higher_studies_applied_10: school.higher_studies_applied_10,
        skill_based_studies_10: school.skill_based_studies_10,
        courseAppliedCount:school?.courseAppliedCount,
        courseJoinCount:school?.courseJoinCount,
    }));

    useEffect(() => {
        const year = watch('year');
        const payloads: any = {
            pageNo: 1,
            pagination: true,
            year: year,
            search_query:null
        }
        const payload2: any = {
            pageNo: 1,
            pagination: false,
            year: year,
            search_query:null
        }
        dispatch(schoolDetailsInProgress(payloads));
        dispatch(csvSchoolListInProgress(payload2));
    }, [dispatch, watch('year')]);

    const handleOnchange = (search_query: any) => {
        const year = watch('year');
        const payloads: any = {
            pageNo: 1,
            pagination: true,
            school_type_id: null,
            year: year,
            search_query:search_query
           
        };
        dispatch(schoolDetailsInProgress(payloads));
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {/* <SchoolHeader />  */}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} p={2}>
                <InputLabel>{t("year")}:</InputLabel>
                <Controller
                    control={control}
                    name="year"
                    render={({ field }) => (
                        <Select
                            {...field}
                            fullWidth
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                        >
                            {yearsList.map((year) => (
                                <MenuItem key={year.id} value={year.id}>
                                    {year.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    rules={{ required: "Year is required" }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} xl={4} p={2}>
                <InputLabel>{t("search")}:</InputLabel>
                <TextField
                    fullWidth
                    placeholder={t("search")}
                    variant='standard'
                    onChange={(e: any) => handleOnchange(e.target.value)}
                />
            </Grid>
            {schoolDetailsLoading ?
                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress />
                </Grid> :
                <Grid item xs={12} my={5}>
                    <TableComponent
                        columns={columns}
                        totalRecords={schoolTotalRecords}
                        csvData={rows2}
                        type={"school_List"}
                        rows={rows}
                        pagination={false}
                        hideFooterPagination={false}
                        handlePagination={handlePagination}
                        paginationModel={{
                            page: Number(schoolDetails?.currentPage) - 1 || 0,
                            pageSize: Number(schoolDetails?.pagesize) || 5,
                        }}
                    />
                </Grid>
            }
        </Grid>
    );
}

export default SchoolListScreen;
