import { delay, put, call, takeLatest } from 'redux-saga/effects';



import { AxiosResponse } from 'axios';
import { csvSchoolListInError, csvSchoolListInProgress, csvSchoolListInSuccess, deleteSchoolInProgress, deleteSchoolInSuccess, saveSchoolInError, saveSchoolInProgress, saveSchoolInSuccess, schoolDetailsInError, schoolDetailsInProgress, schoolDetailsInSuccess, schoolTypeInProgress, schoolTypeInSuccess, updateSchoolInError, updateSchoolInProgress, updateSchoolInSuccess } from './_school.slice';
import { addSchoolEffect, deleteSchoolEffect, getCsvSchoolListEffect, getSchoolTypesEffect, getSchoolsEffect, updateSchoolEffect } from './_school.service';
import { getSchoolListEffect } from '../students/_students.service';
import { deleteStudentInError } from '../students/_students.slice';


function* saveSchoolWatcher({
    payload,
}: ReturnType<typeof saveSchoolInProgress>) {
    const { navigate, ...requestPayload }: any = payload;
    try {
        console.log("sagaPaylod",payload)
        const { data }: AxiosResponse<any> = yield call(addSchoolEffect, payload);
        yield put(saveSchoolInSuccess(data));
        if (payload.handleSuccess) {
            payload.handleSuccess();	
        }
    } catch (e: any) {
        yield put(saveSchoolInError(e.message));
    }
}
function* getSchoolsWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getSchoolsEffect, payload);
		console.log('data', data);
		yield put(
			schoolDetailsInSuccess({
				data: data,
				message:"Survey Details Fetched Successfully"
			})
		);
	} catch (error) {
		yield put(
			schoolDetailsInError({
				message: 'error',
			})
		);
	}
}
function* getSchoolTypesWatcher({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getSchoolTypesEffect, payload);
		console.log('data', data);
		yield put(
			schoolTypeInSuccess({
				data: data,
				message:""	
			})
		);
	} catch (error) {
		// yield put(
		// 	partiesListInError({
		// 		message: 'error',
		// 	})
		// );
	}
}
function* updateSchoolWatcher({
    payload,
}: ReturnType<typeof updateSchoolInProgress>) {
    const { navigate, ...requestPayload }: any = payload;
    try {
        console.log("sagaPaylod",payload)
        const { data }: AxiosResponse<any> = yield call(updateSchoolEffect, payload);
        yield put(updateSchoolInSuccess(data));
        if (payload.handleSuccess) {
            payload.handleSuccess();	
        }
    } catch (e: any) {
        yield put(updateSchoolInError(e.message));
    }
}
function* deleteSchoolWatcher({
    payload,
}: ReturnType<typeof deleteSchoolInProgress>) {
    const { navigate, ...requestPayload }: any = payload;
    try {
        console.log("sagaPaylod",payload)
        const { data }: AxiosResponse<any> = yield call(deleteSchoolEffect, payload);
        yield put(deleteSchoolInSuccess(data));
        if (payload.handleSuccess) {
            payload.handleSuccess();	
        }
    } catch (e: any) {
        yield put(deleteStudentInError(e.message));
    }
}
function* getCsvSchoolList({
	payload
}: any) {
	try {
		console.log("sagaCalled")
		yield delay(3000);
		const { data } = yield call(getCsvSchoolListEffect, payload);
		console.log('data', data);
		yield put(
			csvSchoolListInSuccess({
				data: data,
				message:"Survey Details Fetched Successfully"
			})
		);
	} catch (error) {
		yield put(
			csvSchoolListInError({
				message: 'error',
			})
		);
	}
}
export default function* schoolSaga() {
	yield takeLatest(saveSchoolInProgress.type, saveSchoolWatcher);
    yield takeLatest(schoolDetailsInProgress.type, getSchoolsWatcher);
	yield takeLatest(schoolTypeInProgress.type, getSchoolTypesWatcher);
	yield takeLatest(updateSchoolInProgress.type, updateSchoolWatcher);
	yield takeLatest(deleteSchoolInProgress.type, deleteSchoolWatcher);
	yield takeLatest(csvSchoolListInProgress.type, getCsvSchoolList);
	

}
