import { all } from 'redux-saga/effects';
import studentSaga from './students/_students.saga';
import surveySaga from './survey/_survey.saga';
import authSaga from './auth/_auth.saga';
import schoolSaga from './school/_school.saga';
import questionsSaga from './questions/_questions.saga';
export default function* rootSaga() {
	yield all([studentSaga(),surveySaga(),authSaga(),schoolSaga(),questionsSaga()]);
	// code after all-effect
}
