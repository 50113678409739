/* eslint-disable jsx-a11y/anchor-is-valid */
import {
	Grid,
	IconButton,
	List,
	ListItemText,
	Typography,
	useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import '../Layout.scss';
import { Link, useNavigate } from 'react-router-dom';

// import { ReactComponent as CloseIcon } from '../../common/icons/close.svg';
import CloseIcon from '@mui/icons-material/Close';

import { APP_ROUTES, AUTH_ROUTES, CANDIDATE_MENU } from '../../common/constants/Routes';
import SvgConverter from '../../common/SvgConverter';
import LanguageSelector from '../../features/auth/LanguageSelector';
import { useDispatch } from 'react-redux';
import { logoutRequest } from '../../store/auth/_auth.slice';
import { useTranslation } from 'react-i18next';
import { getProfileInProgress } from '../../store/students/_students.slice';

const LeftMenuList = ({ setOpen }: { setOpen: Function }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
	const [menuItems, setMenuItem] = useState(CANDIDATE_MENU);
	const handleMenuItemClick = () => {
		setOpen(false);
	};
	const handleLogOff = () => {
		setOpen(false);
		dispatch(logoutRequest(''));
		navigate(AUTH_ROUTES.LOGIN);
		window.location.reload();
		localStorage.removeItem('accessToken')
		localStorage.removeItem('name')
		localStorage.removeItem('email')

	};

	const changeLanguage = () => {
		setShowLanguageModal(true);
		setOpen(false);
	};
	const Id=localStorage.getItem("id")
	useEffect(()=>{
	  const paylod:any={
	  id:Id
	  }
	  dispatch(getProfileInProgress(paylod))
	},[])

	return (
		<div className="notification-container">
			<Grid
				container
				justifyContent="space-between"
				// className="notification-drawer-header"
				alignItems="center"
			>
				<Grid item mt={5}>
				{screenWidth < 900 && (
					<Grid item mt={5}>
						<IconButton onClick={() => setOpen(false)}>
							<CloseIcon />
						</IconButton>
					</Grid>)}
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<List className="left-menu-container" >
								{menuItems.map((item: any, index: any) => (
									<React.Fragment key={index}>
										{item.route ? (
											<Link to={item.route} onClick={handleMenuItemClick}>
												<ListItemText
													className={`menu-item-container${
														index > 0 ? '' : ''
													}`}
													primary={
														<Typography
															color={theme.palette.primary.main}
															fontWeight={600}
															fontSize={18}
															mb={2}
															className='left-menu-text'
														>
															{t(item.text)}
														</Typography>
													}
												/>
											</Link>
										) : (
											<>
											<ListItemText
													// className={`menu-item-container mt-30${
													// 	index > 0 ? ' mt-10' : ''
													// }`}
													primary={
														<Typography
															color={theme.palette.primary.main}
															fontWeight={600}
															fontSize={18}
															onClick={() => navigate(APP_ROUTES.CHANGE_PASSWORD)}
															mb={1.5}
															className='left-menu-text'
												
													flexWrap={"wrap"}
														>
															{t('CHANGE_PASSWORD')}
														</Typography>
													}
												/>
												<ListItemText
													// className={`menu-item-container mt-30${
													// 	index > 0 ? ' mt-10' : ''
													// }`}
													primary={
														<Typography
															color={theme.palette.primary.main}
															fontWeight={600}
															fontSize={18}
															className='left-menu-text'
															onClick={() => setShowLanguageModal(true)}
														>
															{t('SELECT_LANGUAGE')}
														</Typography>
													}
												/>
												<Link to={APP_ROUTES.PROFILE_UPDATE} >
												<ListItemText
													// className={`menu-item-container mt-30${
													// 	index > 0 ? ' mt-10' : ''
													// }`}
													primary={
														<Typography
															color={theme.palette.primary.main}
															fontWeight={600}
															fontSize={18}
															mt={2}
															className='left-menu-text'
														
														>
															{t('MY_PROFILE')}
														</Typography>
													}
												/></Link>
												<ListItemText
													className={`menu-item-container${
														index > 0 ? '' : ''
													}`}
													primary={
														<Typography
															color={theme.palette.primary.main}
															fontWeight={600}
															fontSize={18}
															onClick={handleLogOff}
															mt={2}
															className='left-menu-text'
														>
															{t(item.text)}
														</Typography>
													}
												/>
											</>
										)}
									</React.Fragment>
								))}{' '}
							</List>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<LanguageSelector
				open={showLanguageModal}
				setOpen={setShowLanguageModal}
				contentType="DASHBOARD"
			/>
		</div>
	);
};

export default LeftMenuList;
