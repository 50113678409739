
export const mediumType = [
	{
		id: 1,
		name: 'Tamil',
		Category: 1,
	},
	{
		id: 2,
		name: 'English',
		Category: 2,
	},
	

];
export const reportingManagerList = [
	{
		id: 1,
		name: 'Hindhu',
		Category: 1,
	},
	{
		id: 2,
		name: 'Muslim',
		Category: 2,
	},
	{
		id: 3,
		name: 'Christian',
		Category: 3,
	},
];
export const communityList = [
	{
		id: 1,
		name: 'OC',
		Category: 1,
	},
	{
		id: 2,
		name: 'BC',
		Category: 2,
	},
	{
		id: 3,
		name: 'MBC',
		Category: 3,
	},
	{
		id: 4,
		name: 'SC',
		Category: 4,
	},
	{
		id: 5,
		name: 'ST',
		Category: 5,
	},
];

export const genderType = [
	{
		id: 1,
		name: 'Male',
		Category: 1,
	},
	{
		id: 2,
		name: 'Female',
		Category: 2,
	},
	{
		id: 3,
		name: 'Others',
		Category: 3,
	},
	

];
export const financialType = [
	{
		id: 1,
		name: 'Below Poverty',
		Category: 1,
	},
	{
		id: 2,
		name: 'Lower Middle',
		Category: 2,
	},
	{
		id: 3,
		name: 'Middle',
		Category: 3,
	},
	{
		id: 4,
		name: 'Upper Middle',
		Category: 4,
	},
	{
		id: 5,
		name: 'High',
		Category: 5,
	},
	

];
export const parentalStatus = [
	{
		id: 1,
		name: 'Living With Both Parents',
		Category: 1,
	},
	{
		id: 2,
		name: 'Living With Single Parents',
		Category: 2,
	},
	{
		id: 3,
		name: 'Living With Gaurdian',
		Category: 3,
	},
	{
		id:4,
		name: 'No',
		Category:4,
	},
	

];
export const parentalOccupation= [
	{
		id: 1,
		name: 'Farmer',
		Category: 1,
	},
	{
		id:2,
		name: 'Wages',
		Category:2,
	},
	{
		id:3,
		name: 'Government Job',
		Category:3,
	},
	{
		id: 4,
		name: 'Private Job',
		Category: 4,
	},
	{
		id:5,
		name: 'Business',
		Category:5,
	},
	{
		id: 6,
		name: 'Job Less',
		Category: 6,
	},
	

];
export const parentalEducation= [
	{
		id: 1,
		name: 'Less than High School',
		Category: 1,
	},
	{
		id: 2,
		name: 'High School',
		Category: 2,
	},
	{
		id: 3,
		name: 'Bachelor Degree',
		Category: 3,
	},
	{
		id:4,
		name: 'Professional Degree',
		Category:4,
	},
	
	

];
export const locality= [
	{
		id: 1,
		name: 'Village',
		Category: 1,
	},
	{
		id: 2,
		name: 'City',
		Category: 2,
	},
	{
		id: 3,
		name: 'Town',
		Category: 3,
	},
	{
		id: 4,
		name: 'Rural',
		Category: 4,
	},
	
	

];
export const  yearsList= [
	{
		id: 2023,
		name: '2022-2023',
		Category: 1,
	},
	{
		id: 2024,
		name: '2023-2024',
		Category: 2,
	},
	

];

export const higherStudyOption = [
	{
		id: 1,
		name: 'Yes',
		Category: 1,
	},
	{
		id: 2,
		name: 'No',
		Category: 2,
	},
	

];
export const healthStatusOption = [
	{
		id: 1,
		name: 'புற்றுநோய் பாதிப்பு',
		Category: 1,
	},
	{
		id: 2,
		name: 'நீரிழிவு நோயாளி',
		Category: 2,
	},
	
	{
		id: 3,
		name: 'நிரந்தரமாக முடங்கிய நோயாளி',
		Category: 3,
	},
	{
		id: 4,
		name: 'ஊனமுற்றவர்',
		Category: 4,
	},
	{
		id: 5,
		name: 'மனநலம் பாதிக்கப்பட்டவர்',
		Category: 5,
	},
	{
		id: 6,
		name: 'இல்லை',
		Category: 6 ,
	},

];
export const passFailStatus = [
	{
		id: 1,
		name: 'Pass',
		Category: 1,
	},
	{
		id: 2,
		name: 'Fail',
		Category: 2,
	},
];
export const surveyStatus = [
	{
		id: 1,
		name: 'Not-Completed',
		Category: 1,
	},
	{
		id: 2,
		name: 'Completed',
		Category: 2,
	},
];
export const studentStatus = [
	{
		id: 1,
		name: 'Higher Studies Joined',
		Category: 1,
	},
	{
		id: 2,
		name: 'Higher Studies Not Joined',
		Category: 2,
	},
	{
		id: 3,
		name: 'Higher Studies Applied',
		Category: 3,
	},
	{
		id: 4,
		name: 'Skill Based Course Joined',
		Category: 4	,
	},
];