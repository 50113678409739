import { Instance ,candidateInstances, testingInstance} from '../../common/axiosInstance';
import ROUTES from '../../common/routes';
const token=localStorage.getItem('accessToken')






  export const addSchoolEffect = (formData:any) => {

    return testingInstance.request({
      url: `/school/insertschools`,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  export const getSchoolsEffect = (payload:any) => {
    return testingInstance.request({
      url: `/school/getschools?pagination_required=${payload?.pagination}&pageNo=${payload?.pageNo}&pageSize=10&year=${payload?.year}&search_query=${payload?.search_query}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },  
    });
  };
  export const getSchoolTypesEffect = (payload:any) => {
    console.log("serviceCalled")
    return testingInstance.request({
      url: `/schooltype/getlist`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },  
    });
  };
  export const updateSchoolEffect = (formData:any) => {
    console.log("service called",formData)
       return testingInstance.request({
         url: `/school/updateschool/${formData?.id}`,
         method: "PUT",
         data: formData,
         headers: {
           Authorization:  `Bearer ${token}`,
           "Content-Type": "application/json",
         },
       });
     }
     export const deleteSchoolEffect = (formData:any) => {
      console.log("service called",formData)
         return testingInstance.request({
           url: `/school/deleteschool/${formData?.id}`,
           method: "DELETE",
           data: formData,
           headers: {
             Authorization:  `Bearer ${token}`,
             "Content-Type": "application/json",
           },
         });
       }
       export const getCsvSchoolListEffect = (payload:any) => {
        return testingInstance.request({
          url: `/school/getschools?pagination_required=${payload?.pagination}&pageNo=${payload?.pageNo}&pageSize=10&year=${payload?.year}&search_query=${payload?.search_query}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },  
        });
      };
