import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import EditProfile from './EditProfile';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getProfileInProgress, getProfileLoading, selectGetProfileDetails } from '../../../store/students/_students.slice';
import { CircularProgress } from '@material-ui/core';

const ProfileScreen = () => {
  const dispatch=useDispatch()
  const Id=localStorage.getItem("id")
  useEffect(()=>{
    const paylod:any={
    id:Id
    }
    dispatch(getProfileInProgress(paylod))
  },[])
const {t}=useTranslation()
  const  user= useSelector(selectGetProfileDetails);
  const profileLoading=useSelector(getProfileLoading)

  console.log("profileLoading",profileLoading)
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    trigger,
    reset,
  } = useForm({
    defaultValues: {
      first_name:user?.userInfo?.first_name?user?.userInfo?.first_name:"",
      last_name:user?.userInfo?.last_name?user?.userInfo?.last_name:"",
      email:user?.userInfo?.email?user?.userInfo?.email:"",
      phone:user?.userInfo?.phone?user?.userInfo?.phone:"",
      DOB:user?.userInfo?.UserProfile?.DOB?user?.userInfo?.UserProfile?.DOB:"",
      address:user?.userInfo?.UserProfile?.address?user?.userInfo?.UserProfile?.address:"",
      middle_name:" ",
    },
  });
 

  return (
    <div>
      <Grid>
      {profileLoading?
      <Grid display={"flex"} justifyContent={"center"}>
      <CircularProgress/></Grid>:
        <EditProfile
          handleSubmit={handleSubmit}
          text={`${t('MY_PROFILE')}`}
          register={register}
          control={control}
          errors={errors}
          reset={reset}
          setValue={setValue}
          watch={watch}
          trigger={trigger}
        />}
      </Grid>
    </div>
  );
};

export default ProfileScreen;
