import { Instance ,candidateInstances, testingInstance} from '../../common/axiosInstance';
import ROUTES from '../../common/routes';
const token=localStorage.getItem('accessToken')






  export const addSurveyEffect = (formData:any) => {

    return testingInstance.request({
      url: `/answer/insertanswer`,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  
  export const getSurveyDetailstEffect = (payload:any) => {
    console.log("surveypayload",payload)
    return testingInstance.request({
      url: `/student/getstudent/${payload}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}` ,
        "Content-Type": "application/json",
      },  
    });
  };
  export const getDashboardDetailsEffect = (payload:any) => {

    return testingInstance.request({
      url: `/answer/dashboard?year=${payload?.year}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}` ,
        "Content-Type": "application/json",
      },  
    });
  };
  export const updateSurveyEffect = (formData:any) => {
    console.log("service called",formData)
       return candidateInstances.request({
         url: `answer/updateanswer?id=${formData?.id}`,
         method: "PUT",
         data: formData,
         headers: {
           Authorization: `Bearer ${token}`,
           "Content-Type": "application/json",
         },
       });
     }
